import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { constant } from '../constant';
import {DownloadDocument} from '../services/hdfcergo.service'
import {CheckPolicyStatusAndDownload} from '../services/care.service'
import {StatusAndDownloadDocument} from '../services/goDigit.service'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-policypurchased',
  templateUrl: './policypurchased.component.html',
  styleUrls: ['./policypurchased.component.css']
})
export class PolicypurchasedComponent implements OnInit {

  purchased = [];
  errorMessage = ''; 
  userId: string;
  userName:string;
  careProposalData: any
  goDigitDocumentData: any
  goDigitPolicyStatus: any
  goDigitProposal: any
  goDigitStatusChecked: any
  @ViewChild('document', { static: false }) documentTemplate: TemplateRef<any>;
  @ViewChild('PolicyStatus', { static: false }) policyStatusTemplate: TemplateRef<any>;
  @ViewChild('CarePolicyStatus', { static: false }) CarePolicyStatusTemplate: TemplateRef<any>; 
  modalRef2: BsModalRef;
  currentPage: number = 1; 
  totalItems: number = 0;
  itemsPerPage: number = 10; 
  totalPages: number = 1
  page = 1
  pageNumbers: number[];
  loading: boolean;
  downloadCertificate: boolean =false

  constructor(
    private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private hdfcdocumentDownload: DownloadDocument,
    private toastr: ToastrService,
    private carePolicyDocument: CheckPolicyStatusAndDownload,
    private digitstatusAndDownload:StatusAndDownloadDocument,
    private modalService: BsModalService,
    // private iciciDocument: IciciPaymentSuccessComponent
  ) { }

  ngOnInit() {
    this.userId = localStorage.getItem('user_Id');
    this.userName = localStorage.getItem('fullname');
    if (!this.userId) {
      this.router.navigate([`home`]);
    } else {
      this.policyPurchased(this.page);
    }
  }

  async policyPurchased(page: number) {
    this.ngxService.start();
    const url = `/userPlanTransactionRoute/list?page=${page} &order_by=1&status=1&user_id=${this.userId}&company_id=&plan_id=&premium_id=`;
    this.commonService.get(url).subscribe(async (response) => {
      
      if (response.error.errorCode === 200) {
         this.totalItems = response.data.count
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        await this.updatePageNumbers()
        this.ngxService.stop();
        this.purchased = response.data.rows.map(e => {
          return {
            company: e.company_name,
            companylogo:constant.companyLogos[e.company_name],
            plan: e.plan_name,
            premium: e.premium_amount,
            transactionNo: e.transaction_id,
            proposalTypeName:e.proposal_id_name,
            proposalTypeValue:e.proposal_id_value,
            policyNo: e.policy_no,
            updatedAt: e.updatedAt,
            payment_status:e.payment_status
          }
        })
        //console.log(this.purchased)
      }
    }, (error) => {
      //console.log("error ts: ", error);
    });
    
  }; 

  startloading(){
    this.loading = true
  }

  stoploading() {
    this.loading =false
  }

  checkPolicyStatus( name?:string, proposalNo?: string , policyNo? : string ){
    this.downloadCertificate = false
    this.startloading()
    if(name=="care") {
      this.carePolicyDocument.checkPolicyStatus(proposalNo, policyNo).subscribe(response=>{
        this.stoploading()
     
        if(response.responseData.status === "1" &&  response.responseData.message === "Success") {
          this.careProposalData = response.intGetPolicyStatusIO
          this.modalRef2 = this.modalService.show(this.CarePolicyStatusTemplate);
        }
        else {
          this.stoploading()
          this.toastr.error("Oops! We encountered an error while checking the policy status. Please try again in some time.")
        }
      })
    }else if(name == "digit") {
      this.digitstatusAndDownload.checkPolicyStatus(policyNo).subscribe(Response=>{
        this.stoploading();
        if(Response.status.code===200 && Response.status.message === "OK" ){
          this.goDigitPolicyStatus = Response.responseBody
          this.goDigitProposal= Response.responseBody.policyStatus
          if( Response.responseBody.policyStatus === "EFFECTIVE"){
            this.goDigitStatusChecked = true
          }
          this.modalRef2 = this.modalService.show(this.policyStatusTemplate);
        } else {
          this.stoploading()
          this.toastr.error("Oops! We encountered an error while fetching the policy status. Please try again after some time")
        }
       })

    }


  }

  downloadPolicy(name?:string, proposalNo?: string, policyNo?: string, transaction_id?: string   ){
    this.downloadCertificate = true
    if(name =="HDFC"){
      this.startloading()
      const payload ={
        "TransactionID": transaction_id,
        "Req_Policy_Document": {
            "Policy_Number":policyNo
        }
  
      }
      this.hdfcdocumentDownload.downloadPolicy(payload).subscribe(policyresponse=>{
        this.stoploading()
       if (policyresponse && policyresponse.Resp_Policy_Document && policyresponse.Resp_Policy_Document.PDF_BYTES) {
        this.hdfcdocumentDownload.downloadPDF(policyresponse.Resp_Policy_Document.PDF_BYTES, 'PolicyDocument.pdf');
      } else {
        this.stoploading()
        console.error('PDF bytes are not available');
       
      }
      }) 
    } else if(name == "care") {
      this.startloading()

      this.carePolicyDocument.downloadPolicy(policyNo).subscribe(response => {
        this.stoploading()
        if(response.responseData.status ==="1" && response.responseData.message ==="Success") {
          this.hdfcdocumentDownload.downloadPDF(response.intFaveoGetPolicyPDFIO.dataPDF, "CarePolicy.pdf")
  
        }
        else {
          this.stoploading()
          this.toastr.error("Oops! We encountered an error while downloading the policy. Please try again in some time.")
        }
      })
    } else if(name == "digit") {
      this.startloading()
      this.digitstatusAndDownload.downloadDocument( policyNo).subscribe (Response=> {
    
        this.stoploading()
        this.goDigitDocumentData = Response["Retail Health-PDF Service"]
        const error = JSON.parse(Response['Retail Health-PDF Service'].Error);
        if(error.errorCode == 400) {
          this.stoploading()
          const message = JSON.parse(error.errorMessage)
          this.errorMessage = message.error[0].message || 'An unknown error occurred.';
        }
  
       
        this.modalRef2 = this.modalService.show(this.documentTemplate);
      })
    } else if(name == "ICICI") {
      // this.iciciDocument.downloadCertificate

    }


  }

  downloadProposal(proposalNo: string, transaction_id: string ){
    this.startloading()
    this.downloadCertificate = true
    const payload = {
      "TransactionID":transaction_id ,
      "Req_Policy_Document": {
          "Proposal_Number": proposalNo
        }
      }
    this.hdfcdocumentDownload.downloadProposal(payload).subscribe(proposalResponse=>{
      this.stoploading()
      if (proposalResponse && proposalResponse.Resp_Policy_Document && proposalResponse.Resp_Policy_Document.PDF_BYTES) {
        this.hdfcdocumentDownload.downloadPDF(proposalResponse.Resp_Policy_Document.PDF_BYTES, 'ProposalPDf.pdf');
      } else {
        this.stoploading
        console.error('PDF bytes are not available');
      }
    })

  }
  onPageChange(newPage: number) {
    if (newPage >= 1 ) {
      this.currentPage = newPage;
      this.policyPurchased(this.currentPage)

    }
  }

  async updatePageNumbers() {
    this.pageNumbers = [];
    const maxVisiblePages = 5; 
    if (this.totalPages <= maxVisiblePages) {
     
      this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(1, this.currentPage - 2); 
      const endPage = Math.min(this.totalPages, this.currentPage + 2); 
      if (startPage > 1) {
        this.pageNumbers.push(1); 
        if (startPage > 2) {
          this.pageNumbers.push(-1); 
        }
      }
  
      for (let i = startPage; i <= endPage; i++) {
        this.pageNumbers.push(i);
      }
  
      if (endPage < this.totalPages) {
        if (endPage < this.totalPages - 1) {
          this.pageNumbers.push(-1); 
        }
        this.pageNumbers.push(this.totalPages); 
      }
    }
  }
  
}

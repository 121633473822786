import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CareEnviroment } from "../../environments/environment";
import { CommonService } from "./common.service";
import { CareToken } from "./encryption.Service";

@Injectable({
  providedIn: "root",
})
export class CalculateCarePremiumforHealth {
  individual: any = {
    type: "I",
    gender: "1",
    age: "",
    adult_number: 1,
    policyTenure: 1,
    child_number: 0,
    userName: "",
    emailId: "",
    city_id: "",
    contactNumber: "",
    ped: "0",
    pincode: "400086",
    diseaseSelection: [
      {
        DiseaseID: "14",
        SufferingSince: "29/12/2018",
      },
    ],
  };


  careFreedompayload: any
  careAdvantage: any
  careHeart: any

  constructor(private http: HttpClient) {}

  private getAccessToken(): Observable<string> {
    const authApiUrl = `${CareEnviroment.apiUrl}/auth/access-token?formattype=json`;
    const authApiBody = {
      api_key: CareEnviroment.api_key,
      auth_secret: CareEnviroment.auth_secret,
    };

    return this.http
      .post<any>(authApiUrl, authApiBody)
      .pipe(map((data) => data.data.accessToken));
  }

  // Supreme
  // private makeAbacusSupremeApiRequest(accessToken: string): Observable<any> {
  //   const abacusApiUrl = `${this.apiUrl}/abacus/partner?formattype=json`;

  //   const individualFrm = JSON.parse(localStorage.getItem('userData1'));
  //   if (individualFrm) {
  //     this.individual = individualFrm

  //   }

  //   // Constructing the dynamic request payload based on the individual data
  //   const abacusApiBody = {
  //     partnerId: '98',
  //     abacusId: '5227',
  //     postedField: {
  //       field_1: '1',
  //       field_3: this.individual.age,
  //       field_2: '7',
  //       field_4: '1 Year',
  //       field_54: this.individual.pincode,
  //       field_9: 'Individual',
  //       outPutField: 'field_8',
  //       field_35:'0'
  //     },
  //   };
  //   // //console.log(abacusApiBody);

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${accessToken}`,
  //   });

  //   return this.http.post(abacusApiUrl, abacusApiBody, { headers });
  // }
  // executeSupremeApiRequest(): Observable<any> {
  //   return this.getAccessToken().pipe(
  //     switchMap((accessToken) => {
  //       return this.makeAbacusSupremeApiRequest(accessToken).pipe(
  //         catchError((error) => {
  //           console.error('Error making Abacus API request:', error);
  //           throw error; // Re-throw the error to propagate it downstream
  //         })
  //       );
  //     }),
  //     catchError((error) => {
  //       console.error('Error getting access token:', error);
  //       throw error; // Re-throw the error to propagate it downstream
  //     })
  //   );
  // }
  // Freedom
  private makeAbacusFreedomApiRequest(accessToken: string, addon?): Observable<any> {
    const abacusApiUrl = `${CareEnviroment.apiUrl}/abacus/partner?formattype=json`;

    const ageRanges = [
      "5 Yrs",
      "6 - 17 Yrs",
      "18 - 35 Yrs",
      "36 - 40 Yrs",
      "41 - 45 Yrs",
      "46 - 50 Yrs",
      "51 - 55 Yrs",
      "56 - 60 Yrs",
      "61 - 65 Yrs",
      "66 - 70 Yrs",
      "71 - 75 Yrs",
      "76 - 80 Yrs",
      " > 80 Yrs",
    ];

    // Constructing the dynamic request payload based on the individual data
    const abacusApiBody = this.createPayload(CareEnviroment.CareFreedom, ageRanges);
    if (addon) {
      (abacusApiBody.postedField as any).field_Deductible = addon.filter(addon => addon.addon_id === "CAREFREEDOMDEDUCTIBLERIDER-25000").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_HCP = addon.filter(addon => addon.addon_id === "CFHP").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_HomeCare = addon.filter(addon => addon.addon_id === "CFWHC").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_OPD = addon.filter(addon => addon.addon_id === "COPD1211").length > 0 ? 1 : 0;
  }
  this.careFreedompayload = abacusApiBody
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    });

    return this.http.post(abacusApiUrl, abacusApiBody, { headers });
  }
  executeFreedomApiRequest(addon?): Observable<any> {
    localStorage.setItem("careTransactionId",this.careTransactionID())
    return this.getAccessToken().pipe(
      switchMap((accessToken) => {
        return this.makeAbacusFreedomApiRequest(accessToken, addon).pipe(
          catchError((error) => {
            console.error("Error making Abacus API request:", error);
            throw error; // Re-throw the error to propagate it downstream
          })
        );
      }),
      catchError((error) => {
        console.error("Error getting access token:", error);
        throw error; // Re-throw the error to propagate it downstream
      })
    );
  }
  // Advantage
  private makeAbacusAdvantageApiRequest(accessToken: string, addon?): Observable<any> {
    const abacusApiUrl = `${CareEnviroment.apiUrl}/abacus/partner?formattype=json`;

    // Construct dynamic request payload
    const ageRanges = [
      "5 - 17 years",
      "18 - 24 years",
      "25 - 35 years",
      "36 - 40 years",
      "41 - 45 years",
      "46 - 50 years",
      "51 - 55 years",
      "56 - 60 years",
      "61 - 65 years",
      "66 - 70 years",
      "71 - 75 years",
      "76 - 80 years",
      "> 80 years",
    ];
    const abacusApiBody = this.createPayload(CareEnviroment.CareAdvantage, ageRanges);
    if(addon && addon.length> 0) {
      (abacusApiBody.postedField as any).field_NCB = addon.filter(addon => addon.addon_id === "CAREADWITHNCB").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_35 = addon.filter(addon => addon.addon_id === "AACCA1090").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_43 = addon.filter(addon => addon.addon_id === "RIPEDCA1092").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_AHC = addon.filter(addon => addon.addon_id === "HCUPCA1093").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_34 = addon.filter(addon => addon.addon_id === "RRMCA").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_SS = addon.filter(addon => addon.addon_id === "SMARTCA").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_OPD = addon.filter(addon => addon.addon_id === "COPD1211").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_CPW = addon.filter(addon => addon.addon_id === "CFWHC").length > 0 ? 1 : 0;
      (abacusApiBody.postedField as any).field_CS = addon.filter(addon => addon.addon_id === "CARESHILED1104").length > 0 ? 1 : 0;
    }
  
    this.careAdvantage = abacusApiBody
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    });

    return this.http.post(abacusApiUrl, abacusApiBody, { headers });
  }

  executeAdvantageApiRequest(addon?): Observable<any> {
    localStorage.setItem("careTransactionId",this.careTransactionID())
    return this.getAccessToken().pipe(
      switchMap((accessToken) => {
        return this.makeAbacusAdvantageApiRequest(accessToken, addon).pipe(
          catchError((error) => {
            console.error("Error making Abacus API request:", error);
            throw error; // Re-throw the error to propagate it downstream
          })
        );
      }),
      catchError((error) => {
        console.error("Error getting access token:", error);
        throw error; // Re-throw the error to propagate it downstream
      })
    );
  }
  // Heart
  private makeAbacusHeartApiRequest(accessToken: string, addon): Observable<any> {
    const abacusApiUrl = `${CareEnviroment.apiUrl}/abacus/partner?formattype=json`;
    // Constructing the dynamic request payload based on the individual data
    const ageRanges = [
      "18 - 24 years",
      "25 - 35 years",
      "36 - 40 years",
      "41 - 45 years",
      "46 - 50 years",
      "51 - 55 years",
      "56 - 60 years",
      "61 - 65 years",
      "66 - 70 years",
      "71 - 75 years",
      " 76 - 80 years",
      " > 80 years",
    ];
    let abacusApiBody = this.createPayload(CareEnviroment.CareHeart, ageRanges);
    if(addon && addon.length > 0) {
      (abacusApiBody.postedField as any).field_OPD = addon.filter(addon => addon.addon_id === "COPD1211").length > 0 ? 1 : 0;
    (abacusApiBody.postedField as any).field_HomeCare = addon.filter(addon => addon.addon_id === "CFWHC").length > 0 ? 1 : 0;
    (abacusApiBody.postedField as any).field_CS = addon.filter(addon => addon.addon_id === "CARESHILEDCF1209").length > 0 ? 1 : 0;
    }
    this.careHeart = abacusApiBody
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    });
    const tab =localStorage.getItem('tab')
    if (tab === "F") {
      const  userData = JSON.parse(localStorage.getItem("userData2"));
      if( parseInt(userData.adult_number , 10) < 3 &&   parseInt(userData.child_number, 10) ===0) {
        return this.http.post(abacusApiUrl, abacusApiBody, { headers });
      } else {
        return EMPTY
      }
    }
      return this.http.post(abacusApiUrl, abacusApiBody, { headers });
   
  }
  executeHeartApiRequest(addons?): Observable<any> {
    localStorage.setItem("careTransactionId",this.careTransactionID())
    return this.getAccessToken().pipe(
      switchMap((accessToken) => {
        return this.makeAbacusHeartApiRequest(accessToken,addons).pipe(
          catchError((error) => {
            console.error("Error making Abacus API request:", error);
            throw error; // Re-throw the error to propagate it downstream
          })
        );
      }),
      catchError((error) => {
        console.error("Error getting access token:", error);
        throw error; // Re-throw the error to propagate it downstream
      })
    );
  }

  private createPayload(abacusId, ageRanges) {
    const tab = localStorage.getItem("tab");
    let userData: any;
    let child_number: number;
    let cover_type: string;
    let ageToFilter 
    let insured_member;
    if (tab === "I") {
      userData = JSON.parse(localStorage.getItem("userData1"));
      child_number = 0;
      cover_type = "Individual";
      ageToFilter = userData.age;
    } else if (tab === "F") {
      userData = JSON.parse(localStorage.getItem("userData2"));
      insured_member = JSON.parse(localStorage.getItem("userData2Insured"));
      cover_type = "Floater";
      child_number = userData.child_number;
      ageToFilter=this.eldestMemberage(insured_member);
    }

    const filteredRanges = ageRanges.filter((range) => {
      const [minStr, maxStr] = range.split(" - ");
      const min = parseInt(minStr, 10);

      // Handle the case where maxStr is not a valid number
      const max = maxStr === "> 80 years" ? Infinity : parseInt(maxStr, 10);

      //console.log(`Checking range: ${range}, min: ${min}, max: ${max}`);

      if (!isNaN(min) && !isNaN(max)) {
        const result = ageToFilter >= min && ageToFilter <= max;
        //console.log(`Result: ${result}`);
        return result;
      } else {
        //console.log(`Invalid range: ${range}`);
        return false;
      }
    });
    let coverAmount = this.transform( userData.coverAmount)
    let payload = {
      "SI" :  userData.coverAmount,
      "Tenure": userData.tenure
    }
    localStorage.setItem("carePremiumPayload", JSON.stringify(payload))
    return {
      partnerId: CareEnviroment.partnerId,
      abacusId: abacusId,
      postedField: {
        field_1: userData.adult_number,
        field_2: coverAmount,
        field_10: child_number,
        field_4: userData.tenure + ' Year',
        field_NS: "Resident of India",
        field_3: filteredRanges[0],
        field_9: cover_type,
        field_54: userData.pincode,
      },
    };
  }

  eldestMemberage(insured_member) {
    let eldestAge = 0;

    insured_member[0].form.forEach((member) => {
      const birthDate = new Date(member.DateOfBirth);
      const age = this.calculateAge(birthDate);
      eldestAge = Math.max(eldestAge, age);
    });
    return eldestAge;
  }

  private calculateAge(dateOfBirth: Date): number {
    
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    }

    return age;
  }

  private transform(value: string): string {
    let numberValue = parseInt(value, 10);
    if (isNaN(numberValue)) {
      return value;
    }
    return (numberValue / 100000).toString();
  }

  private careTransactionID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) >> (c === 'x' ? 0 : 4);
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
} 

@Injectable({
  providedIn: "root",
})

export class CheckPolicyStatusAndDownload {
  constructor(private http: HttpClient,
    private careToken :CareToken,
    private commonService: CommonService ) {}

  checkPolicyStatus(proposal_no, policy_no): Observable <any> {
    const getPoposalUrl =  `/userPlanTransactionRoute/detailsByProposalID/${proposal_no}/${policy_no}`
    var proposal_no  
    this.commonService.get(getPoposalUrl).subscribe(res => {
      proposal_no = res.data.proposal_id_value
    })
    return this.careToken.generateToken(CareEnviroment.PostProposalappId).pipe(
      switchMap((encryptedToken: any) => {
          let headers = new HttpHeaders({
              appId: CareEnviroment.PostProposalappId,
              signature: CareEnviroment.Signature,
              timestamp: Date.now().toString(),
              applicationCD: CareEnviroment.applicationCD,
              sessionId: this.careToken.sessionId,
              tokenId: encryptedToken.encryptedText,
              agentId: CareEnviroment.baseAgentId,
              "Content-Type": "application/json",
          });
          const payload = {
            "intGetPolicyStatusIO": {
                "proposalNum": proposal_no
            }
        }
          return this.http.post(CareEnviroment.policyStatus, payload, { headers });
      }),
      catchError((error) => {
          console.error("Error occurred during KYC process:", error);
          return throwError(error);
      })
  );
    
  }

  downloadPolicy(policyNum): Observable <any> {
    return this.careToken.generateToken(CareEnviroment.PostProposalappId).pipe(
      switchMap((encryptedToken: any) => {
          let headers = new HttpHeaders({
              appId: CareEnviroment.PostProposalappId,
              signature: CareEnviroment.Signature,
              timestamp: Date.now().toString(),
              applicationCD: CareEnviroment.applicationCD,
              sessionId: this.careToken.sessionId,
              tokenId: encryptedToken.encryptedText,
              agentId: CareEnviroment.baseAgentId,
              "Content-Type": "application/json",
          });
          const payload = {
            "intFaveoGetPolicyPDFIO":{"policyNum":policyNum,
            "ltype":"POLSCHD"
          }
        }
          return this.http.post(CareEnviroment.downloadPolicy, payload, { headers });
      }),
      catchError((error) => {
          console.error("Error occurred during download document:", error);
          return throwError(error);
      })
  )

  }

}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { constant } from '../constant';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { CommonService } from '../services/common.service';
import { bankMaster } from 'src/hdfcConfig/bankMaster';
import  { PaymentGatewayForHDFC, DownloadDocument } from "../services/hdfcergo.service"
import * as moment from 'moment';
import { StatusAndDownloadDocument } from "../services/goDigit.service"
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CheckPolicyStatusAndDownload } from '../services/care.service';


@Component({
  selector: 'app-hdfcthankyou',
  templateUrl: './hdfcthankyou.component.html',
  styleUrls: ['./hdfcthankyou.component.css']
})
export class HdfcthankyouComponent implements OnInit {

  @ViewChild('PolicyStatus', { static: false }) policyStatusTemplate: TemplateRef<any>;
  @ViewChild('KYCStatus', { static: false }) KYCStatusTemplate: TemplateRef<any>; 
  @ViewChild('CarePolicyStatus', { static: false }) CarePolicyStatusTemplate: TemplateRef<any>; 
  @ViewChild('document', { static: false }) documentTemplate: TemplateRef<any>; // Use static option
  modalRef2: BsModalRef;
  careProposalData
  goDigitDocumentData
  carePaymentData: any
  goDigitStatusChecked = false
  goDigitKYCLink
  goDigitPolicyStatus:any
  paymentId: string;
  loading = false
  downloadCertificate = false
  goDigitProposal: any
  txnid: string;
  isCare= false
  isHdfc = false
  isGoDigit =false
  careErrorMessage: string
  plandetail:any = {};
  hdnmsg: string = "";
  paymentDetails: any = {};
  Customer_Details : any = {};
  Policy_Details: any = {};
  goDigitPaymentData: any
  user

  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private hdfcPaymentService: PaymentGatewayForHDFC, 
    private downlaodDocument: DownloadDocument,
    private goDigitStatus:StatusAndDownloadDocument,
    private modalService: BsModalService,
    private policyStatus:CheckPolicyStatusAndDownload   
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if( params['hdnmsg']){
        this.hdnmsg = params['hdnmsg'] || "";
        this.parseHdnmsg();

      } else if(localStorage.getItem('platform') === 'World Wide Treatment Plan' || localStorage.getItem('platform') === 'Double Wallet Plan' || localStorage.getItem('platform') === 'Infinity Wallet Plan') {
       this.showGoDigitPaymentData(params)
      }
       else  {
        this.showCarePaymentData(params)
      }
    });
    const tab = localStorage.getItem('tab')
    if (tab === 'I') {
      this.user = JSON.parse(localStorage.getItem('userData1'));
      } else if (tab === 'F') {
          this.user = JSON.parse(localStorage.getItem('userData2'));
      } else {
          this.user = JSON.parse(localStorage.getItem('userData3'));
      }
      this.plandetail.username = this.user.userName
  }
//  HDFC Submit payment function
  parseHdnmsg(): void {
    this.isHdfc =true
    if (this.hdnmsg) {
      const details = this.hdnmsg.split('|');
      this.paymentDetails = {
        MerchantID: details[0],
        TransactionNo: details[1],
        TransactionRefNo: details[2],
        BankReferenceNo: details[3],
        TxnAmount: details[4],
        BankCode: details[5],
        IsSIOpted: details[6],
        PaymentMode: details[7],
        PG_Remarks: details[8],
        PaymentStatus: details[9],
        TransactionDate: details[10],
        AppID: details[11],
        Checksum: details[12]
      };
      this.saveTransactionDetails(details[4], details[12], details[0], details[2], details[9] === "SPD" ? 1 : 0, "HDFC", null  )
    }
    this.txnid = this.paymentDetails.PaymentStatus
   const payload = this.submitPaymentPayload()
    this.hdfcPaymentService.paymentDetailsSumbit(payload).subscribe(response=>{
      if(response.StatusCode === 200 && response.Message === null ) {
        this.Customer_Details = response.Customer_Details;
        this.Policy_Details  = response.Policy_Details;
        localStorage.setItem('hdfcPolicy',response.Policy_Details.PolicyNumber)
      this.updateUserTransactionModel(response.Policy_Details.TotalPremium, response.TransactionID, "Success", "Success", response.Policy_Details.PolicyNumber, null, response.Policy_Details.ProposalNumber )
      }
    })
  }

  showCarePaymentData(data:any){
    this.isCare = true
    this.carePaymentData = data
    let premiumData = JSON.parse(localStorage.getItem('careProposal'))
    if(data.errorMsg==="") {
       this.txnid = 'SPD'
    } 
    this.paymentDetails.TxnAmount =Number(premiumData.premium);
    this.paymentDetails.TransactionNo = data.transactionRefNum
    this.Policy_Details.PolicyNumber = data.policyNumber
    this.updateUserTransactionModel(this.paymentDetails.TxnAmount, data.transactionRefNum, "Success", "Success", data.policyNumber, null, localStorage.getItem('careProposalNum') )
  }
  
  downloadCarePolicy(policyNum){
    this.startloading();
    this.downloadCertificate = true
    this.policyStatus.downloadPolicy(policyNum).subscribe(response => {
      this.stoploading();
      if(response.responseData.status ==="1" && response.responseData.message ==="Success") {
        this.downlaodDocument.downloadPDF(response.intFaveoGetPolicyPDFIO.dataPDF, "CarePolicy.pdf")

      }
      else {
        this.toastr.error("Oops! We encountered an error while downloading the policy. Please try again in some time.")
      }
    })
   
  }

  checkCarePolicyStatus(proposal_no: any, policy_no: any) {
    this.startloading();
    this.policyStatus.checkPolicyStatus(proposal_no, policy_no).subscribe(response=>{
      this.stoploading();
      if(response.responseData.status === "1" &&  response.responseData.message === "Success") {
        this.careProposalData = response.intGetPolicyStatusIO
        this.modalRef2 = this.modalService.show(this.CarePolicyStatusTemplate);
      }
      else {
        this.toastr.error("Oops! We encountered an error while checking the policy status. Please try again in some time.")
      }
    })
    
  }

  downloadPolicy(){
    if(this.isHdfc === true){
      this.downloadHdfcPolicy()
    }
    if(this.isGoDigit === true)
    this.downloadGoDigitPolicy()
  }
  

  
  submitPaymentPayload () {
    let bankName = ""
    const bankCodeNumber = Number(this.paymentDetails.BankCode);
    const bankDetail = bankMaster.filter(bank => bank.NUM_FINANCIER_CD === bankCodeNumber);
    if(bankDetail.length > 0) {
      bankName = bankDetail[0].TXT_FINANCIER_NAME
    } else {
      bankName = this.paymentDetails.BankCode

    }
    
    return {
      "TransactionID": localStorage.getItem('TransactionId'),
      "Proposal_no": localStorage.getItem('hdfcProposal'),
      "Payment_Details": {
          "GC_PaymentID": null,
          "BANK_NAME": bankName ? bankName : 'BIZDIRECT',
          "BANK_BRANCH_NAME": "Andheri",
          "PAYMENT_MODE_CD": 'EP',
          "PAYER_TYPE": "CUSTOMER",
          "PAYMENT_AMOUNT": this.paymentDetails.TxnAmount,
          "INSTRUMENT_NUMBER":this.paymentDetails.TransactionNo,
          "PAYMENT_DATE": this.paymentDetails.TransactionDate,
          "OTC_Transaction_No": null,
          "IsReserved": 0,
          "IsPolicyIssued": null,
          "Elixir_bank_code": null
      }
  }
  }

  getPlanDetails() {
    const url = `/userPlanTransactionRoute/detailsByProposalID/${this.paymentId}`;
    this.commonService.get(url).subscribe(res => {
      //console.log("Plan Details : ", res)
      this.ngxLoader.stop()
      let username;
      if (res.data.dbuser.middle_name) {
        username = res.data.dbuser.first_name + ' ' + res.data.dbuser.middle_name + ' ' + res.data.dbuser.last_name;
      } else {
        username = res.data.dbuser.first_name + ' ' + res.data.dbuser.last_name;
      }
      this.plandetail = {
        plan: res.data.plan_name,
        plan_id: res.data.plan_id,
        premium_id: res.data.premium_id,
        premium_type: res.data.construct,
        username: username,
        contactNumber:res.data.dbuser.contactNumber,
        gender: (res.data.dbuser.gender == 'MALE') ? '1' : '2',
        age: res.data.dbuser.age,
        city: 'Kolkata',
        session_id: res.data.session_id,
        company: res.data.company_master.company_name,
        si: res.data.si_amount,
        premium: Math.round(res.data.premium_master.premium_with_gst),
        term: res.data.premium_term
      }
      //console.log(this.plandetail)
    }, err => {
      this.ngxLoader.stop();
      //console.log("Error : ", err)
      this.toastr.error("Something went wront while fetching Plan Details", "Error");
    })
  }

  afterPaymentSMS() {
    const shortenURL = this.commonService.createbitlyshortlink(JSON.stringify({ "long_url": constant.playstore_link, "domain": "bit.ly" })).subscribe(res=>{
      let link = res.link
        const body = {
            "contactNumber": this.plandetail.contactNumber,
            "clickLink": link
        };
        const url = `/smsRoute/welcome_after_payment`;
        this.commonService.post(url, body).subscribe((response) => {
            if (response.error.errorCode === 200) {
                //console.log("Welcome SMS : ", response);
            }
        }, (error) => {
            //console.log("SMS error ts: ", error);
            this.toastr.error(error.error.error.errorMessage || 'Please try after sometime', "Error");
        });
    })
  }

  paymentUnsucessSMS(url:string){
    const shortenURL = this.commonService.createbitlyshortlink(JSON.stringify({ "long_url": url, "domain": "bit.ly" })).subscribe(res=>{
      let link = res.link
        const body = {
            "contactNumber": this.plandetail.contactNumber,
            "clickLink": link
        };
        const url = `/smsRoute/transaction_failure`;
        this.commonService.post(url, body).subscribe((response) => {
            if (response.error.errorCode === 200) {
                //console.log("Welcome SMS : ", response);
            }
        }, (error) => {
            //console.log("SMS error ts: ", error);
            this.toastr.error(error.error.error.errorMessage || 'Please try after sometime', "Error");
        });
    })
  }

  downloadProposal ( ) {
    this.downloadCertificate = true
    this.startloading();
   const payload = {
    "TransactionID": localStorage.getItem('TransactionId'),
    "Req_Policy_Document": {
        "Proposal_Number": localStorage.getItem('hdfcProposal')
      }
    }
    this.downlaodDocument.downloadProposal(payload).subscribe(proposalResponse=>{
      if (proposalResponse && proposalResponse.Resp_Policy_Document && proposalResponse.Resp_Policy_Document.PDF_BYTES) {
        this.downlaodDocument.downloadPDF(proposalResponse.Resp_Policy_Document.PDF_BYTES, 'ProposalPDf.pdf');
        this.stoploading();
      } else {
        console.error('PDF bytes are not available');
        this.stoploading();
      }
    })
  }


  downloadHdfcPolicy () {
    this.downloadCertificate = true
   this.startloading();
    const payload ={
      "TransactionID": localStorage.getItem('TransactionId'),
      "Req_Policy_Document": {
          "Policy_Number":localStorage.getItem('hdfcPolicy')
      }

    }
    this.downlaodDocument.downloadPolicy(payload).subscribe(policyresponse=>{
     if (policyresponse && policyresponse.Resp_Policy_Document && policyresponse.Resp_Policy_Document.PDF_BYTES) {
      this.downlaodDocument.downloadPDF(policyresponse.Resp_Policy_Document.PDF_BYTES, 'PolicyDocument.pdf');
     this.stoploading();
    } else {
      console.error('PDF bytes are not available');
      this.stoploading();
    }
    }) 
 }
 
 showGoDigitPaymentData(data:any){
  if(data.policyNumber) {
    localStorage.setItem('goDigitPaymentData',JSON.stringify(data))
  }
 
  if(data.kycVerificationSuccess=== 'true'){
    this.goDigitPaymentData = JSON.parse(localStorage.getItem("goDigitPaymentData"))
    if(this.goDigitPaymentData.transactionNumber != '') {
      this.isGoDigit = true
      this.txnid = 'SPD'
      this.goDigitProposal = JSON.parse(localStorage.getItem('goDigit_proposal'))
      this.goDigitProposal.kycStatus.kycVerificationStatus = "true"
      this.paymentDetails.TransactionNo = this.goDigitPaymentData.transactionNumber
      this.paymentDetails.TxnAmount = Number(this.goDigitProposal.premium.grossPremium.replace(/INR /g, '').replace(/,/g, ''));
      this.Policy_Details.PolicyNumber =  this.goDigitPaymentData.policyNumber
     
    }
  } else {
    if(data.transactionNumber !== '') {
      this.isGoDigit = true
      this.txnid = 'SPD'
      this.goDigitProposal = JSON.parse(localStorage.getItem('goDigit_proposal'))
      this.paymentDetails.TransactionNo = data.transactionNumber
      this.paymentDetails.TxnAmount = Number(this.goDigitProposal.premium.grossPremium.replace(/INR /g, '').replace(/,/g, ''));
      this.Policy_Details.PolicyNumber =  data.policyNumber
     
    }
  }
 }
 
 downloadGoDigitPolicy() {
  this.startloading();
  this.downloadCertificate = true
  this.goDigitProposal = JSON.parse(localStorage.getItem('goDigit_proposal'))
  this.goDigitProposal.kycStatus.kycVerificationStatus = "true"
  this.goDigitStatus.downloadDocument( this.Policy_Details.PolicyNumber ).subscribe (Response=> {
    this.stoploading();
    this.goDigitDocumentData = Response["Retail Health-PDF Service"]
    this.modalRef2 = this.modalService.show(this.documentTemplate);
  })
 }

 checkKYCStatus() {
  this.startloading();
  this.goDigitStatus.Response( this.Policy_Details.PolicyNumber).subscribe (Response=> {
    this.stoploading();
    const statusData = Response["Retail Health-KYC Status API"]
    if(statusData.kycVerificationStatus === "NOT_DONE" && statusData.link != ""){
      this.goDigitKYCLink = statusData.link
      this.modalRef2 = this.modalService.show(this.KYCStatusTemplate);
    } else {
      this.toastr.error("Oops! We encountered an error while fetching the KYC status. Please try again after some time", "error")
    }
  })
 }
 checkPolicyStatus() {
  this.startloading();
  this.goDigitStatus.checkPolicyStatus(this.Policy_Details.PolicyNumber).subscribe(Response=>{
    this.stoploading();
    if(Response.status.code===200 && Response.status.message === "OK" ){
      this.goDigitPolicyStatus = Response.responseBody
      this.goDigitProposal.policyStatus = Response.responseBody.policyStatus
      if( Response.responseBody.policyStatus === "EFFECTIVE"){
        this.goDigitStatusChecked = true
      }
      this.modalRef2 = this.modalService.show(this.policyStatusTemplate);
    } else {
      this.toastr.error("Oops! We encountered an error while fetching the policy status. Please try again after some time")
    }
   })
  }

  isLinkValid(link: string): boolean {
    return link && link.length > 0;
  }

  startloading(){
    this.loading = true
  }

  stoploading() {
    this.loading =false
  }

  saveTransactionDetails(amount: string, authCode: string, MerchantID: string, pgTransactionID: string, isSuccess: number, Company: string, gatewayName: string) {
    const addTransaction = '/transaction/addTransaction'
    const paymentPayload = {
      'g_id':localStorage.getItem('g_id'),
      "Amount" :amount,
      "AuthCode": authCode,
      "GatewayName" :gatewayName,
      "MerchantId": MerchantID,
      "PGTransactionId": pgTransactionID,
      "Success":isSuccess,
      "UserId" :localStorage.getItem('getquoteuserID'),
      "Company": Company,
      "TransactionId": localStorage.getItem('TransactionId')
    }
    this.commonService.post(addTransaction, paymentPayload).subscribe()

  }

  updateUserTransactionModel(amount: string, TransactionId: string, txtMsg: string, txtStatus: string, policyNo: string, quoteNumber: string,quoteID: string  ) {
     const policyAfterpayment = '/userPlanTransactionRoute/responseAfterPolicyPurchase'
    const currentDateTime = moment();
          const txnDate = currentDateTime.format('YYYY-MM-DD');
          const txnTime = currentDateTime.format('HH:mm:ss'); 
        const payload = {
          "amount":amount,
          "TxRefNo":TransactionId,
          "currency": "INR",
          "paymentMode": "",
          "pgRespCode": null,
          "TxMsg": txtMsg,
          "TxStatus": txtStatus,
          "txnDateTime":  currentDateTime.toISOString(),
          "txnDate":txnDate,
          "txnTime":txnTime,
          "signature": "",
          "policyNo": policyNo,
          "QuoteNumber": quoteNumber,
          "QuoteId": quoteID,
      }
      this.commonService.post(policyAfterpayment, payload ).subscribe()

  }

}


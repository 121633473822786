import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { state } from '@angular/animations';
import { stringify } from 'querystring';
import { master_pincode } from '../icici_constant/PROD_PINCODE_MASTER';
import { HDFCenvironment } from './../../environments/environment';
import { PincodeDetails } from './pincodedetails.service';
import { DatePipe } from '@angular/common';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { FormGroup } from '@angular/forms';
import { constant } from '../constant';
import { CommonService } from './common.service';


@Injectable({
    providedIn: 'root',
  })
  export class CalculatePremiumforHealthHDFC {
    
    pincodeData:{}
    response : any
    individual: any = {
        type: 'I',
        gender: '1',
        age: '',
        dob: '',
        adult_number: 1,
        policyTenure: 1,
        child_number: 0,
        userName: '',
        emailId: '',
        city_id: '',
        contactNumber: '',
        coverAmount: '',
        tenure: '',
        ped: '0',
        RelationshipWithApplicant:'SELF',
        pincode: '',
        diseaseSelection: [
        ]
      }
      familyData: any = {
        type: 'F',
        gender: '1',
        DOB: '',
        coverAmount: '',
        adult_number: 1,
        child_number: 0,
        pincode: '',
        userName: '',
        emailId: '',
        city_id: '',
        contactNumber: '',
        ped: '0',
        tenure: ''
      }
      constructor(private http: HttpClient,
       private pincodeDetails: PincodeDetails,
       private datepipe :DatePipe) {}

      private authenticate(): Observable<string> {
        const apiURL = `${HDFCenvironment.HDFCAPIURL}authenticate`
        const headers = new HttpHeaders({
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'CREDENTIAL': HDFCenvironment.userDetails.CREDENTIAL,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID
          });
      
          return this.http.get<any>(apiURL, { headers }).pipe(
            map(response => response.Authentication.Token)
          );
      }

      calculateHDFCpremium(members? : any, index?: any, planType?:string ): Observable<any> {
        return this.authenticate().pipe(
          switchMap((accessToken) => {
            const apiURL = `${HDFCenvironment.HDFCAPIURL}calculatepremium`;
            const headers = new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              'SOURCE': HDFCenvironment.userDetails.SOURCE,
              'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
              'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
              'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
              'token': accessToken
            });
            return from(this.premiumpayload()).pipe(
              switchMap((payload) => {
                if (members && Number.isInteger(members)) {
                  payload.Policy_Details.Deductible = members;
                  payload.Policy_Details.TypeofPlan = localStorage.getItem('platform')
                } else if(members && index != undefined) {
                  payload.Request_Data_OS.memberDetailsList[index].IsIPARider = members.IPA
                  payload.Request_Data_OS.memberDetailsList[index].IsUnlimitedRestore= members.UR,
                  payload.Request_Data_OS.memberDetailsList[index].IsHDCRider_Global=members.HDC_Global
                  payload.Request_Data_OS.memberDetailsList[index].IsOptimaWellbeing =members.OptimaWellbeing
                  payload.Request_Data_OS.memberDetailsList[index].Insured_HospitalCashAddonCover_SumInsured =members.HDC
                  payload.Request_Data_OS.memberDetailsList[index].Insured_CriticalIllnessAddonCover_PlanType = members.Critical_Illness_plan
                  payload.Request_Data_OS.memberDetailsList[index].Insured_CriticalIllnessAddonCover_SumInsured =members.Critical_Illness_Amount
                  payload.Policy_Details.Deductible = parseInt(localStorage.getItem('deductibleAmount'),  10) 
                  payload.Policy_Details.TypeofPlan = localStorage.getItem('platform')
                }
                if(planType){
                  payload.Policy_Details.Deductible = 0
                  payload.Policy_Details.TypeofPlan = planType
                }
               
                localStorage.setItem('icici_Policy_Details', JSON.stringify(payload.Policy_Details));
                localStorage.setItem('Hdfc_Policy_payload', JSON.stringify(payload));
                this.response = this.http.post(apiURL, payload, { headers });
                return this.response
              })
            );
          }),
          catchError(() => {
            return this.response
          })
        );
      }
      private series: string = '1153';
   
      private   generateTransactionID(): string {
        const date = new Date();
        const year = date.getFullYear().toString().slice(-2);
        const month = this.pad(date.getMonth() + 1, 2);
        const day = this.pad(date.getDate(), 2);
        const randomNo = this.pad(Math.floor(Math.random() * 100000), 5);
        const result = `${this.series}${year}${month}${day}${randomNo}`;
        localStorage.setItem("TransactionId",result)
        return result
    }
       
   
      private pad(num: number, size: number): string {
        let s = num.toString();
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }
    private createFamilyType(adult:number, child: number){
      return `${adult}A+${child}C`
    }
    private async premiumpayload(){
        let proposal_proposer = JSON.parse(localStorage.getItem('proposal_Proposer'))
        let policyType='';
        const tab = localStorage.getItem('tab'); 
        let userData:any
        let insured_member 
        let memberDetailsList: any[] = [];
        let FamilyType = ''
        if(tab === 'I') {
            userData = JSON.parse(localStorage.getItem('userData1'));
            policyType ='Individual'
            FamilyType = this.createFamilyType(1,0)
            let formattedDateOfBirth = this.datepipe.transform(userData.dob, 'dd/MM/yyyy');
            memberDetailsList.push({
              "Insured_Salutation": "MR",
              "Insured_DateOfBirth": formattedDateOfBirth,
              "Insured_Name": "xyz",
              "Insured_Relation":'self',
              "Insured_Gender": "",
              "Insured_MaritalStatus": "",
              "Insured_Dependent": "",
              "Insured_OccupationID": "",
              "Insured_HeightINcms": '',
              "Insured_WeightINkgs": '',
              "Insured_Profession": "",
              "Insured_Nationality": "Indian",
              "Insured_ResidenceStatus": "Indian",
              "Insured_PortabilityApplicable": "No",
              "Insured_HospitalCashAddonCover_SumInsured": '',
              "Insured_CriticalIllnessAddonCover_PlanType": "",
              "IsUnlimitedRestore": false,
              "IsOverseasTravelSecure": false,
              "Insured_AnnualIncome": proposal_proposer.annual_income !== undefined ? proposal_proposer.annual_income: "",
              "Insured_SumInsured": 0,
              "AppointeName": "",
              "AppointeRelation": "",
              "AppointeAddress": "",
              "Insured_Qualification": "EDU_QUAL_BA",
              "MedicalQuestionnaire": [],
              "memberLifeStylelist": [],
              "IsIPARider":"",
              "IsHDCRider_Global":""

            })

        } else if(tab==='F'){
            userData = JSON.parse(localStorage.getItem('userData2'));
            insured_member = JSON.parse(localStorage.getItem('userData2Insured'))
            policyType='Family';
            FamilyType = this.createFamilyType(userData.adult_number, userData.child_number)
     
        insured_member[0].form.forEach(memebr => {
       
          let formattedDateOfBirth = this.datepipe.transform(memebr.DateOfBirth, 'dd/MM/yyyy');
          memberDetailsList.push({
            "Insured_Salutation": "MR",
            "Insured_DateOfBirth": formattedDateOfBirth,
            "Insured_Name": "xyz",
            "Insured_Relation":memebr.relation,
            "Insured_Gender": "",
            "Insured_MaritalStatus": "",
            "Insured_Dependent": "",
            "Insured_OccupationID": "",
            "Insured_HeightINcms": '',
            "Insured_WeightINkgs": '',
            "Insured_Profession": "",
            "Insured_Nationality": "Indian",
            "Insured_ResidenceStatus": "Indian",
            "Insured_PortabilityApplicable": "No",
            "Insured_CriticalIllnessAddonCover_SumInsured": '',
            "Insured_HospitalCashAddonCover_SumInsured": '',
            "Insured_CriticalIllnessAddonCover_PlanType": "",
            "IsUnlimitedRestore": false,
            "IsOverseasTravelSecure": false,
           "Insured_AnnualIncome": proposal_proposer.annual_income !== undefined ? proposal_proposer.annual_income: "",
            "Insured_SumInsured": 0,
            "AppointeName": "",
            "AppointeRelation": "",
            "IsIPARider":"",
            "AppointeAddress": "",
            "Insured_Qualification": "EDU_QUAL_BA",
            "MedicalQuestionnaire": [],
            "memberLifeStylelist": [],
            "IsHDCRider_Global":""
          })
        });
      }
        const pincodeResult = await this.pincodeDetails.getPincode(userData.pincode);
        const districtUpperCase = pincodeResult[0] && pincodeResult[0].PostOffice && pincodeResult[0].PostOffice[0] && pincodeResult[0].PostOffice[0].District
              ? pincodeResult[0].PostOffice[0].District.toUpperCase()
              : null;
        localStorage.setItem('district',districtUpperCase)
       const today = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
       let policyPayload = localStorage.getItem('Hdfc_Policy_payload');
       if (!policyPayload) {
       return   {
            "TransactionID": this.generateTransactionID(),
            "Policy_Details": {
                "PolicyStartDate": today,
                "ProposalDate": today,
                "BusinessType_Mandatary": "New Business",
                "SumInsured": userData.coverAmount,
                "PolicyType": policyType,
                "FamilyType": FamilyType,
                "TypeofPlan": "",
                "PolicyTenure": userData.tenure,
                "Deductible": 0
            },
            "Request_Data_OS": {
                "AppNumber": null,
                "CustomerID": null,
                "TransactionID": null,
                "isProspectModified": "No",
                "isCustomerModified": "No",
                "ChannelName": HDFCenvironment.userDetails.ChannelName,
                "Other_Details": {
                    "LGCode": "",
                    "SPCode": "",
                    "NomineeRelation": "",
                    "Nomineename": "",
                    "NomineeAddress": "",
                    "agentId": HDFCenvironment.userDetails.agentId,
                    "GoGreenFlag": "No"
                },
                "proposer": {
                    "Proposer_Mailing_PinCode":userData.pincode,
                    "Proposer_Mailing_City": districtUpperCase,
                    "Proposer_Mailing_District": districtUpperCase
                },
                "memberDetailsList": memberDetailsList,
                "memberPYPDetailsList": null,
                "Loading_Discount": {
                    "LoyaltyDiscount": false,
                    "PreviousPolicyNumber": "",
                    "overseasResidenceStatus": false,
                   "currentResidenceStatus": false
                }
            }
        }

      } else {
        let policyPayload =JSON.parse(localStorage.getItem('Hdfc_Policy_payload'))
        policyPayload.Policy_Details.PolicyTenure =userData.tenure
        return policyPayload
      }
    }
  }

  @Injectable({
    providedIn: 'root',
  })
  export class HdfcKYC{
    constructor(private http: HttpClient,
      private commonService:CommonService) {}
    private authenticate(): Observable<string> {
      const apiURL = HDFCenvironment.kycCredential.APIURL
      const headers = new HttpHeaders({
        api_key:HDFCenvironment.kycCredential.SandboxTestingkey
        });
        return this.http.get<any>(apiURL, { headers }).pipe(
          map(response => response.data.token)
        
        );
    }

    getKycDetails(params: any): Observable<any> {
      const savePayload = '/payloads/addPayloads'
      const kycPayload ={
        "g_id": localStorage.getItem('g_id'),
        "transaction_id":  localStorage.getItem('TransactionId'),
        "user_id": localStorage.getItem('getquoteuserID'),
        'kyc_payload':params
      }
      this.commonService.post(savePayload, kycPayload).subscribe()
      return this.authenticate().pipe(
        switchMap(token => {
          const apiURL = HDFCenvironment.getKycCredential.SandboxBaseUrl;
          const headers = new HttpHeaders({
            token: `${token}`
          });
  
          return this.http.get<any>(apiURL, { headers, params }).pipe(
            map(response => response)
          );
        })
      );
    }
    
  }

  @Injectable({
    providedIn: 'root',
  })
  export class HDFCProposal {
    constructor(private http: HttpClient,
    private pincodeDetails: PincodeDetails,
    private datepipe :DatePipe,
    private commonService: CommonService) {}

    private authenticate(): Observable<string> {
      const apiURL = `${HDFCenvironment.HDFCAPIURL}authenticate`
      const headers = new HttpHeaders({
          'SOURCE': HDFCenvironment.userDetails.SOURCE,
          'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
          'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
          'CREDENTIAL': HDFCenvironment.userDetails.CREDENTIAL,
          'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID
        });
    
        return this.http.get<any>(apiURL, { headers }).pipe(
          map(response => response.Authentication.Token)
        );
    }
    createProposal (): Observable<any>  {
      const apiURL = `${HDFCenvironment.HDFCAPIURL}createproposal`
      const savePayload = '/payloads/addPayloads'
      return this.authenticate().pipe(
        switchMap((accessToken) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
            'token': accessToken
          });
          let payload = this.createProposalPayload()
          localStorage.setItem('proposal_payload',JSON.stringify(payload) )
          const proposalPayload ={
            "g_id": localStorage.getItem('g_id'),
            "transaction_id": localStorage.getItem('TransactionId'),
            "user_id": localStorage.getItem('getquoteuserID'),
            'proposal_payload':payload
          }
          this.commonService.post(savePayload, proposalPayload).subscribe()
          return this.http.post<any>(apiURL, payload, { headers })
        }),
        catchError((error) => {
          console.error('Error getting access token:', error);
          throw error; // Re-throw the error to propagate it downstream
        })
      );
    }

    downloadCIS(proposalNum): Observable <any>  {
     const apiURL = `${HDFCenvironment.HDFCAPIURL}getcisdocument`
      return this.authenticate().pipe(
        switchMap((accessToken) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
            'token': accessToken
          });
          
          const cisDocumentPayload ={
            "TransactionID": localStorage.getItem('TransactionId'),
            "Req_Policy_Document": {
                "Proposal_Number":proposalNum
            }
        }
          return this.http.post<any>(apiURL, cisDocumentPayload, { headers })
        }),
        catchError((error) => {
          console.error('Error getting access token:', error);
          throw error; // Re-throw the error to propagate it downstream
        })
      );
    }

    capitalizeFirstLetter(string) {
      if (!string) return string;
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }


    private createProposalPayload () {
      let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
      let Policy_Details = JSON.parse(localStorage.getItem('icici_Policy_Details'));
      if(localStorage.getItem('deductibleAmount')!='0'){
        Policy_Details.Deductible = localStorage.getItem('deductibleAmount')
      }
      let proposer_details = JSON.parse(localStorage.getItem('proposal_Proposer'));
      let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'))
      let insuredMember = [];
      let Proposer_Salutation 
      if (proposer_details.gender === 'MALE'){
        Proposer_Salutation ='MR'
      }else {
        Proposer_Salutation= 'MRS'
      }
      proposal_insured_member.forEach(member => {
      let IPA: any 
      let UR: any
      let CriticalIllnessSI: any
      let CriticalIllnessPlan: any
      let HDC: any 
      let HDC_Global: any;
      let OptimaWellbeing: any

      let dateOfBirth = `${member.date_of_birth.dd}/${member.date_of_birth.mm}/${member.date_of_birth.yy}`;
      let namePrifix =""
      if (member.gender=== 'MALE') {
        namePrifix = "Mr."
      } else {
        namePrifix = 'Mrs.'
      }
      let height =this.heightToCms(member.height.ft, member.height.inch)
    
      let pedList: string
      if (member.selectedDiseaseIds && member.selectedDiseaseIds.length > 0) {
        pedList = member.hdfcdiseaseObject;
      } else {
        pedList = null;
      }
      if(member.has_addOn === true){
        IPA =member.IPA
        UR = member.UR
        CriticalIllnessSI = member.Critical_Illness_Amount
        CriticalIllnessPlan = member.Critical_Illness_plan
        HDC = member.HDC
        HDC_Global = member.HDC_Global
        OptimaWellbeing = member.OptimaWellbeing
      }

      let processedMember = {
        "Insured_Salutation": namePrifix,
        "Insured_DateOfBirth": dateOfBirth,
        "Insured_Name": `${member.first_name} ${member.last_name}`,
        "Insured_Relation": this.capitalizeFirstLetter(member.relationship_with_user),
        "Insured_Gender": member.gender,
        "Insured_MaritalStatus": member.marital_status,
        "Insured_OccupationID": member.occupation,
        "Insured_HeightINcms": height,
        "Insured_WeightINkgs": member.weight,
        "Insured_Nationality": "Indian",
        "Insured_ResidenceStatus": 'Indian',
        "Insured_PortabilityApplicable": "No",
        "Insured_SumInsured":Policy_Details.SumInsured,
        "MedicalQuestionnaire": pedList,
        "memberLifeStylelist": null,
        "IsPersonWithDisability": "No",
        "DisabilityDetails": null,
        "OtherDisabilityDetails": null,
        'IsIPARider':IPA,
        "IsUnlimitedRestore":UR,
        "Insured_CriticalIllnessAddonCover_SumInsured":CriticalIllnessSI,
        "Insured_CriticalIllnessAddonCover_PlanType": CriticalIllnessPlan,
        "Insured_HospitalCashAddonCover_SumInsured": HDC,
        "IsHDCRider_Global": HDC_Global,
        "IsOptimaWellbeing": OptimaWellbeing
    };
      insuredMember.push(processedMember);
    });

      return {
        "TransactionID": localStorage.getItem('TransactionId'),
        "Policy_Details": Policy_Details,
        "Request_Data_OS": {
            "AppNumber": null,
            "CustomerID": null,
            "TransactionID": null,
            "isProspectModified": "No",
            "isCustomerModified": "No",
            "ChannelName": HDFCenvironment.userDetails.ChannelName,
            "Other_Details": {
                "LGCode": null,
                "SPCode": "",
                "NomineeRelation": proposal_nominee.relation_value,
                "Nomineename":  `${proposal_nominee.first_name} ${proposal_nominee.last_name}`,
                "NomineeAddress": JSON.stringify(proposer_details.address),
                "agentId": HDFCenvironment.userDetails.agentId,
                "GoGreenFlag": null
            },
            "proposer": {
                "Proposer_FirstName": proposer_details.first_name,
                "Proposer_MiddleName": proposer_details.middle_name,
                "Proposer_LastName": proposer_details.last_name,
                "Proposer_DateofBirth": `${proposer_details.date_of_birth.dd}/${proposer_details.date_of_birth.mm}/${proposer_details.date_of_birth.yy}`,
                "Proposer_Email": proposer_details.emailId,
                "Proposer_Mobile": proposer_details.contactNumber,
                "Proposer_AnnualIncome": proposer_details.annual_income,
                "Proposer_OrganisationType": null,
                "Proposer_PepStatus": null,
                "Proposer_Salutation": Proposer_Salutation,
                "Proposer_Gender": proposer_details.gender,
                "Proposer_Mailing_Address1": `${proposer_details.address.house_no} `,
                "Proposer_Mailing_Address2": `${proposer_details.address.area} ${proposer_details.address.landmark}`,
                "Proposer_Perm_Address1": null,
                "Proposer_Perm_Address2": null,
                "Proposer_Mailing_PinCode":proposer_details.address.pincode,
                "Proposer_Mailing_City": proposer_details.address.city,
                "Proposer_Mailing_District": proposer_details.address.city,
                "Proposer_GSTIN_Number": null,
                "Proposer_GSTIN_State": null,
                "Proposer_Professtion": null,
                "Proposer_MaritalStatus": proposer_details.marital_status,
                "Proposer_EIA_Number": null,
                "Proposer_Nationality": "Indian",
                "Proposer_Mobile_CountryCode": +91,
                "Proposer_Pehchaan_id": localStorage.getItem('hdfckyc')
            },
            "memberDetailsList": insuredMember,
            "memberPYPDetailsList": null,
            "Loading_Discount": {
                "LoyaltyDiscount": false,
                "PreviousPolicyNumber": "",
                "overseasResidenceStatus": false,
                "currentResidenceStatus": false
            },
            "IsEmiOpted": 0,
            "PaymentFrequency": null
        }
    }
    }


    private heightToCms(feet: number, inches: number): number {
      const feetToCm = feet * 30.48;  // 1 foot = 30.48 cm
      const inchesToCm = inches * 2.54;  // 1 inch = 2.54 cm
      const totalCms = feetToCm + inchesToCm;
      return Math.round(totalCms);  // You can also use Math.floor or Math.ceil
    }  
  }

  @Injectable({
    providedIn: 'root',
  })
  export class PaymentGatewayForHDFC {
    baseUrl = constant.api_endpoint
    constructor(private http: HttpClient,
      private ngxXml2jsonService: NgxXml2jsonService,
      private commonService: CommonService) {}
  
    generateRequestChecksum(amount: any): Observable<any> {
      const body = {
        TransactionNo: localStorage.getItem('TransactionId'),
        TotalAmount: amount,
        AppID: HDFCenvironment.paymentDetails.appId,
        SubscriptionID: HDFCenvironment.paymentDetails.SubscriptionID,
        SuccessUrl:  this.baseUrl+`${HDFCenvironment.paymentDetails.SuccessUrl}`,
        FailureUrl: this.baseUrl+`${HDFCenvironment.paymentDetails.FailureUrl}`,
        Source: 'POST'
      };

      
  
      const params = new URLSearchParams();
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          params.set(key, body[key]);
        }
      }
  
      return this.http.get(`${HDFCenvironment.paymentDetails.checksumURL}/GenerateRequestChecksum?${params.toString()}`, { responseType: 'text' })
      .pipe(
        map(response => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          const jsonObj = this.ngxXml2jsonService.xmlToJson(xml);
          return jsonObj;
        }),
        catchError(error => {
          console.error('Error generating checksum', error);
          return throwError(error);
        })
      );
  }


    payment (formdata: FormGroup) {
      if (formdata.valid) {
        const form = this.createForm(formdata.value);
        document.body.appendChild(form);
        form.submit();
      }

    }

    createForm(data: any): HTMLFormElement {
      const savePayload = '/payloads/addPayloads'
      const form = document.createElement('form');
      form.action = HDFCenvironment.paymentDetails.paymentURL;
      form.method = 'post';
      const paymentPayload ={
        "g_id": localStorage.getItem('g_id'),
        "transaction_id":  localStorage.getItem('TransactionId'),
        "user_id": localStorage.getItem('getquoteuserID'),
        'payment_payload':data
      }
      this.commonService.post(savePayload, paymentPayload ).subscribe()
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = data[key];
          form.appendChild(input);
        }
      }
  
      return form;
    }

    paymentDetailsSumbit (transactionPayload: any): Observable<any> {
      const submitUrl = `${HDFCenvironment.HDFCAPIURL}submitpaymentdetails`
      return this.authenticate().pipe(
        switchMap((accessToken) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
            'token': accessToken
          });
        
          return this.http.post<any>(submitUrl, transactionPayload, { headers })
        }),
        catchError((error) => {
          console.error('Error getting access token:', error);
          throw error; // Re-throw the error to propagate it downstream
        })
      );
    }

    private authenticate(): Observable<string> {
      const apiURL = `${HDFCenvironment.HDFCAPIURL}authenticate`
      const headers = new HttpHeaders({
          'SOURCE': HDFCenvironment.userDetails.SOURCE,
          'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
          'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
          'CREDENTIAL': HDFCenvironment.userDetails.CREDENTIAL,
          'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID
        });
    
        return this.http.get<any>(apiURL, { headers }).pipe(
          map(response => response.Authentication.Token)
        );
    }
   
  }

  @Injectable({
    providedIn: 'root',
  })

  export class DownloadDocument {

    constructor(private http: HttpClient) {}

    private authenticate(): Observable<string> {
      const apiURL = `${HDFCenvironment.HDFCAPIURL}authenticate`
      const headers = new HttpHeaders({
          'SOURCE': HDFCenvironment.userDetails.SOURCE,
          'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
          'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
          'CREDENTIAL': HDFCenvironment.userDetails.CREDENTIAL,
          'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID
        });
    
        return this.http.get<any>(apiURL, { headers }).pipe(
          map(response => response.Authentication.Token)
        );
    }

    downloadProposal (payload): Observable <any> {
      const dowloadproposalUrl = `${HDFCenvironment.HDFCAPIURL}getproposaldocument`
      return this.authenticate().pipe(
        switchMap((accessToken) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
            'token': accessToken
          });
        
          return this.http.post<any>(dowloadproposalUrl, payload, { headers })
        }),
        catchError((error) => {
          console.error('Error getting access token:', error);
          throw error; // Re-throw the error to propagate it downstream
        })
      );
    }

    downloadPolicy(payload): Observable <any> {
      const dowloadpolicylUrl = `${HDFCenvironment.HDFCAPIURL}getpolicydocument`
      return this.authenticate().pipe(
        switchMap((accessToken) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'SOURCE': HDFCenvironment.userDetails.SOURCE,
            'CHANNEL_ID': HDFCenvironment.userDetails.CHANNEL_ID,
            'PRODUCT_CODE': HDFCenvironment.userDetails.PRODUCT_CODE,
            'TRANSACTIONID': HDFCenvironment.userDetails.TRANSACTIONID,
            'token': accessToken
          });
        
          return this.http.post<any>(dowloadpolicylUrl, payload, { headers })
        }),
        catchError((error) => {
          console.error('Error getting access token:', error);
          throw error; // Re-throw the error to propagate it downstream
        })
      );

    }
    downloadPDF(base64Data: string, fileName: string) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
  
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(blob, fileName);
      }
    }
    
  }

 
  

import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { constant } from '../constant';
import Utils from '../utils';
import { CommonService } from '../services/common.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CkycService, ProposalCreationForHealth } from '../services/reliance.service';
import { icici_medical_questions, icici_add_ons, icici_befit_pincodes, icici_occupation, befitAddonIds } from '../iciciconfig/iciciconfig';
import { CkycServiceIcici, CalculatePremiumforHealthIcici } from '../services/icici.service';
import { PaymentGateway } from '../services/icici.service';
import { from, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { master_pincode } from '../icici_constant/PROD_PINCODE_MASTER';
import { UwPpmcMaster } from '../iciciconfig/UW_PPMC_MSTR';
import { medicalQuestionaire } from '../hdfc-config/MedicalQuestionnaire';
import { HdfcKYC, HDFCProposal,PaymentGatewayForHDFC, CalculatePremiumforHealthHDFC, DownloadDocument } from '../services/hdfcergo.service';
import { HttpParams } from '@angular/common/http';
import { CareEnviroment, HDFCenvironment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms'
import { hdfcOccupation } from '../hdfc-config/occupation';
import { DeductibleSI,  CriticalIllnessPlanType, HospitalCashAddonCover_SumInsured, CriticalIllnessMinMaxRange } from '../hdfc-config/hdfcAddon';
import { careAdvantageQuestion } from '../careconfig/careAdvantage';
import { careFreedomQuestions } from '../careconfig/careFreedom';
import { careHeartQuestion } from '../careconfig/careHeart';
import { careAdvantage_HeartRelation, careFreedomRelation } from '../careconfig/careRelation';
import { CareToken } from '../services/encryption.Service'
import { CarePropsalCreation } from '../services/careProposal.service'
import { careAdvantageAddOn, careFreedomAddOn, careHeartAddOn } from '../careconfig/CareAddon';
import { ncbMandatory } from '../careconfig/NcbSuperMandatoryPincode';
import { CalculateCarePremiumforHealth } from '../services/care.service';
import { goDigitQuestionList } from '../goDigitConfig/goDigitMedicalQuestions'
import { profession } from '../goDigitConfig/goDigitProfession';
import { ProposalForGodigit } from '../services/goDigit.service';
import { goDoigitAddons } from "../goDigitConfig/addons"
import { CareAddvantageCheck} from "../services/careAddons.service"

//import * as JsonToXML from "js2xmlparser";
//import { promise } from 'protractor';

declare var $: any
interface occupation {
    occupation_code: string;
    occupation_name: string;
}
interface Member {
    selected: boolean;
}

@Component({
    selector: 'app-proposal',
    templateUrl: './proposal.component.html',
    styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {

    goDigitAddon = goDoigitAddons
    addonButtonflag: boolean = true
    globalAddoncheck: boolean = false
    disableCarePED: boolean = false
    careHeartSubQuestions: any[]
    goDigitProposalResponse
    ncbMandatoryPincode = ncbMandatory
    user
    is_poa_poi_same: boolean=false
    kycMethod: string
    poiFile: File | null = null;
    poaFile: File | null = null;
    isSelectedMethod: boolean = false
    icici_ovd_kyc: boolean
    IDProofNumber:string
    isDownloaded: boolean = false;
    hdfcProposalMessage:string
    hdfcProposalNum:any 
    hdfcpremium_details
    HDCSelected:any
    CareTokenInitail = 0
    baseSiOptions: number[] = [];
    memberPaylod: any = [];
    is_Icici:boolean
    isCare =false
    hdfcDeductibleSI :any
    hdfcdiseaseID: string
    careQuestionCode: string;
    CriticalIllnessPlanType = CriticalIllnessPlanType
    HospitalCashAddonCover_SumInsured= HospitalCashAddonCover_SumInsured
    hdfcKycID: string
    hdfcKycData: any
    isAppointeeDateInvalid:boolean = false;
    messageBMI: string;
    alertSeen= false;
    messageUw: String;
    review_length = 100;
    selectedAddon: any;
    careProposalResponse
    loading = false;
    @ViewChild('abhipgform', { static: true }) abhipgform: ElementRef;
    @ViewChild('uWAlertTemplate',{static:true}) showUwAlert:  TemplateRef<any>;
    @ViewChild('carepaymentForm', { static: false }) carepaymentForm: ElementRef<HTMLFormElement>;
    @ViewChild('goDigitProposal', { static: false }) goDigitProposalAlert: TemplateRef<any>;
    @ViewChild('careProposal', { static: false }) careProposalAlert: TemplateRef<any>;

    careproposalNum = '';
    carselectedAddons = [];
    careReturnUrl = '';
    paymentForm: FormGroup;
    savePayload = '/payloads/addPayloads'
    IDProofArr = [
        {
            id: 'IDNO3',
            value: 'Driving License'
        }, {
            id: 'IDNO2',
            value: 'PAN'
        }, {
            id: 'IDNO1',
            value: 'Passport'
        }, {
            id: 'IDNO4',

            value: 'Voter ID'
        }
    ];
    questionsWithoutSubQuestion = ['HEDCH015','HEDCH012', 'HEDCH010', 'HEDCH011', 'HEDCH007', 'HEDCH013', 'HEDCH006', 'HEDCAR27', 'HEDCH009', 'HEDCH008', 'HEDHealthClaim', 'HEDHealthDeclined', 'HEDHealthCovered', 'yesNoExist', 'HEDHealthHospitalized'];
    filteredQuestionsForHEDCH009 = careHeartQuestion.filter(q => q.type == 'subquestion' && q.question_set_code == 'HEDCH009');
    filteredQuestionsForHEDCH008 = careHeartQuestion.filter(q => q.type == 'subquestion' && q.question_set_code == 'HEDCH008');

    occupations: any = icici_occupation
    hdfcoccupations:any = hdfcOccupation
    goDigitOccupation: any = profession
    isGoDigit: boolean = false
    occupation_for_self: any;

    proposal: any = {
        first_name: '',
        middle_name: '',
        last_name: '',
        emailId: '',
        date_of_birth: {
            dd: '',
            mm: '',
            yy: ''
        },
        feb: true,
        height: {
            ft: '',
            inch: "0"
        },
        address: {
            house_no: "",
            area: "",
            landmark: "",
            nationality: "Indian",
            pincode: "",
            city_id: "",
            state: '',
            state_id: ""
        },

        weight: '',
        gender: "",
        occupation: '',
        occupationName: '',
        marital_status: "",
        annual_income: '',
        insured_member: [],
        nominee: {
            first_name: '',
            last_name: '',
            email: '',
            mobileno: '',
            date_of_birth: '',
            age: '',
            relationship_with_proposer: '',
            relation_value: '',
            addressline1: '',
            addressline2: '',
            state: '',
            city: '',
            pincode: '',
            gender:''
        },
        appointee: {
            appointeeName: '',
            appointeeAge: '',
            appointeeRelationship: '',
            date_of_birth:''
        },
        relations: [],
        IDProofNumber:'',
    };

    premium_collection: any = {
        coverage: 0,
        premium_year: 0,
        premium_amout: 0,
        gst: 0,
        final_premium: 0,
        TotalDiscountAmount: 0,
    };
    otp = '';
    //modalRef: BsModalRef;
    @ViewChild('otppopup', { static: true }) otppopup: BsModalRef
    noofyear = [];
    ABHI_PG_LANDING: string;
    CARE_PG_LANGING: string;
    userid: string;
    session_id: string;
    step2nextbtn = false;
    eldestmemberAge: any;
    eldestmemberGender: any;
    stateName: string;
    actulaRel = [];
    questions = [];
    proposer_pincode: string;
    section: string;
    savenshareObj: any = {};
    modalRef: BsModalRef;
    modalRef2: BsModalRef;
    ped_section = false;
    imdisabled = false;
    totalmember = [];
    seletedRelArr = [];
    //lifestyleFlag = false;
    dd = [];
    YY = [];
    mm = [];
    tab: string;
    relation: string
    snsBtn = 'Save & Share';
    snsdisabled = false;
    occupation = [];
    stateList = [];
    cityList = [];
    cityList2 = [];
    weight = [];
    age = [];
    areaList = [];
    appointeeAge = []
    relationShip = [];
    lifestyleList = [];
    medical_histories = [];
    medical = [];
    step1 = true;
    step2 = false;
    step3 = false;
    step4 = false; //** */
    step5 = false;
    step6 = false;
    step7 = false;
    step14 = false;
    noOfChild = 0;
    proposer_step = false;
    insured_member_step = false;
    kyc_step = false;
    nominee_step = false;
    medical_history_step = false;
    addon_step = false;
    cover_type_slug: string;
    cover_type: string;
    previewFlag = false;
    appointee = false;
    medi1 = false;
    medi2 = false;
    medi3 = false;
    medi4 = false;
    medi5 = false;
    medi6 = false;
    showchildcover = false;
    child_addon: any = {
        memberSelected: []
    };
    coverageDisplay: string;
    checkedStatus = {};
    finalPremiumDisplay: string;
    cityDisabled = true;
    nomineeRelations = [];
    btnText = 'Next';
    btndisabled = true;
    hdfcPaymentButton =false
    plan_id: string;
    company_id: string;
    step: string;
    premium_id = '';
    fullURL: string;
    plandetails: any = {
        product_master: {},
        company: {},
        premium: {},
        coverage: {}
    };

    kyc_details: any = {
        pan_card: '',
        date_of_birth: '',
        ispanCardVerfied: false,
        fullname: '',
        date_of_incorporation: '',
        aadhaar_name: '',
        aadhaar_dob: '',
        aadhaar_gender: '',
        IDProofType: '',
        IDProofNumber: '',
    };

    isPanMandatory: boolean = false
    showMaternityAddon: boolean = false;
    showPersonalAccident: boolean = false;
    isBefitSelected: boolean = false;
    insuredMemberDetails: any[] = [];
    selectedMembers: { [addonId: number]: any[] } = {};
    selectedAddonMembers: { [key: number]: any[] } = {};
    IciciHealthAdvantagePlusPremium: any = 0;
    IciciHealthAdvantagePlustitle: string = '';
    selectedAddons: { [key: number]: boolean } = {};
    updatedAddons: { [key: string]: { title: string, members: any[] } } = {};


    medical_details_status: any = {
        status: false,
        value: '',
    }


    base_url: string;
    freedomHeartMandatoryAddon:boolean =false
    advantageMandatoryAddons:boolean = false
    ncbSuper: boolean = false
    gst: number;
    util = new Utils;
    avlbPincodes = [];
    genericrelationShip = [];
    carebody: any = {};
    responsejson = {};
    construct: any;
    agee: any;
    city: any;
    gender: any;
    coverage: string;
    currentYr: number;
    abhi: any = {};
    carepg: any = {};
    // step: string;
    linkclicked = false;
    backbtnhidefromapp = '0';
    countmedicalQus = 0;
    medical_questions: any 
    icici_add_ons: any = icici_add_ons
    careAddon :any 
    icici_befit_pincodes: any = icici_befit_pincodes
    befitAddonIds: any = befitAddonIds
    addon_eligible_members: { [key: string]: any[] } = {};

    company_special_id: string;
    data: any[] = [];
    qus: any = { existing_since: { mm: '', yy: '' } };
    panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    platform = '';
    selectedCard: string;
    yy: string;
    month: string;
    day: string;
    isSelf: boolean = false
    transactionData: any;
    anyCheckboxSelected: boolean = false;
   premium_collectionData: any
   CriticalIllnessAddonCover:any
   area:string
   selectedDeductible: any
   carePaymentUrl = CareEnviroment.carePaymentUrl


    constructor(
        private commonService: CommonService,
        private _location: Location,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        // private ngxXml2jsonService: NgxXml2jsonService,
        private modalService: BsModalService,
        private ngxLoader: NgxUiLoaderService,
        // reliance
        private ckycService: CkycService,
        private ProposalService: ProposalCreationForHealth,
        // icici
        private icici_ckycService: CkycServiceIcici,
        private CalculatePremiumforHealthIcici: CalculatePremiumforHealthIcici,
        private PaymentGateway: PaymentGateway,
        private HDFCKYC: HdfcKYC,
        private  HdfcProposal:HDFCProposal,
        private paymentGateway: PaymentGatewayForHDFC,
        private formbuilder: FormBuilder,
        private  hdfcPremium: CalculatePremiumforHealthHDFC,
        private datepipe :DatePipe,
        private hdfcDocumentDownload : DownloadDocument,
        private careToken :CareToken,
        private careProposal:CarePropsalCreation,
        private carePremiumCalculation :CalculateCarePremiumforHealth,
        private ProposalCreationForGodigit: ProposalForGodigit,
        private careAddonsCheck: CareAddvantageCheck
    ) { }

    backClicked() {
        //this._location.back();
        if (this.modalRef2) {
            this.modalRef2.hide();
          }
        this.router.navigate([`health-quote`]);
    }

    onPincodeSelect(event: { target: { value: any; }; }) {
        //console.log('Pincode : ', event)
        this.proposal.address.pincode = event.target.value
    }

    async ngOnInit() {
        let platform = localStorage.getItem('platform')
        switch (platform) {
            case 'Optima Secure':
            case 'Optima Super Secure':
            case 'OptimaSecureGlobal':
            case 'OptimaSecureGlobalPlus':
                this.medical_questions = medicalQuestionaire;
                this.premium_collectionData =JSON.parse(localStorage.getItem(`HDFC${platform}`));
                this.gethdfcPremium_collection();
                this.is_Icici = false
                this.isCare = false
                break;
            case 'iciciPremium':
                this.medical_questions = icici_medical_questions ;
                this.is_Icici = true
                break;
            case 'World Wide Treatment Plan':
            case 'Double Wallet Plan':
            case 'Infinity Wallet Plan':
                this.premium_collectionData =JSON.parse(localStorage.getItem(localStorage.getItem('platform')));
                this.getGoDigitPremium_collection()
                this.medical_questions = goDigitQuestionList ;
                this.isGoDigit = true
                break;
            case 'careAdvantagePremium':
                this.is_Icici = false
                this.medical_questions = careAdvantageQuestion
                this.isCare = true
                this.careAddon = careAdvantageAddOn
                this.premium_collectionData =JSON.parse(localStorage.getItem('CarAdvantage'));
                this.getCarePremium_collection(this.premium_collectionData)
                break;
            case 'careFreedomPremium':
                this.medical_questions = careFreedomQuestions
                this.is_Icici = false
                this.careAddon = careFreedomAddOn
                this.premium_collectionData =JSON.parse(localStorage.getItem('CareFreedom'));
                this.isCare = true
                this.getCarePremium_collection(this.premium_collectionData)
                break;
            case 'careHeartPremium':
                this.medical_questions = careHeartQuestion
                this.careAddon = careHeartAddOn
                this.premium_collectionData =JSON.parse(localStorage.getItem('CarHeart'));
                this.getCarePremium_collection(this.premium_collectionData)
                this.is_Icici = false
                this.isCare = true
                break;
            
            default:
                // Handle default case if needed
                break;
        }
        ////console.log(moment())
        this.route.queryParams.subscribe(params => {
            this.transactionData = params;
        });

        if(this.transactionData.status ==="approved"){
            this.kycMessage(this.transactionData)
        }

        $("html, body").animate({ scrollTop: 0 }, 600);
        this.platform = localStorage.getItem("platform")
        this.userid = localStorage.getItem("getquoteuserID") || '';
        this.session_id = localStorage.getItem("session_id") || '';
        this.backbtnhidefromapp = localStorage.getItem('backbtnhidefromapp') || '0';
        this.fullURL = constant.hosting_endpoint + this.router.url + '&sess=' + this.session_id;
        //this.fullURL = this.router.url;
        var d = new Date();
        this.ABHI_PG_LANDING = constant.abhi_landing_page_url;
        this.CARE_PG_LANGING = constant.religare_payment_url;
        this.currentYr = d.getFullYear();
        //const random = this.util.randomString(15);
        this.base_url = constant.api_endpoint;
        this.gst = constant.gstval;
        this.tab = localStorage.getItem('tab');
        var user: {
            construct: any;
            pincode: any; userName: string; dob: any; emailId: any; contactNumber: any; from_pincode: string; to_pincode: string; state_id: any; gender: string; city_id: any; age: any;
        };
        if (this.tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
            [this.yy, this.month, this.day] = user.dob.split("-");
            this.relation = 'self';

            if (user.dob) {
                this.isSelf = true
                this.dd.push(this.day);
                this.mm.push(this.month);
                this.YY.push(this.yy)
                this.proposal.date_of_birth.dd = this.day
                this.proposal.date_of_birth.mm = this.month
                this.proposal.date_of_birth.yy = this.yy;


            }
        } else if (this.tab === 'F') {
            user = JSON.parse(localStorage.getItem('userData2'));

        } else {
            user = JSON.parse(localStorage.getItem('userData2'));

        }
        // this.lifestyleList = await this.getLifeStyle();
        // //console.log(this.lifestyleList);


        for (let i = 1; i <= 31; i++) {
            if (i.toString().length === 1) {
                this.noofyear.push(i + 1)
                this.dd.push('0' + i);
            } else {
                this.dd.push(i);
            }

        }
        for (let x = 1; x <= 100; x++) {
            this.weight.push(x);
            this.age.push(x);
            this.YY.push((this.currentYr - 90) + x);
            if (x > 18) {
                this.appointeeAge.push(x);
            }
        }
        //console.log('========New Session============');
        this.populateInsuredMemberForm(user);
        let userArr = user.userName.split(" ");
        //console.log(userArr);

        this.proposal.first_name = userArr[0];
        this.savenshareObj.emailId = user.emailId;
        this.savenshareObj.contactNumber = user.contactNumber;

        //set pincodes
        for (let i = parseInt(user.from_pincode); i <= parseInt(user.to_pincode); i++) {
            this.avlbPincodes.push(i.toString())
        }
        ////console.log("avlbPincodes : ", this.avlbPincodes)

        // this.proposal.insured_member[0]['first_name'] = userArr[0];

        if (userArr.length == 3) {
            this.proposal.middle_name = userArr[1];
            this.proposal.last_name = userArr[2];
        } else if (userArr.length == 2) {
            this.proposal.last_name = userArr[1];
        }

        //console.log(this.proposal.insured_member);


        this.proposal.emailId = user.emailId;
        this.proposal.contactNumber = user.contactNumber;
        this.proposal.address.state_id = '21';
        this.proposal.address.pincode = user.pincode;
        this.construct = user.construct
        //console.log(this.construct);

        // this.welcomeFormFillingSMS();
        this.getstateList();
        this.proposal.gender = (user.gender == '0') ? 'MALE' : 'FEMALE';
        if (this.proposal.address.pincode) {
            this.commonService.getPincode(this.proposal.address.pincode).subscribe(res => {

                let pincodeDetails = res[0].PostOffice.map((postOffice: { Division: any; Circle: any; Name: any; }) => ({
                    Region: postOffice.Division,
                    Circle: postOffice.Circle,
                    Name: postOffice.Name
                }));

                const transformed_data = [];



                pincodeDetails.forEach(item => {
                    const circleName = item.Circle;
                    const Region = item.Region;
                    const existingCircle = transformed_data.find(obj => obj.Circle === circleName);

                    if (!existingCircle) {
                        transformed_data.push({
                            "Circle": circleName,
                            "Region": Region,
                            "Area": [item.Name]
                        });
                    } else {
                        existingCircle.Area.push(item.Name);
                    }
                });

                this.cityList = [transformed_data[0].Region];
                this.stateList = [transformed_data[0].Circle];
                this.areaList = transformed_data[0].Area;
                this.proposal.address.state = transformed_data[0].Circle;
                this.proposal.address.city = transformed_data[0].Region;
            }, err => {
                const errorMessage = err && err.message || 'Something goes wrong';
                this.toastr.error(errorMessage, 'Error');
            })

        }

        this.getNomineeRelations();
        // this.goto(2);

        // const review = localStorage.getItem('review');
        // //console.log('review');

        // if (review.length != 0) {
        //     // this.proposal=review[0];
        // }

        // premium_collection
        //console.log(this.platform);

        switch (this.platform) {
            case 'reliancePremium':
                this.getreliancePremium_collection();
                //console.log(this.platform);
                break;
            case 'iciciPremium':
                this.geticiciPremium_collection();
                //console.log(this.platform);
                break;
            // case 'careSupremePremium':
            //     this.getCarePremium_collection();
                //console.log(this.platform);
                break;
            default:
                break;
        }

        if (this.transactionData === 'failed') {

        }

    }

    getGoDigitPremium_collection () {
         
        if (this.premium_collectionData) {
            this.premium_collection.coverage = this.premium_collectionData.coverage;
            this.premium_collection.premium_year = this.premium_collectionData.premium_year;
            this.premium_collection.premium_amout = this.premium_collectionData.premium_amout;
            this.premium_collection.gst = this.premium_collectionData.gst;
            this.premium_collection.final_premium = this.premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = this.premium_collectionData.TotalDiscountAmount;
        }
        let currentStep = JSON.parse(localStorage.getItem('step'));
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('kyc');
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            //console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            //console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                //console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present) {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        if (proposal_insured_member) {
            let setdate_of_birth = proposal_insured_member[0].date_of_birth;
            this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
            //console.log(this.kyc_details.date_of_birth);
            this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

        }

    }
    gethdfcPremium_collection() {
        this.selectedDeductible = localStorage.getItem('deductibleAmount')
      
        let hdfcSI = parseInt(this.premium_collectionData.coverage,10)
        this.hdfcDeductibleSI = DeductibleSI.filter(deductible =>  deductible < hdfcSI)
        for (let i = CriticalIllnessMinMaxRange.minValue; i <= CriticalIllnessMinMaxRange.maxValue; i += CriticalIllnessMinMaxRange.intvl) {
            this.baseSiOptions.push(i);
          }
        this.CriticalIllnessAddonCover = this.baseSiOptions.filter(amount=> amount < hdfcSI )
        this.area = localStorage.getItem('district')

        if (this.premium_collectionData) {
            this.premium_collection.coverage = this.premium_collectionData.coverage;
            this.premium_collection.premium_year = this.premium_collectionData.premium_year;
            this.premium_collection.premium_amout = this.premium_collectionData.premium_amout;
            this.premium_collection.gst = this.premium_collectionData.gst;
            this.premium_collection.final_premium = this.premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = this.premium_collectionData.TotalDiscountAmount;
        }

        let currentStep = JSON.parse(localStorage.getItem('step'));
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('kyc');
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            //console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            //console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present =  localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                //console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        if (proposal_insured_member) {
            let setdate_of_birth = proposal_insured_member[0].date_of_birth;
            this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
            //console.log(this.kyc_details.date_of_birth);
            this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

        }

    }
 
    getreliancePremium_collection() {
        let premium_collectionData = JSON.parse(localStorage.getItem('reliancePremium_collection'));

        if (premium_collectionData) {
            this.premium_collection.coverage = premium_collectionData.coverage;
            this.premium_collection.premium_year = premium_collectionData.premium_year;
            this.premium_collection.premium_amout = premium_collectionData.premium_amout;
            this.premium_collection.gst = premium_collectionData.gst;
            this.premium_collection.final_premium = premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = premium_collectionData.TotalDiscountAmount;
        }

        let currentStep = JSON.parse(localStorage.getItem('step'));
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('kyc');
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            //console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            //console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                //console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        if (proposal_insured_member) {
            let setdate_of_birth = proposal_insured_member[0].date_of_birth;
            this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
            //console.log(this.kyc_details.date_of_birth);
            this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

        }

    }

    geticiciPremium_collection() {
        let premium_collectionData = JSON.parse(localStorage.getItem('iciciPremium_collection'));

        if (premium_collectionData) {
            this.premium_collection.coverage = premium_collectionData.coverage;
            this.premium_collection.premium_year = premium_collectionData.premium_year;
            this.premium_collection.premium_amout = premium_collectionData.premium_amout;
            this.premium_collection.gst = premium_collectionData.gst;
            this.premium_collection.final_premium = premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = premium_collectionData.TotalDiscountAmount;
        }

        let currentStep = JSON.parse(localStorage.getItem('step'));
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            this.isPanMandatory = this.premium_collection.final_premium >= 100000;
            if (this.isPanMandatory) {
                this.kyc_details.IDProofType = 'PAN';
            }
            //console.log('kyc');
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            //console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            //console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        if (proposal_insured_member) {
            let setdate_of_birth = proposal_insured_member[0].date_of_birth;
            this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
            //console.log(this.kyc_details.date_of_birth);
            this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

        }

    }

    getCarePremium_collection(premium:any) {
        let premium_collectionData = premium
         const finalPremium = parseFloat(premium_collectionData.final_premium.replace(/,/g, ''));
         const premiumAmount = parseFloat(premium_collectionData.premium_amout);
         const difference = (finalPremium - premiumAmount).toFixed(2);
        if (premium_collectionData) {
            this.premium_collection.coverage = premium_collectionData.coverage;
            this.premium_collection.premium_year = premium_collectionData.premium_year;
            this.premium_collection.premium_amout = premium_collectionData.premium_amout;
            this.premium_collection.gst = difference.toString()
            this.premium_collection.final_premium = premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = premium_collectionData.TotalDiscountAmount;
        }
        let currentStep = JSON.parse(localStorage.getItem('step'));
        // this.goto(currentStep)
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            //console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            this.isPanMandatory =premium_collectionData.final_premium >= 50000;
            if (this.isPanMandatory) {
                this.kyc_details.IDProofType = 'PAN';
            }
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            //console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            //console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                //console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        if (proposal_insured_member) {
            let setdate_of_birth = proposal_insured_member[0].date_of_birth;
            this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
            //console.log(this.kyc_details.date_of_birth);
            this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

        }

    }

    async populateInsuredMemberForm(user: any) {
        let proposalform = this.commonService.relations().filter(el => el.value == user.construct);

        let insuredMember = JSON.parse(localStorage.getItem("userData2Insured"));
        this.noOfChild = proposalform[0]['nochild'];
        //console.log(this.noOfChild);

        // this.medical_questions = await this.getMedicalQuestionarries();
        // //console.log(this.medical_questions);

        if (this.proposal.marital_status === 'Single') {

            //console.log(proposalform[0]['form']['relations']);

        }

        //let totalmember = proposalform[0]['totalmember'];


        for (var member of proposalform[0]['form']) {


            let matchMember = insuredMember[0].form.find(memberforMatch => memberforMatch.memberTypeDef == member.memberTypeDef);
            if (matchMember && this.tab !== "I") {
                [this.yy, this.month, this.day] = matchMember.DateOfBirth.split("-");
                this.relation = matchMember.relation;
            }


            let yyyy = []
            let ped_yy = []
            const currentYear = new Date().getFullYear();
            const yearDifference = currentYear - Number(this.yy);
            if (member.membertype == '1c') {
                var ped_start_year =  Number(this.yy);
                var startingYr = (this.currentYr - 25);
                for (let x = 1; x <= 25; x++) {
                    yyyy.push(startingYr + x)
                }
                
                for (let x = 0; x <= yearDifference; x++) {
                    ped_yy.push(ped_start_year + x)
                }

            } else {
                var Yr = (this.currentYr - 90);
                for (let x = 1; x <= 90; x++) {
                    yyyy.push(Yr + x)
                }
                var ped_start_year =  Number(this.yy);
                for (let x = 0; x <= yearDifference; x++) {
                    ped_yy.push(ped_start_year + x)
                }
            }
            
            ////console.log(member.membertype, ":", yyyy)
            let userArr = user.userName.split(" ");
            let middle_name = '';
            let last_name = '';
            let first_name = '';
            if (userArr.length == 3 && this.isSelf) {
                first_name = userArr[0]
                middle_name = userArr[1];
                last_name = userArr[2];
            } else if (userArr.length == 2 && this.isSelf) {
                first_name = userArr[0]
                last_name = userArr[1];
            }

            this.proposal.insured_member.push(
                {
                    relationship_with_user: this.relation,
                    memberDef: member.memberTypeDef,
                    membertype: member.membertype,
                    relArr: this.relation,
                    dateOfBirthYear: yyyy,
                    symptomStartYr: ped_yy,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    weight: '',
                    occupation: '',
                    marital_status: (member.membertype == '1c') ? 'Single' : '',
                    maritaldropdown: (member.membertype == '1c') ? true : false,
                    gender: '',
                    height: {
                        ft: '', inch: '0'
                    },
                    date_of_birth: {
                        dd: this.day,
                        mm: this.month,
                        yy: this.yy
                    },
                    emailId: '',
                    contactNumber: '',
                    IDProofType: '',
                    IDProofNumber: '',
                    IDProofIssueDate:'',
                    isdisabled: false,
                    has_ped: false,
                    medical_questions: this.medical_questions.map((el) => {
                        return {
                            question_title: el.question_title,
                            question_desc: el.question_desc,
                            DiseaseID: el.DiseaseID,
                            question_set_code: el.question_set_code,
                            question_code: el.question_code,
                            question_description: el.question_description,
                            type: el.type,
                            existing_since: {
                                dd:'',mm: '', yy: ''
                            },
                            subQuestions: el.subQuestions,
                            showSubQuestions: el.showSubQuestion,
                            answerType: el.Answer_Type
                        }
                    }),
                    mh: false,
                    marital_status_flag: true,
                    genderfreez: false,
                    nominee: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        mobileno: '',
                        date_of_birth: {
                            dd: '',
                            mm: '',
                            yy: ''
                        },
                        age: '',
                        relationship_with_proposer: '',
                        relation_value: '',
                        addressline1: '',
                        addressline2: '',
                        state: '',
                        city: '',
                        pincode: ''
                    },
                    appointee: {
                        appointeeName: '',
                        appointeeAge: '',
                        appointeeRelationship: '',
                    },
                }
            )
        }
    }

    getNomineeRelations() {
        if (this.company_special_id == '3') {
            this.nomineeRelations = this.commonService.abhiNomineeRel();
        } else {
            this.nomineeRelations = this.commonService.nomineeRelations();
        }
        //console.log("Nominee RelationShip: ", this.nomineeRelations)
    }


    changeMaritalStatus() {
        //console.log(this.proposal.marital_status);

        if (this.proposal.marital_status === 'Single') {
            this.nomineeRelations = this.nomineeRelations.filter(nom => nom.value !== 'Spouse');
        } else {
            this.getNomineeRelations();
        }
    }
    changeRelation(e: { target: { value: any; }; }, index: number) {
        let val = e.target.value;
        //console.log(val);

        if (index === 0) {
            this.seletedRelArr = [];
            //console.log(this.seletedRelArr)
        }
        if (val == '345') {
            //console.log(this.proposal)
            this.proposal.insured_member[index].isdisabled = true;
            this.proposal.insured_member[index]['first_name'] = this.proposal.first_name;
            this.proposal.insured_member[index]['middle_name'] = this.proposal.middle_name;
            this.proposal.insured_member[index]['last_name'] = this.proposal.last_name;
            this.proposal.insured_member[index]['occupation'] = this.proposal.occupation;
            this.proposal.insured_member[index]['marital_status'] = this.proposal.marital_status;
            this.proposal.insured_member[index]['gender'] = this.proposal.gender;
            this.proposal.insured_member[index]['date_of_birth']['dd'] = this.proposal.date_of_birth.dd;
            this.proposal.insured_member[index]['date_of_birth']['mm'] = this.proposal.date_of_birth.mm;
            this.proposal.insured_member[index]['date_of_birth']['yy'] = this.proposal.date_of_birth.yy;
            this.proposal.insured_member[index]['height']['ft'] = this.proposal.height.ft;
            this.proposal.insured_member[index]['height']['inch'] = this.proposal.height.inch;
            this.proposal.insured_member[index]['weight'] = this.proposal.weight;
            this.proposal.insured_member[index]['emailId'] = this.proposal.emailId;
            this.proposal.insured_member[index]['contactNumber'] = this.proposal.contactNumber;
            this.proposal.insured_member[index]['IDProofType'] = this.proposal.IDProofType;
            this.proposal.insured_member[index]['IDProofNumber'] = this.proposal.IDProofNumber;
        } else {
            this.proposal.insured_member[index].isdisabled = false;
            this.proposal.insured_member[index]['gender'] = '';
            this.proposal.insured_member[index]['marital_status'] = '';
            this.proposal.insured_member.isdisabled = false;
            this.proposal.insured_member[index]['first_name'] = '';
            this.proposal.insured_member[index]['middle_name'] = '';
            this.proposal.insured_member[index]['last_name'] = '';
            this.proposal.insured_member[index]['occupation'] = '';
            this.proposal.insured_member[index]['marital_status'] = '';
            this.proposal.insured_member[index]['gender'] = '';
            this.proposal.insured_member[index]['date_of_birth']['dd'] = '';
            this.proposal.insured_member[index]['date_of_birth']['mm'] = '';
            this.proposal.insured_member[index]['date_of_birth']['yy'] = '';
            this.proposal.insured_member[index]['height']['ft'] = '';
            this.proposal.insured_member[index]['height']['inch'] = '';
            this.proposal.insured_member[index]['weight'] = '';
            this.proposal.insured_member[index]['emailId'] = '';
            this.proposal.insured_member[index]['contactNumber'] = '';
            this.proposal.insured_member[index]['IDProofType'] = '';
            this.proposal.insured_member[index]['IDProofNumber'] = '';
        }
        // let relMatch = this.relationShip.filter(e => e.relation_slug == val);
        // if (relMatch.length <= 0) {
        //     this.util.errorDialog("This relation is not available");
        //     this.proposal.insured_member[index]['relationship_with_user'] = '';
        // }
        // //console.log(relMatch)
        // this.proposal.insured_member[index]['relationship_with_proposer'] = (relMatch.length > 0) ? relMatch[0]['relation_code'] : null;
        // this.seletedRelArr[index] = val;
        // //console.log(this.seletedRelArr)

        if (this.construct == '2a') {
            if (this.proposal.insured_member[0]['relationship_with_user'] == 'self') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'spouse', value: 'Spouse' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'spouse') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'self', value: 'Self' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'father') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'mother', value: 'Mother' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'mother') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'father', value: 'Father' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'father-in-law') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'mother-in-law', value: 'Mother-in-Law' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'mother-in-law') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'father-in-law', value: 'Father-in-Law' }];
            }
        } else {
            // =======Remove Selected Insured members relation (ADULT)===============

            this.proposal.insured_member.forEach((mem: { IDProofType: string; membertype: string; }, i: number) => {
                let IDProofNam = this.IDProofArr.find(el => el.id == mem.IDProofType);
                if (IDProofNam) {
                    this.proposal.insured_member[i]['IDProofName'] = this.IDProofArr.find(el => el.id == mem.IDProofType)['value']
                }
                if (i > index && mem.membertype == '1a' && (this.noOfChild > 0)) {
                    let rels = [{ key: 'self', value: 'Self' }, { key: 'spouse', value: 'Spouse' }]
                    this.proposal.insured_member[i]['relArr'] = rels.filter((el) => {
                        if (this.proposal.marital_status == 'Single') {
                            return el.key != 'spouse' && !this.seletedRelArr.includes(el.key)
                        } else {
                            return !this.seletedRelArr.includes(el.key)
                        }
                    })
                } else if (i > index && mem.membertype == '1c' && (this.noOfChild > 0)) {
                    // =======SPECIAL CASE FRO CHILD===============
                    this.proposal.insured_member[i]['relArr'] = [{ key: 'son', value: 'Son' }, { key: 'daughter', value: 'Daughter' }]
                }
            })
        }


        if (val == '320') {
            if (this.proposal.gender == 'MALE') {
                this.proposal.insured_member[index]['gender'] = 'FEMALE';
                this.proposal.insured_member[index]['marital_status'] = 'Married';
                this.proposal.insured_member[index]['maritaldropdown'] = true;
                this.proposal.insured_member[index]['genderfreez'] = true;
            } else {
                this.proposal.insured_member[index]['gender'] = 'MALE';
                this.proposal.insured_member[index]['genderfreez'] = true;
                this.proposal.insured_member[index]['marital_status'] = 'Married';
                this.proposal.insured_member[index]['maritaldropdown'] = true;
            }
        } else {
            this.proposal.insured_member[index]['genderfreez'] = false;
            this.proposal.insured_member[index]['maritaldropdown'] = false;
        }

        if (val == '321') {
            this.proposal.insured_member[index]['marital_status'] = 'Single';
            this.proposal.insured_member[index]['gender'] = 'MALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['maritaldropdown'] = true;
        } else if (val == '322') {
            this.proposal.insured_member[index]['marital_status'] = 'Single';
            this.proposal.insured_member[index]['gender'] = 'FEMALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['maritaldropdown'] = true;
        }

        if (val == '319' || val == '2058' || val == '2063') {
            this.proposal.insured_member[index]['gender'] = 'MALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['marital_status'] = 'Married';
        } else if (val == '1255' || val == '2057' || val == '2063') {
            this.proposal.insured_member[index]['gender'] = 'FEMALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['marital_status'] = 'Married';
        }
    }

    // Assuming data is a property of your component
    requiresDOB(): boolean {
        const typesRequiringDOB = ['DL', 'PAN', 'PASSPORT', 'VOTERID', 'CKYC'];
        return typesRequiringDOB.includes(this.kyc_details.IDProofType);
    }
    onIdProofChange(event: Event) {
        const selectedValue = (event.target as HTMLSelectElement).value;
        console.log("selected value is ", selectedValue)
        if(selectedValue == 'OVD'){
            this.icici_ovd_kyc = true
        }else {
            this.icici_ovd_kyc = false
        }
        console.log("checking here", this.icici_ovd_kyc)
    }

    handleCheckboxChange(event: Event) {
        const checkbox = (event.target as HTMLInputElement); // Cast to HTMLInputElement
        if(checkbox.checked) {
            this.is_poa_poi_same =true
       }else {
        this.is_poa_poi_same = false
       }
    }
    
    handleKycMethod(event: Event) {
        this.kycMethod = (event.target as HTMLInputElement).value;
        this.isSelectedMethod = true
    }

    onFileSelected(event: Event, type: string) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
          if (type === 'poi') {
            this.poiFile = input.files[0];
          } else if (type === 'poa') {
            this.poaFile = input.files[0];
          }
        }
      }
    
    

    requiresDOI(): boolean {
        return this.kyc_details.IDProofType === 'CIN';
    }

    requiresAadhaarFields(): boolean {
        return this.kyc_details.IDProofType === 'AADHAAR'
    }


    getmedical_questions(event: any, diseaseId: string) {
        const selectedValue = event.target.value;

        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.DiseaseID === diseaseId);

        if (selectedValue === 'YES') {
            // Check if there is an entry with the given DiseaseID
            const existingEntries = this.data.filter(question => question.DiseaseID === diseaseId);
            localStorage.setItem('medical_details_present', 'yes');

            if (existingEntries.length === 0) {
                // If there is no entry, add a new entry
                this.data.push(existingEntry);
            } else {
                // If there are existing entries, you can decide how to handle it
                // For example, you might want to create a new entry or update the existing ones.
                // Here, we are creating a new entry with a different title.
                this.data.push({
                    question_title: `Another Question for DiseaseID ${diseaseId}`,
                    DiseaseID: diseaseId,
                    existing_since: {
                        mm: '', yy: ''
                    },
                });
            }
        } else if (selectedValue === 'NO') {
            // Remove all entries with the given DiseaseID
            this.data = this.data.filter(question => question.DiseaseID !== diseaseId);
            localStorage.setItem('medical_details_present', 'no');
        }

        //console.log(this.data);
    }

    getmedical_questions_for_care(memberIndex, event: any, question_code: string, question_set_code: string, questionIndex: number) {
        const selectedValue = event.target.value;

        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.question_code === question_code);
        const questionSet = this.medical_questions.filter(question =>question.question_set_code === question_set_code)
      
        const subQuestion = questionSet.find((q) => q.type === 'subquestion');
        const subQuestion_set = ['H023','H024','H025','H026','H027','H028','A014','A016','A017','A018','A019']
        if(subQuestion && ((selectedValue).toUpperCase() === 'YES') ){
           
            this.disableCarePED = true
        }
        if( subQuestion_set.includes(question_code)  && ((selectedValue).toUpperCase() === 'YES' ||  (selectedValue).toUpperCase() === "NO")){

            this.disableCarePED = false
        }
        
        const member = this.proposal.insured_member[memberIndex];
        if ((selectedValue).toUpperCase() === 'YES') { 
            // Check if there is an entry with the given question_code
            const existingEntries = this.data.filter(question => question.question_code === question_code);
            localStorage.setItem('medical_details_present', 'yes');
            // Find and update the Response for the matching entries

            if (existingEntry) {
                existingEntry.Response = 'YES';
              }

            if (!member.selectedQuestionCodes) {
                member.selectedQuestionCodes = [];
            }
            if (!member.selectedQuestionCodes.includes(question_code)) {
                member.selectedQuestionCodes.push(question_code);
            }
            if (existingEntries.length === 0) {
                // If there is no entry, add a new entry
                this.data.push(existingEntry);
            } 
            localStorage.setItem('medical_details', JSON.stringify(this.data));
        } else if (selectedValue === 'NO' || selectedValue === '') {
            existingEntry.Response = 'NO';
            // Remove all entries with the given question_code
            this.data = this.data.filter(question => question.question_set_code != question_set_code);
            localStorage.setItem('medical_details', JSON.stringify(this.data));
            const selectElements = document.getElementsByClassName('existing_since_mm' + questionIndex) as HTMLSelectElement;
            
                 // Iterate through the collection and reset each element
            for (let i = 0; i < selectElements.length; i++) {
                const element = selectElements[i] as HTMLElement;
                // Check if the element is an HTMLSelectElement before accessing its properties
                if (element instanceof HTMLSelectElement) {
                    element.value = ''; // Reset to default placeholder
                    element.dispatchEvent(new Event('change')); // Trigger change detection
                }
            }
        }
        const question = this.medical_questions.find(q => q.question_code === question_code);
        if (!member.carediseaseObject) {
            member.carediseaseObject = [];
        }
        
        // Find the index of the question in carediseaseObject
        const existingQuestionIndex = member.carediseaseObject.findIndex(q => q.question_code === question_code);
        if (existingQuestionIndex !== -1) {
            // Update the existing question entry
            member.carediseaseObject[existingQuestionIndex] = {
                ...member.carediseaseObject[existingQuestionIndex],
                ...question
            };
        } else {
            // Add the new question entry
            member.carediseaseObject.push({ ...question });
        }
    }

    storeResponseForCareSubquestions(memberIndex, event: any, question_code: string, year: string, question_set_code) {
        if (year === '/') {
            year = "";
        }
        const member = this.proposal.insured_member[memberIndex];
        let response = '';
        if(this.monthYearFormatValid(year)){
            this.disableCarePED = false
        }
        
        if(year) {
            response = year;
        } else {
            this.disableCarePED = false
            response  = event.target.value;
        }
        
        if (!member.carediseaseObject) {
            member.carediseaseObject = [];
        }
        const question = this.medical_questions.find(q => q.question_code == question_code && q.question_set_code == question_set_code);
        const existingEntry = this.data.find(question => question.question_code == question_code && question.question_set_code == question_set_code);
        if(question){
            question.Response = response;
        }
        
        if (existingEntry) {
            this.data = this.data.filter(question => question.question_code != question_code && question.question_set_code != question_set_code );
            
        } else {
            this.data.push(question);
        }
        // Find the index of the question in carediseaseObject
        const existingQuestionIndex = member.carediseaseObject.findIndex(q => q.question_code === question_code && q.question_set_code == question_set_code);
        if (existingQuestionIndex !== -1) {
            // Update the existing question entry
            member.carediseaseObject[existingQuestionIndex] = {
                ...member.carediseaseObject[existingQuestionIndex],
                ...question
            };
        } else {
            // Add the new question entry
            member.carediseaseObject.push({ ...question });
        }
        localStorage.setItem('medical_details', JSON.stringify(this.data));
    }

    selectedDiseaseIds: string[] = [];

    handleDiseaseSelection(memberIndex, diseaseID: string, isSelected: boolean) {
        const member = this.proposal.insured_member[memberIndex];
        if (isSelected) {
            if (!member.selectedDiseaseIds) {
                member.selectedDiseaseIds = [];
            }
            if (!member.selectedDiseaseIds.includes(diseaseID)) {
                member.selectedDiseaseIds.push(diseaseID);
            }
        } else {
            // Remove the DiseaseID if it's in the array
            if (member.selectedDiseaseIds) {
                member.selectedDiseaseIds = member.selectedDiseaseIds.filter(id => id !== diseaseID);
            }
        }
    }

    handleGoDigitDiseaseSelection(memberIndex: number, disease: any, isSelected: boolean) {
        const member = this.proposal.insured_member[memberIndex];
    
        // Group subquestions by question_title
        const groupedSubQuestions = disease.subQuestions.reduce((acc, subQuestion) => {
            const key = subQuestion.question_title;
            if (!acc[key]) {
                acc[key] = {
                    question_title: subQuestion.question_title,
                    answer_type: subQuestion.Answer_Type,
                    subQuestions: []
                };
            }
            acc[key].subQuestions.push(subQuestion);
            return acc;
        }, {});
    
        disease.subQuestions = Object.keys(groupedSubQuestions).map(key => groupedSubQuestions[key]);
    
        if (isSelected) {
            if (!member.selectedDiseaseIds) {
                member.selectedDiseaseIds = [];
            }
    
            if (!member.selectedDiseaseIds.some(item => item.DiseaseID === disease.DiseaseID)) {
                member.selectedDiseaseIds.push(disease);
            }
        } else {
            if (member.selectedDiseaseIds) {
                member.selectedDiseaseIds = member.selectedDiseaseIds.filter(item => item.DiseaseID !== disease.DiseaseID);
            }
        }
    
    }

    onGoDigitAnswerChange(DiseaseID: any, value: any, i: any, subQuestionTitle?: string): void {
        const member = this.proposal.insured_member[i];
        let medical = this.medical_questions.find(question => question.DiseaseID === DiseaseID);
        if (medical && medical.subQuestions) {
            const filteredSubQuestions = medical.subQuestions.filter(subQ => {
                
                return subQ.Answer_Code === value || 
                       (subQ.Answer_Code === null && subQ.question_title === subQuestionTitle);
            });
    
            if (!member.selectedQuestions) {
                member.selectedQuestions = [];
            }
            let existingQuestion = member.selectedQuestions.find(q => q.DiseaseID === DiseaseID);
            if (existingQuestion) {
                existingQuestion.subQuestions = [...existingQuestion.subQuestions, ...filteredSubQuestions];
            } else {
                const questionWithFilteredSubQuestions = {
                    ...medical,
                    subQuestions: filteredSubQuestions
                };
    
                member.selectedQuestions.push(questionWithFilteredSubQuestions);
            }
        }
    }
    
    handleDiseaseSelectionforhdfc(memberIndex, diseaseID: string, isSelected: boolean, question_index: number) {
        this.hdfcdiseaseID = diseaseID
        const member = this.proposal.insured_member[memberIndex];
        if (isSelected) {
            if (!member.selectedDiseaseIds) {
                member.selectedDiseaseIds = [];
            }
            if (!member.selectedDiseaseIds.includes(diseaseID)) {
                member.selectedDiseaseIds.push(diseaseID);
            }
        } else {
            // Remove the DiseaseID if it's in the array
            if (member.selectedDiseaseIds) {
                member.selectedDiseaseIds = member.selectedDiseaseIds.filter(id => id !== diseaseID);
            }
        }
        if (!member.hdfcdiseaseObject) {
            member.hdfcdiseaseObject = [];
        }
        this.hdfcdiseaseID = member.selectedDiseaseIds;
        member.hdfcdiseaseObject = member.hdfcdiseaseObject.filter(question =>  member.selectedDiseaseIds.includes(question.DiseaseID));
       
    }

    handleDiseaseSelectionForCareFreedom(memberIndex, questionCode: string, questionSetCode: string, isSelected: boolean, question_title: string) {
        this.hdfcdiseaseID = questionCode
        const member = this.proposal.insured_member[memberIndex];
        if (isSelected) {
            if (!member.selectedDiseaseIds) {
                member.selectedDiseaseIds = [ {
                    "questionSetCd": "yesNoExist",
                    "questionCd": "pedYesNo",
                    "response": "YES"
                },
                ];
            }
            
            if(!member.selectedDiseaseNames){
                member.selectedDiseaseNames = []
            }
            member.selectedDiseaseNames.push(question_title)
            console.log("checking question ",  member.selectedDiseaseNames)


            let questionObject = {
                'questionSetCd':questionSetCode,
                'questionCd':questionCode,
                "response": "YES"
            };
            if (!member.selectedDiseaseIds.includes(questionObject)) {
                member.selectedDiseaseIds.push(questionObject);
            }
            localStorage.setItem('medical_details_present', 'yes')
        } else {
            if (member.selectedDiseaseNames) {
                member.selectedDiseaseNames = member.selectedDiseaseNames.filter(
                    (name) => name !== question_title
                );
            }
            if(member.selectedDiseaseIds.length == 1){
                localStorage.setItem('medical_details_present', 'no')
            }
            if (member.selectedDiseaseIds) {
                member.selectedDiseaseIds = member.selectedDiseaseIds.filter(id => id !== questionCode);
            }
        }
    }

    Updatemedical_questions(current: any, type: string, diseaseId: string) {
        const selectedValue = current.target.value;

        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.DiseaseID === diseaseId);

        // Check if there is an entry with the given DiseaseID in this.data
        const existingDataEntryIndex = this.data.findIndex(question => question.DiseaseID === diseaseId);

        if (existingDataEntryIndex === -1) {
            // If there is no entry, add a new entry
            //console.log(' If there is no entry, add a new entry');

            this.data.push({
                question_title: existingEntry ? existingEntry.question_title : '',
                DiseaseID: diseaseId,
                existing_since: {
                    mm: type === 'existing_since_mm' ? selectedValue : '',
                    yy: type === 'existing_since_yy' ? selectedValue : ''
                },
            });
        } else {
            // If there is an existing entry, update the existing_since.mm or existing_since.yy
            //console.log('If there is an existing entry, update the existing_since.mm or existing_since.yy');
            //console.log(type);

            if (type === 'existing_since_mm') {
                //console.log(existingDataEntryIndex);

                this.data[existingDataEntryIndex].existing_since.mm = selectedValue;
            } else if (type === 'existing_since_yy') {
                this.data[existingDataEntryIndex].existing_since.yy = selectedValue;
            }
        }

        //console.log(this.data);

        localStorage.setItem('medical_details', JSON.stringify(this.data))
    }

    hdfcUpdatemedical_questions(memberIndex: string | number, current: any, type: string, diseaseId: string) {
        const selectedValue = current.target.value;
        const member = this.proposal.insured_member[memberIndex];
        const question = this.medical_questions.find(q => q.DiseaseID === diseaseId);
        if (question) {
            question[type] = selectedValue;
        }
       
        let existingQuestionIndex = member.hdfcdiseaseObject.findIndex(q => q.DiseaseID === diseaseId);
        
        if (existingQuestionIndex !== -1) {
            
            member.hdfcdiseaseObject[existingQuestionIndex] = {
                ...member.hdfcdiseaseObject[existingQuestionIndex],
                ...question
            };
        } else {
            
            member.hdfcdiseaseObject.push({ ...question });
        }
        
        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.DiseaseID === diseaseId);

        // Check if there is an entry with the given DiseaseID in this.data
        const existingDataEntryIndex = this.data.findIndex(question => question.DiseaseID === diseaseId);

        if (existingDataEntryIndex === -1) {
            // If there is no entry, add a new entry
            //console.log(' If there is no entry, add a new entry');

            this.data.push({
                question_title: existingEntry ? existingEntry.question_title : '',
                DiseaseID: diseaseId,
                existing_since: {
                    mm: type === 'existing_since_mm' ? selectedValue : '',
                    yy: type === 'existing_since_yy' ? selectedValue : ''
                },
            });
        } else {
            // If there is an existing entry, update the existing_since.mm or existing_since.yy
            //console.log('If there is an existing entry, update the existing_since.mm or existing_since.yy');
            //console.log(type);

            if (type === 'existing_since_mm') {
                //console.log(existingDataEntryIndex);

                this.data[existingDataEntryIndex].existing_since.mm = selectedValue;
            } else if (type === 'existing_since_yy') {
                this.data[existingDataEntryIndex].existing_since.yy = selectedValue;
            }
        }

        //console.log(this.data);

        localStorage.setItem('medical_details', JSON.stringify(this.data))
    }

    careUpdatemedical_questions(memberIndex: string | number, current: any, type: string, question_code: string) {
        const selectedValue = current.target.value;
        const member = this.proposal.insured_member[memberIndex];
        
        // Ensure carediseaseObject is an array
        if (!Array.isArray(member.carediseaseObject)) {
            member.carediseaseObject = [];
        }
    
        // Find the question in the medical_questions array
        const question = this.medical_questions.find(q => q.question_code === question_code);
        if (question) {
            question[type] = selectedValue;
        }
    
        // Find the index of the question in carediseaseObject
        const existingQuestionIndex = member.carediseaseObject.findIndex(q => q.question_code === question_code);
    
        if (existingQuestionIndex !== -1) {
            // Update the existing question entry
            member.carediseaseObject[existingQuestionIndex] = {
                ...member.carediseaseObject[existingQuestionIndex],
                ...question
            };
        } else {
            // Add the new question entry
            member.carediseaseObject.push({ ...question });
        }
        
    
        // Find or create an entry in this.data
        const existingDataEntryIndex = this.data.findIndex(q => q.question_code == question_code);
        console.log(existingDataEntryIndex);
        
        
        if (existingDataEntryIndex === -1) {
            // Add a new entry to this.data
            this.data.push({
                question_title: question ? question.question_title : '',
                question_code: question_code,
                existing_since: {
                    mm: type === 'existing_since_mm' ? selectedValue : '',
                    yy: type === 'existing_since_yy' ? selectedValue : ''
                },
            });
        } else {
            // Update the existing entry in this.data
            const existingData = this.data[existingDataEntryIndex];
            if (!existingData.existing_since) {
                existingData.existing_since = {};
            }
            if (type === 'existing_since_mm') {
                existingData.existing_since.mm = selectedValue;
            } else if (type === 'existing_since_yy') {
                existingData.existing_since.yy = selectedValue;
            }
        }
    
        // Save updated data to localStorage
        localStorage.setItem('medical_details', JSON.stringify(this.data));
    }
    
    kycDetailsPanVerified() {
        const requestData = {
            "PAN": this.kyc_details.pan_card,
            "DOB": this.kyc_details.date_of_birth,
            "ReturnURL": "www.google.com",
            "UNIQUEID": "445555"
        };
        // Check if the PAN is valid before making the API request
        if (this.panRegex.test(requestData.PAN)) {
            this.ckycService.getPanVerified(requestData).subscribe(
                (response) => {
                    // Handle the successful response here
                    if (response.success == true) {

                        this.toastr.success(response.message, 'Success');
                    } else {

                        this.toastr.error(response.message, 'Failed');
                    }

                    if (response.PAN_Verified == 'true') {
                        this.kyc_details.ispanCardVerfied = true;
                        let collectData = {
                            "PanNo": this.kyc_details.pan_card,
                            "DOB": this.kyc_details.date_of_birth,
                            "PAN_Verified": response.PAN_Verified,
                            "KYC_Verified": response.KYC_Verified,
                            "CKYC": response.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO,
                            "IsDocumentUpload": false,
                            "IsForm60": false,
                        }

                        localStorage.setItem('PAN_Verified', JSON.stringify(collectData))
                    }
                    if (response.PAN_Verified == 'false') {
                        this.kyc_details.ispanCardVerfied = false;
                        let collectData = {
                            "PanNo": this.kyc_details.pan_card,
                            "DOB": this.kyc_details.date_of_birth,
                            "PAN_Verified": response.PAN_Verified,
                            "KYC_Verified": response.KYC_Verified,
                            "CKYC": response.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO,
                            "IsDocumentUpload": false,
                            "IsForm60": false,
                        }

                        localStorage.setItem('PAN_Verified', JSON.stringify(collectData))
                    }

                },
                (error) => {
                    console.error('Error', error);
                    // Handle the error here
                    this.kyc_details.ispanCardVerfied = true;
                }
            );


        } else {
            this.toastr.error('Invalid PAN format. Please enter a valid PAN.','Error');
        }
    }

    onCardChange(newValue: string) {
        this.selectedCard = newValue;
    }

    kycDetailsPanVerified_Icici() {
        this.startLoading();
        const idProofType = this.kyc_details.IDProofType;
        const requestData: any = {
            certificate_type: idProofType,
            pep_flag: false // Adjust based on your requirements
        };

        if(this.kycMethod == 'online'){
             requestData.method = 'online'
            if (idProofType === 'PAN') {
                requestData.pan_details = {
                    pan: this.kyc_details.IDProofNumber,
                    dob: this.kyc_details.date_of_birth
                };
                if (!this.panRegex.test(requestData.pan_details.pan)) {
                    this.toastr.error('Invalid PAN format. Please enter a valid PAN.', 'Error');
                    this.stopLoading();
                    return;
                }
            } else if (idProofType === 'AADHAAR') {
                requestData.aadhaar_details = {
                    aadhaar_number: this.kyc_details.IDProofNumber,
                    name_on_aadhaar: this.kyc_details.aadhaar_name,
                    dob: this.kyc_details.date_of_birth,
                    gender: this.kyc_details.aadhaar_gender
                };
                if (!/^\d{12}$/.test(requestData.aadhaar_details.aadhaar_number)) {
                    this.toastr.error('Invalid Aadhaar format. Please enter a valid 12-digit Aadhaar number.','Error');
                    this.stopLoading();
                    return;
                }
            } else if (idProofType === 'CIN') {
                requestData.cin_details = {
                    cin_number: this.kyc_details.IDProofNumber,
                    date_of_incorporation: this.kyc_details.date_of_incorporation
                };
            } else if (['DL', 'PASSPORT', 'VOTERID', 'CKYC'].includes(idProofType)) {
                requestData.other_id_details = {
                    id_number: this.kyc_details.IDProofNumber,
                    dob: this.kyc_details.date_of_birth
                };
            } else {
                this.toastr.error('Invalid ID Proof Type selected.','Error');
                this.startLoading();
                return;
            }
        } 
        if(this.kycMethod =='upload') {
            requestData.method = 'upload'
            requestData.email = this.kyc_details.email
            requestData.mobile = this.kyc_details.mobile
            if(this.poaFile) {
                requestData.poaFile = this.poaFile
                requestData.POA = this.kyc_details.POA
            }
            requestData.poiFile = this.poiFile
            requestData.POI = this.kyc_details.POI
            requestData.is_poa_poi_same = this.is_poa_poi_same
        }
        this.icici_ckycService.getKYCVerified(requestData).subscribe(
            (response) => {
                this.stopLoading();

                if (response.statusMessage === "Success") {

                    this.toastr.success("Congratulations! Your KYC was successful.");
                    const UserId = localStorage.getItem('getquoteuserID');
                    const getUserUrl = `/loginSignupRoute/getUserDetails/${UserId}`
                    const addKyc     = `/kycDetails/addKyc`
                    let kycpayload: any 
                    this.commonService.get(getUserUrl).subscribe(
                        async userResponse => {
                            if (userResponse.error.errorMessage === "Success" && userResponse.error.errorCode === 200 && userResponse.data[0]) {
                                localStorage.setItem('g_id',userResponse.data[0].g_id)
                                kycpayload  = {
                                    "g_id":userResponse.data[0].g_id ,
                                    "UserId":userResponse.data[0].userId ,
                                    "il_kyc_ref_no":response.kyc_details.il_kyc_ref_no,
                                    "certificate_type":response.kyc_details.certificate_type,
                                    "ckyc_number":response.kyc_details.ckyc_number ,
                                    "insurance_company": "ICICI"
                                  }

                                  this.commonService.post(addKyc, kycpayload).subscribe(res=>{
                                })
                                
                            }
                        },
                        error => {   
                            console.error("Error occurred:", error); 
                        }
                    );

                    this.kyc_details.isVerified = true;

                    const collectData = {
                        IDProofType: idProofType,
                        IDProofNumber: this.kyc_details.IDProofNumber,
                        DOB: this.kyc_details.date_of_birth,
                        CKYC: response.kyc_details.ckyc_number,
                        IsDocumentUpload: false,
                        IsForm60: false,
                        il_kyc_ref_no: response.kyc_details.il_kyc_ref_no,
                        ckyc_number: response.kyc_details.ckyc_number
                    };

                    localStorage.setItem('KYC_Verified', JSON.stringify(collectData));
                } else {
                    const collectData = {
                        IDProofType: idProofType,
                        IDProofNumber: this.kyc_details.IDProofNumber,
                        DOB: this.kyc_details.date_of_birth,
                        CKYC: response.kyc_details.ckyc_number,
                        IsDocumentUpload: false,
                        IsForm60: false,
                        il_kyc_ref_no: response.kyc_details.il_kyc_ref_no,
                        ckyc_number: response.kyc_details.ckyc_number|| null
                    };

                    localStorage.setItem('KYC_Verified', JSON.stringify(collectData));

                    this.toastr.error(response.message, 'Failed');
                    if(this.isGoDigit === true) {
                        this.kyc_details.isVerified = true
                    }
                }
            },
            (error) => {
                this.stopLoading();
                this.kyc_details.isVerified = false;
                this.toastr.error(error, 'Error');

            }
        );
    }

    hdfcGetKyc(){
        this.startLoading();
        let params
        const idProofType = this.kyc_details.IDProofType;
        if (idProofType === 'PAN') {
            if (!this.panRegex.test(this.kyc_details.IDProofNumber)) {
                this.toastr.error('Invalid PAN format. Please enter a valid PAN.', 'Error');
                this.stopLoading();
                return;
            }
            params = new HttpParams()
            .append('pan', this.kyc_details.IDProofNumber)
            .append('dob', this.kyc_details.date_of_birth)
            .append('redirect_url', HDFCenvironment.getKycCredential.redirect_url);

        } else if(idProofType === 'AADHAAR'){
            if (!/^\d{4}$/.test(this.kyc_details.IDProofNumber)) {
                this.toastr.error('Invalid Aadhaar format. Please enter last four digit Aadhaar number.','Error');
                this.stopLoading();
                return;
            }
            params = new HttpParams()
            .append('pan', this.kyc_details.IDProofNumber)
            .append('dob', this.kyc_details.date_of_birth)
            .append('redirect_url', HDFCenvironment.getKycCredential.redirect_url);

        }
       this.HDFCKYC.getKycDetails(params).subscribe(response =>{
        this.kycMessage(response)
       })

    }

    kycMessage(response){
        if(response.data.iskycVerified === 0){
            let redirectLink = response.data.redirect_link;
            redirectLink = redirectLink.replace('{channel}',encodeURIComponent(HDFCenvironment.getKycCredential.Channel));
            window.location.href = redirectLink;
            this.stopLoading();
        }else if (response.data.iskycVerified === 1){
           this.stopLoading();
            this.hdfcKycID = response.data.kyc_id
            this.hdfcKycData = response.data
            localStorage.setItem('hdfckyc', this.hdfcKycID)
            localStorage.setItem("hdfckycData", JSON.stringify(this.hdfcKycData))
            this.toastr.success("Congratulations! Your KYC was successful.");
            this.kyc_details.isVerified = true;
        } else if(response.status ==="approved"){
            this.hdfcKycID = response.kyc_id
            this.hdfcKycData = response.data
            localStorage.setItem('hdfckyc', this.hdfcKycID)
            localStorage.setItem("hdfckycData", JSON.stringify(this.hdfcKycData))
            this.toastr.success("Congratulations! Your KYC was successful.");
            this.kyc_details.isVerified = true;
        }

        const UserId = localStorage.getItem('getquoteuserID');
        const getUserUrl = `/loginSignupRoute/getUserDetails/${UserId}`
        const addKyc     = `/kycDetails/addKyc`
        let kycpayload: any 
        this.commonService.get(getUserUrl).subscribe(
            async userResponse => {
                if (userResponse.error.errorMessage === "Success" && userResponse.error.errorCode === 200 && userResponse.data[0]) {
                    localStorage.setItem('g_id',userResponse.data[0].g_id)
                    kycpayload  = {
                        "g_id":userResponse.data[0].g_id ,
                        "UserId":userResponse.data[0].userId ,
                        "il_kyc_ref_no":null,
                        "certificate_type":null,
                        "ckyc_number":response.data.kyc_id ,
                        "insurance_company": "HDFC"
                        }
                        this.commonService.post(addKyc, kycpayload).subscribe(res=>{
                    })
                    
                }
            },
            error => {   
                console.error("Error occurred:", error); 
            }
        );

    }

    initializeInsuredMemberDetails() {
        this.insuredMemberDetails = this.proposal.insured_member.map(member => ({
            DateOfBirth: CalculatePremiumforHealthIcici.formatDateOfBirth(member.date_of_birth),
            RelationshipWithApplicant: member.relationship_with_user,
            ...this.getAddOnInitialState(),
            ...this.getChildAddOnInitialState() // Include initial state for child addons
        }));
    }

    getAddOnInitialState() {
        let addOnState = {};
        this.icici_add_ons.forEach(addon => {
            addOnState[`AddOn${addon.addon_id}`] = false;
        });
        return addOnState;
    }
    getChildAddOnInitialState() {
        let childAddOnState = {};
        for (let addon of this.icici_add_ons) {
            if (addon.child_cover && addon.child_cover.length > 0) {
                for (let childAddon of addon.child_cover) {
                    childAddOnState[`AddOn${childAddon.addon_id}`] = false; // Initialize child addons to false
                }
            }
        }
        return childAddOnState;
    }

    icicievaluateCondition(member, condition: string): boolean {
        try {
            const age = this.CalculatePremiumforHealthIcici.calculateAgebyDobObject(member.date_of_birth);
            const gender = member.gender;
            const selectedDiseaseIds = this.selectedDiseaseIds;
            return new Function('member', 'age', 'selectedDiseaseIds', 'gender', `return eval(${condition})`)(member, age, selectedDiseaseIds, gender);
        } catch (e) {
            console.error('Condition evaluation error', e);
            return false;
        }
    }

    icicitoggleAddon(icici_add_ons) {
        const isBefit = this.befitAddonIds.includes(icici_add_ons.addon_id);
        if (isBefit && icici_add_ons.showMembers == false){
            if (this.isBefitSelected) {
                this.toastr.warning(`You can choose only one Befit AddOn, Deselect Other Befit Add-ons to opt for it`, 'Notification');
                return;
            }
        }
            
        icici_add_ons.showMembers = !icici_add_ons.showMembers;
        if (icici_add_ons.child_cover) {
            this.showchildcover = !this.showchildcover;
        }
        if (icici_add_ons.showMembers && this.proposal.insured_member) {
            const eligibleMembers = this.proposal.insured_member
                .filter(member => icici_add_ons.addon_conditions.every(condition => this.icicievaluateCondition(member, condition)));
            // Load saved member selection state
            const selectedMembers = this.selectedAddonMembers[icici_add_ons.addon_id] || [];
            const selectedMemberIds = new Set(selectedMembers.map(member => member.id));

            this.addon_eligible_members[icici_add_ons.addon_id] = eligibleMembers.map(member => ({
                ...member,
                selected: selectedMemberIds.has(member.id)
            }));
        } else {
            this.addon_eligible_members[icici_add_ons.addon_id].forEach(member => {
                member.selected = false;
            });
            // Clear selected members for this addon
            delete this.selectedAddonMembers[icici_add_ons.addon_id];
            this.iciciUpdateQuote(icici_add_ons.addon_id);
            if (isBefit) {
                this.isBefitSelected = false
            }
        }

    }

    onCheckboxChange(addonId: number) {
        // Check if any checkboxes are selected for the specified addonId
        const selectedMembers = this.addon_eligible_members[addonId].filter(member => member.selected)
        this.selectedAddonMembers[addonId] = selectedMembers;

        // Update this.selectedMembers for the specified addonId
        if (selectedMembers.length > 0) {
            this.selectedMembers[addonId] = selectedMembers;

        } else {
            // If no members are selected, remove the addonId from selectedMembers
            delete this.selectedMembers[addonId];
        }

        // Check if any checkboxes are selected across all addon_ids
        this.selectedAddons[addonId] = selectedMembers.length > 0;
    }


    iciciUpdateQuote(addonId: string) {
        const isBefitAddon = this.befitAddonIds.includes(addonId);

        if (isBefitAddon && this.isBefitSelected != true) {
            this.isBefitSelected = true
        }

        this.startLoading()
        this.addon_eligible_members[addonId].forEach(member => {
            const detail = this.insuredMemberDetails.find(detail => detail.DateOfBirth === CalculatePremiumforHealthIcici.formatDateOfBirth(member.date_of_birth));
            if (detail) {
                detail[`AddOn${addonId}`] = member.selected;
            }
        });

        const selectedMembers = this.insuredMemberDetails.filter(member => member[`AddOn${addonId}`] === true);
        if (selectedMembers.length > 0) {
            const addon = this.icici_add_ons.find(addon => addon.addon_id === addonId);

            const completeMembers = selectedMembers.map(member => {
                const fullDetails = this.proposal.insured_member.find(detail => CalculatePremiumforHealthIcici.formatDateOfBirth(detail.date_of_birth) === member.DateOfBirth && detail.Relationship === member.Relationship);

                return { ...member, ...fullDetails };
            });

            this.updatedAddons[addonId] = {
                title: addon.addon_title,
                members: completeMembers
            };
        } else {
            delete this.updatedAddons[addonId];
        }

        localStorage.setItem('insured_member_detail_with_addon', JSON.stringify(this.insuredMemberDetails));

        const storedPayload = JSON.parse(localStorage.getItem('icici_quote_payload'));
        storedPayload.InsuredDetails = this.insuredMemberDetails;

        const proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
        let proposer_dob = CalculatePremiumforHealthIcici.formatDateOfBirth(proposer.date_of_birth)
        storedPayload.ProposerDOB = proposer_dob;
        storedPayload.ProposalAnnualIncome = proposer.annual_income

        this.CalculatePremiumforHealthIcici.calculatePremium(storedPayload).pipe(
            catchError((calculatePremiumError) => {
                console.error('iciciHealthService API Error:', calculatePremiumError.message);
                this.stopLoading()
                // Handle the error as needed
                throw calculatePremiumError; // Re-throw the error to propagate it to the next error handler
            })
        ).subscribe((calculatePremiumResponse) => {

            if (calculatePremiumResponse) {
                const basicPremium = parseFloat(calculatePremiumResponse.basicPremium) || 0;
                const totalPremium = parseFloat(calculatePremiumResponse.totalPremium) || 0;
                const totalTax = parseFloat(calculatePremiumResponse.totalTax) || 0;
                const correlationId = calculatePremiumResponse.correlationId;

                this.premium_collection = {
                    ...this.premium_collection,
                    premium_amout: Math.round(basicPremium),
                    gst: Math.round(totalTax),
                    final_premium: Math.round(totalPremium),
                    TotalDiscountAmount: null,
                    correlationId: correlationId,
                    zone: calculatePremiumResponse.zone
                };

                localStorage.setItem('iciciPremium_collection', JSON.stringify(this.premium_collection));
            }

            // Handle the calculatePremiumResponse as needed
            this.stopLoading()
        });
    }

    isBefitAddon(addon: any): boolean {
        return addon.addon_title.startsWith('Befit');
    }

    // Function to determine if pincode is in icici_befit_pincodes
    isBefitPincode(pincode: string): boolean {
        return icici_befit_pincodes.includes(pincode);
    }

    // Function to validate if at least one Befit addon is selected
    validateBefitSelection(): boolean {
        const insuredMembers = JSON.parse(localStorage.getItem('insured_member_detail_with_addon'));
      
        let befitSelected = false;
      if (insuredMembers){
        for (const member of insuredMembers) {
            for (const addonId of this.befitAddonIds) {
              if (member[`AddOn${addonId}`] === true) {
                befitSelected = true;
                break;
              }
            }
            if (befitSelected) {
              break;
            }
          }
      }

      
        if (!befitSelected) {
          return false;
        }
      
        return true;
      }
      
    validateAddonSelection(requiredAddonId: number, coverageThreshold: number): boolean {
        if (this.premium_collection.coverage > coverageThreshold) {
            const isAddonSelected = this.insuredMemberDetails.some(
                member => member[`AddOn${requiredAddonId}`] === true
            );

            if (!isAddonSelected) {
                return false;
            }
        }

        return true;
    }

    validateClaimProtectorSelection(): boolean {
        return this.validateAddonSelection(84, 1500000);
    }

    validateCompassionateVisitSelection(): boolean {
        return this.validateAddonSelection(85, 10000000);
    }

    validateNursingAtHomeSelection(): boolean {
        return this.validateAddonSelection(86, 10000000);
    }





    // kycDetailsPanVerified_Icici() {
    //     const requestData = {
    //         "PAN": this.kyc_details.pan_card,
    //         "DOB": this.kyc_details.date_of_birth
    //     };
    //     // Check if the PAN is valid before making the API request
    //     if (this.panRegex.test(requestData.PAN)) {
    //         this.icici_ckycService.getPanVerified(requestData).subscribe(
    //             (response) => {
    //                 console.log('Success', response);
    //                 // Handle the successful response here
    //                 if (response.statusMessage == "Success") {

    //                     this.toastr.success(response.message, 'Success');
    //                 } else {

    //                     this.toastr.error(response.message, 'Failed');
    //                 }

    //                 if (response.statusMessage == "Success") {
    //                     this.kyc_details.ispanCardVerfied = true;
    //                     let collectData = {
    //                         "PanNo": this.kyc_details.pan_card,
    //                         "DOB": this.kyc_details.date_of_birth,
    //                         "CKYC": response.kyc_details.ckyc_number,
    //                         "IsDocumentUpload": false,
    //                         "IsForm60": false,
    //                     }

    //                     localStorage.setItem('PAN_Verified', JSON.stringify(collectData))
    //                 }

    //             },
    //             (error) => {
    //                 console.error('Error', error);
    //                 // Handle the error here
    //                 this.kyc_details.ispanCardVerfied = true;
    //             }
    //         );


    //     } else {
    //         alert('Invalid PAN format. Please enter a valid PAN.');
    //     }
    // }

    careKyc() {
        this.startLoading();
        const idProofType = this.kyc_details.IDProofType;
        let data 
        if (idProofType === 'PAN') {
            if (!this.panRegex.test(this.kyc_details.IDProofNumber)) {
                this.toastr.error('Invalid PAN format. Please enter a valid PAN.', 'Error');
                this.stopLoading();
                return;
            }
             data = {
                "getCkycEkycInputIO": {
                    "panNum":this.kyc_details.IDProofNumber,
                    "document_type": idProofType,
                    "id_number": "",
                    "consent_purpose": "this is a consent purpose string pass anything",
                    "dob":this.kyc_details.date_of_birth
                }
            }

        } else if(idProofType === 'AADHAAR'){
            if (!/^\d{4}$/.test(this.kyc_details.IDProofNumber)) {
                this.toastr.error('Invalid Aadhaar format. Please enter last four digit Aadhaar number.','Error');
                this.stopLoading();
                return;
            }

            data =  {
                "aadharCKYCDetailsIO": {
                    "aadharno": this.kyc_details.IDProofNumber,
                    "name": this.kyc_details.aadhaar_name,
                    "dob": this.formatDateOfBirth(this.kyc_details.date_of_birth),
                    "gender": this.kyc_details.aadhaar_gender
                }
            }  

        }
       
      this.careToken.careKycVerification(data ,idProofType).subscribe({
       next:(response) => {
        if(response.responseData.message === "Success" && response.responseData.status === "1" ){
            this.stopLoading();

            this.toastr.success("Congratulations! Your KYC was successful.");
                    const UserId = localStorage.getItem('getquoteuserID');
                    const getUserUrl = `/loginSignupRoute/getUserDetails/${UserId}`
                    const addKyc     = `/kycDetails/addKyc`
                    let kycpayload: any 
                    let ckycNO: string
                    if(idProofType ==='AADHAAR') {
                        ckycNO = response.aadharCKYCDetailsIO.kycDetails.personalIdentifiableData.personalDetails.ckycNo
                    } else {
                        ckycNO = response.getCkycEkycInputIO.kycDetails.personalIdentifiableData.personalDetails.ckycNo
                    }
                    this.commonService.get(getUserUrl).subscribe(
                        async userResponse => {
                            if (userResponse.error.errorMessage === "Success" && userResponse.error.errorCode === 200 && userResponse.data[0]) {
                                localStorage.setItem('g_id',userResponse.data[0].g_id)
                                kycpayload  = {
                                    "g_id":userResponse.data[0].g_id ,
                                    "UserId":userResponse.data[0].userId ,
                                    "il_kyc_ref_no":"",
                                    "certificate_type":idProofType,
                                    "ckyc_number":ckycNO,
                                    "insurance_company": "Care"
                                  }
                                  this.commonService.post(addKyc, kycpayload).subscribe(res=>{
                                  console.info("after care kyc", res)
                                })
                                
                            }
                        },
                        error => {   
                            console.error("Error occurred here :", error); 
                            this.toastr.error(error, 'error')
                        }
                    );
                    this.kyc_details.isVerified = true;
                    const collectData = {
                        IDProofType: idProofType,
                        IDProofNumber: this.kyc_details.IDProofNumber,
                        DOB: this.kyc_details.date_of_birth,
                        CKYC: ckycNO
                    };

                    localStorage.setItem('KYC_Verified', JSON.stringify(collectData));
                } else {
                    this.stopLoading();

                    this.toastr.error(response.message, 'Failed');
                }
        this.stopLoading();
    },
    error: (error) => {
          this.toastr.error('Internal Server Error occurred. Please try after some time.', 'Error');
       
        this.stopLoading();
      }
      })
      
    }


    goto(step: number) {
        var regex = constant.emailvalidateregex;
        var mobileNoregex = constant.mobilevalidateregex;
        var addressregex = constant.addressvalidaterregex
        if (this.modalRef2) {
            this.modalRef2.hide();
          }
        if(this.isSelf){
            let data 
            if(this.is_Icici === false) {
                   data = this.hdfcoccupations.filter(occupation=> occupation.STROCCUPCD === parseInt(this.proposal.occupation,10)  )
            }
            if(this.isGoDigit === true) {
              data = this.goDigitOccupation.filter(occupation=> occupation.OCCUPATION ===this.proposal.occupation )
            }
            this.proposal.insured_member.first_name = this.proposal.first_name;
            this.proposal.insured_member.middle_name = this.proposal.middle_name;
            this.proposal.insured_member[0].last_name= this.proposal.last_name;
            this.proposal.insured_member[0].marital_status = this.proposal.marital_status;
            this.proposal.insured_member[0].gender = this.proposal.gender;
            this.proposal.insured_member[0].height = this.proposal.height;
            this.proposal.insured_member[0].weight = this.proposal.weight;
            this.proposal.insured_member[0].IDProofType = this.proposal.IDProofType;
            this.proposal.insured_member[0].IDProofNumber = this.proposal.IDProofNumber;
            this.proposal.insured_member[0].IDProofIssueDate = this.proposal.IDProofIssueDate;
            this.occupation_for_self = (data && data[0] && data[0].OCCUPATION) ||this.proposal.occupation;
            this.proposal.insured_member[0].occupation= this.occupation_for_self;
            localStorage.setItem('proposal_Proposer', JSON.stringify(this.proposal));
            localStorage.setItem('step', `${step}`);
        
        }
        if (step === 2) {
            $("html, body").animate({ scrollTop: 0 }, 600);

            //-----------Proposer data collect--------------
           
            this.step2nextbtn = false;
            this.seletedRelArr = [];
            this.proposal.insured_member = this.proposal.insured_member.map((el) => {
                if (this.noOfChild > 0 && el.membertype === '1a' && this.isCare === false) {
                    el['relArr'] = [{ key: 'self', value: 'Self' }, { key: 'spouse', value: 'Spouse' }];
                } else if (this.noOfChild === 0 && el.membertype === '1a' && this.isCare === false) {
                    if (this.proposal.marital_status === 'Single') {
                        el['relArr'] = this.commonService.relationshipWithProposer();
                    } else if (this.proposal.marital_status === 'Married') {
                        el['relArr'] = this.commonService.relationshipWithProposer();
                    }
                } else if(this.isCare === true) {
                    if(localStorage.getItem('platform') === 'careAdvantagePremium' ||localStorage.getItem('platform') === 'careHeartPremium'  ){
                        el['relArr'] = careAdvantage_HeartRelation

                    } else if (localStorage.getItem('platform') === 'careFreedomPremium'){
                        el['relArr'] = careFreedomRelation

                    }
                }
                 else {
                    el['relArr'] = el.relArr;
                }
                return el;
            });

            if (!this.stateName) {
                this.util.errorDialog("State name is undefined", "Error");
                return;
            }

            let panregex = constant.pannumberregex;
            if(addressregex.test(this.proposal.address.house_no)) {
              this.toastr.error("Only A-Z,a-z,0-9,./#& are allowed in address field", "Error")
              return;
            }
            if (this.proposal.first_name === '') {
                this.toastr.error("Please enter your First Name", "Error");
                return; 
            } else if (!constant.onlyalphabetregex.test(this.proposal.first_name)) {
                this.toastr.error('Only alphabet allowed in First Name', "Error");
                return;
            } else if (this.proposal.last_name === '') {
                this.toastr.error("Please enter your Last Name", "Error");
                return;
            } else if (!constant.onlyalphabetregex.test(this.proposal.last_name)) {
                this.toastr.error('Only alphabet allowed in Last Name', "Error");
                return;
            } else if (this.proposal.emailId === '') {
                this.toastr.error("Please enter an email address", "Error");
                return;
            } else if (!regex.test(this.proposal.emailId)) {
                this.toastr.error("Please enter a valid email address", "Error");
                return;
            } else if (!mobileNoregex.test(this.proposal.contactNumber)) {
                this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
                return;
            } else if (!this.proposal.date_of_birth.dd || !this.proposal.date_of_birth.mm || !this.proposal.date_of_birth.yy) {
                this.toastr.error("Date Of birth is required", "Error");
                return;
            } else if (!this.proposal.marital_status && this.isCare === false) {
                this.toastr.error("Select your Marital Status", "Error");
                return;
            } else if (!this.proposal.gender) {
                this.toastr.error("Select your Gender", "Error");
                return;
            } else if (!this.proposal.height.ft && this.isCare === false) {
                this.toastr.error("Select your Height", "Error");
                return;
            } else if (!this.proposal.weight && this.isCare === false) {
                this.toastr.error("Select your Weight", "Error");
                return;
            } else if (this.noOfChild > 0 && this.proposal.marital_status === 'Single') {
                this.toastr.error("Wrong Marital Status", "Error");
                return;
            } else if (!this.proposal.annual_income && this.isCare === false && this.isGoDigit === false) {
                this.toastr.error("Annual Income is required", "Error");
                return;
            } else if (!this.proposal.occupation && this.isCare === false) {
                this.toastr.error("Occupation is required", "Error");
                return;
            } else if (!this.proposal.address.house_no) {
                this.toastr.error("Enter your Flat, House No, Street", "Error");
                return;
            } else if (!this.proposal.address.area) {
                this.toastr.error("Enter your Area", "Error");
                return;
            } else if (!this.proposal.address.pincode) {
                this.toastr.error("Enter your pincode", "Error");
                return;
            } else if (!this.proposal.address.city) {
                this.toastr.error("Enter your city", "Error");
                return;
            } else if (!this.proposal.address.state_id) {
                this.toastr.error("Enter your state", "Error");
                return;
            } else if (this.construct === '2a' && this.proposal.marital_status === 'Single') {
                this.toastr.error("Wrong Marital Status", "Error");
                return;
            } else if(this.isGoDigit === true && this.proposal.IDProofType === "") {
                this.toastr.error("IDProofType Required", "Error");
                return
            } else if (this.isGoDigit === true &&  this.proposal.IDProofNumber ==="" ) {
                this.toastr.error("IDProofNumber Required", "Error");
                return
            } else if (this.isGoDigit === true  &&  this.proposal.IDProofIssueDate === "" ) {
                this.toastr.error("IDProof Issue date is  Required", "Error");
                return
            }
             else {
                const dob = `${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`;
                let monthdays = this.util.getDaysInMonth(this.proposal.date_of_birth.mm, this.proposal.date_of_birth.yy);
                const proposerAge = this.util.calculateAge(dob);
                if (this.proposal.date_of_birth.dd > monthdays) {
                    this.toastr.error("Invalid Date Of Birth", "Error");
                    return;
                } else if (proposerAge < 18) {
                    this.toastr.error("Proposer should be 18+", "Error");
                    return;
                } else {
                    this.activityCapture('proposer');
                }
            }
            localStorage.setItem('proposal_Proposer', JSON.stringify(this.proposal));
            localStorage.setItem('step', '2');
            // next step
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
        }
        if (step == 3) {
            $("html, body").animate({ scrollTop: 0 }, 600);

            

            //-----------Insured Member------------
            this.seletedRelArr = [];
            this.actulaRel = []
            let valid = true;
            var d = new Date();
            var n = d.getFullYear();
            var maxAge = 0;
            var eldestmemberGender = '';
            let proposerAge = this.util.calculateAge(`${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`);
            for (var el of this.proposal.insured_member) {

                if (!el.relationship_with_user) {
                    this.toastr.error(`${el.memberDef} relationship with proposer`, "Error");
                    valid = false;
                    break;
                }
                if (!el.first_name || !el.last_name) {
                    this.toastr.error(`${el.memberDef} First name and Last name is required`, "Error");
                    valid = false;
                    break;
                } else if (!constant.onlyalphabetregex.test(el.first_name)) {
                    this.toastr.error('Only alphabet allowed in First Name', "Error");
                    valid = false;
                    break;
                } else if (!constant.onlyalphabetregex.test(el.last_name)) {
                    this.toastr.error('Only alphabet allowed in Last Name', "Error");
                    valid = false;
                    break;
                } else if (!el.weight && this.isCare === false) {
                    this.toastr.error(`${el.memberDef} weight is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.marital_status && this.isCare === false ) {
                    this.toastr.error(`${el.memberDef} marital status is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.gender) {
                    this.toastr.error(`${el.memberDef} gender is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step != '1' && !el.occupation && this.isCare === false) {
                    this.toastr.error(`${el.memberDef} occupation is required`, "Error");
                    valid = false;
                    break;
                } else if ((!el.height.ft || !el.height.inch) && this.isCare === false ) {
                    this.toastr.error(`${el.memberDef} height is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.date_of_birth.dd || !el.date_of_birth.mm || !el.date_of_birth.yy) {
                    this.toastr.error(`${el.memberDef} Date Of Birth is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !el.emailId) {
                    this.toastr.error(`${el.memberDef} Email is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !regex.test(el.emailId)) {
                    this.toastr.error("Please enter a valid email address", "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !el.contactNumber) {
                    this.toastr.error(`${el.memberDef} contactNumber is required`, "Error");
                    valid = false;
                    break;
                } else if(this.isGoDigit ==true && !el.IDProofType) {
                    this.toastr.error(`${el.memberDef} ID ProofType is required`, "Error");
                    valid = false;
                    break;
                }  else if(this.isGoDigit ==true && !el.IDProofNumber) {
                    this.toastr.error(`${el.memberDef} ID ProofNumber is required`, "Error");
                    valid = false;
                    break;
                }  else if(this.isGoDigit ==true && !el.IDProofIssueDate) {
                    this.toastr.error(`${el.memberDef} ID Proof Issue Date is required`, "Error");
                    valid = false;
                    break;
                } 

                let dob = `${el.date_of_birth.mm}/${el.date_of_birth.dd}/${el.date_of_birth.yy}`; //mm/dd/yyyy
                var varDate = new Date(`${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`).getTime();
                var today = new Date().getTime();
                let monthdays = this.util.getDaysInMonth(el.date_of_birth.mm, el.date_of_birth.yy);
                //console.log("monthdays : ", monthdays);

                if (varDate > today) {
                    this.toastr.error(`Wrong date of birth choosen for ${el.memberDef}`, "Error");
                    valid = false;
                    break;
                } else if (el.date_of_birth.dd > monthdays) {
                    this.toastr.error("Invalid Date Of Birth", "Error");
                    valid = false;
                    break;
                }

                let memberAge = this.util.calcAgeinDecimal(dob);
                //alert(memberAge);

                if (memberAge > maxAge) {
                    maxAge = memberAge
                    eldestmemberGender = el.gender;
                }

                if (el.construct == '1a' && memberAge < 18) {
                    this.toastr.error(`${el.memberDef} age should be 18+`, "Error");
                    valid = false;
                    break;
                }

                if (el.construct == '1c' && ((memberAge > constant.childamaxAgelimit) || (memberAge < constant.childminAge))) {
                    this.toastr.error(`Child age should be between > 91 days and < 24 yrs`, "Error");
                    valid = false;
                    break;
                }

                if (el.relationship_with_user == 'father' || el.relationship_with_user == 'mother') {
                    let ageDiffwithproposer = memberAge - proposerAge;
                    if (ageDiffwithproposer < 18) {
                        this.toastr.error(`Incorrect Age of  ${el.memberDef}`, "Error");
                        valid = false;
                        break;
                    }
                }
            }; 

            if (!valid) {
                return false;
            }

            this.eldestmemberAge = maxAge;
            this.eldestmemberGender = eldestmemberGender;
            localStorage.setItem('proposal_insured_member', JSON.stringify(this.proposal.insured_member));  
            localStorage.setItem('step', '3');
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('medical details');

        }
        if (step == 4) {
            $("html, body").animate({ scrollTop: 0 }, 600);
            // //console.log(this.medical_questions.existing_since.mm);
            localStorage.setItem('proposal_insured_member', JSON.stringify(this.proposal.insured_member));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            if (proposal_insured_member) {
                let setdate_of_birth = proposal_insured_member[0].date_of_birth;
                this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
                //console.log(this.kyc_details.date_of_birth);
                this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

            }

            let medical_details_present = localStorage.getItem('medical_details_present');
            if (medical_details_present) {

                if (medical_details_present == 'no') {
                    localStorage.setItem('medical_details_present', 'no')
                } else {
                    localStorage.setItem('medical_details_present', 'yes')
                }

            } else {
                localStorage.setItem('medical_details_present', 'no')
            }

            localStorage.setItem('step', '4');
            if(this.isCare === true) {
                this.isPanMandatory = this.premium_collection.final_premium  >= 50000;
                if (this.isPanMandatory) {
                    this.kyc_details.IDProofType = 'PAN';
                }
            }
            
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('kyc');

        }
        if (step == 5) {
            $("html, body").animate({ scrollTop: 0 }, 600);
            // //console.log(this.medical_questions.existing_since.mm);
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
          
            this.addon_step = false;
            if(this.isGoDigit === true) {
                this.nominee_step = true;
                this.nomineeDetails()
            } else {
                this.nominee_step = false;
            }
            //console.log('nominiee');
            localStorage.setItem('step', '5');

        }

        if (step == 6) {
            $("html, body").animate({ scrollTop: 0 }, 600);
            this.initializeInsuredMemberDetails();
            if(this.isGoDigit === false) {
                this.nomineeDetails()
            }

            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.nominee_step = true;

            if(this.isGoDigit === true) {
                this.addon_step = true;
            } else {
                this.addon_step = false;
            }
            localStorage.setItem('step', '6');
        }
        if (step == 7) {
            $("html, body").animate({ scrollTop: 0 }, 600);
            const pincode = this.proposal.address.pincode;
            if (this.isBefitPincode(pincode) && this.validateBefitSelection() === false && this.is_Icici === true) {
                this.toastr.error("Any one Befit Addon is Mandatory to select", "Error");
                return;
            }
            if (this.validateClaimProtectorSelection() === false && this.is_Icici === true) {
                this.toastr.error("Claim Protector Addon is Mandatory for Coverage above 15 Lakhs", "Error");
                return;
            } else if (this.validateCompassionateVisitSelection() === false && this.is_Icici === true) {
                this.toastr.error("Comapassionate Visit Addon is Mandatory for Coverage above 1 Crore", "Error");
                return;
            } else if (this.validateNursingAtHomeSelection() === false && this.is_Icici === true) {
                this.toastr.error("Nursing at Home Addon is Mandatory for Coverage above 1 Crore", "Error");
                return;
            } else if((this.platform === 'careFreedomPremium' || this.platform === 'careHeartPremium'  ) && this.freedomHeartMandatoryAddon === false) {
                this.toastr.error("OPD Care Addon is Mandatory", "Error");
                return;
            }else if( this.platform === 'careAdvantagePremium' && this.advantageMandatoryAddons === false) {
                this.toastr.error("Pre and Post Hospitalization, Unlimited E-Consultation & Unlimited Automatic Recharge are Mandatory", "Error");
                return;
            } else if( this.ncbSuper === true && this.platform === "careAdvantagePremium") {
                this.toastr.error("NCB- Super addon is mandatory", "Error");
                return;
            } else if (
                this.globalAddoncheck === true && 
                (!this.carselectedAddons.includes('NEGCE4092') && !this.carselectedAddons.includes('NEGCI4093'))
            ) {
                this.toastr.error("Please choose from one of the mandatory add-ons: 'Ext of Global Coverage(Exc US)' or 'Ext of Global Coverage(Inc US)'.", "Error");
                return;
            }
            

            const insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            const stateCode = master_pincode.filter(item => item.NUM_PINCODE == pincode)[0].NUM_STATE_CD
            const proposeZone = UwPpmcMaster.filter(item => item.NUM_STATE_CD==stateCode)[0].LOCATION_CATEGORY
            const premium = JSON.parse(localStorage.getItem('iciciPremium_collection'));
            this.hdfcKycData = JSON.parse(localStorage.getItem("hdfckycData"))
            let showAlert = false
            this.messageBMI = '';
            this.messageUw = '';
            if(!this.alertSeen && this.is_Icici === true) {
                for(var member of insured_member){
                    let dob = `${member.date_of_birth.dd}/${member.date_of_birth.mm}/${member.date_of_birth.yy}`
                    let memberAge = this.util.calcAgeinDecimal(dob);
                    if(proposeZone == 'NCRWEST' && memberAge > 55 && premium.coverage > 2000000  ){  
                         showAlert = true
                        this.messageUw = 'Based on your inputs, this plan can be made available only after the proposal is reviewed by the medical underwriter.'
                    } else if(proposeZone == 'SOUTHEAST' && memberAge > 65 && premium.coverage > 5000000){
                        showAlert = true
                        this.messageUw = 'Based on your inputs, this plan can be made available only after the proposal is reviewed by the medical underwriter.'
                    } else if(memberAge > 65 && premium.coverage > 2000000 ){
                        showAlert = true
                        this.messageUw = 'Based on your inputs, this plan can be made available only after the proposal is reviewed by the medical underwriter.'
                    } else if (member.selectedDiseaseIds){
                        showAlert = true
                        this.messageUw = 'Based on your inputs, this plan can be made available only after the proposal is reviewed by the medical underwriter.'
                    }
    
                    const BMI =  this.CheckBMI(member.height.ft , member.height.inch ,member.weight)
                    if ((BMI < 18 || BMI > 35) && memberAge > 18) {
                        showAlert = true;
                        this.messageBMI = 'Based on your BMI, this plan is not valid for you. Please choose a different plan/insurer.';
                        this.messageUw = '';
                    }
    
                }
            }
            
            if (showAlert && this.isGoDigit == false) {
                this.UwAlert(this.showUwAlert);
                return;
            }

            localStorage.setItem('proposal_nominee', JSON.stringify(this.proposal.nominee));
            localStorage.setItem('step', '7');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                //console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.nominee_step = true;
            this.addon_step = true;
            //console.log('review');

            console.log("checking data", this.proposal.insured_member)

        }

    }
    selectNomineeCity(e: { target: { value: any; }; }) {
        let cityObj = this.cityList2.find(c => c.hdfc_code == e.target.value);
        //console.log(cityObj)
        if (cityObj) {
            this.proposal.nominee.cityName = cityObj['city_name']
        } else {
            this.proposal.nominee.city = '';
            alert('City Code doesnot exist');
            return;
        }
    }
    selectNomineeGender (event:any) {
        this.proposal.nominee.gender =  event.target.value
    }

    relationshipVal() {
        const relName = this.nomineeRelations.find(el => el.key == this.proposal.nominee.relationship_with_proposer)
        this.proposal.nominee.relation_value = relName.value
    }

    activityCapture(step: string) {
        if (step == 'proposer') {
            this.ngxLoader.start()
            // const url = `/proposalFormRoute/add_user_details/${this.userid}`;
            const occupation = this.occupation.find((occ) => occ.occupation_code == this.proposal.occupation);
            const postData = {
                "first_name": this.proposal.first_name,
                "last_name": this.proposal.last_name,
                "date_of_birth": this.proposal.date_of_birth.yy + '-' + this.proposal.date_of_birth.mm + '-' + this.proposal.date_of_birth.dd,
                "marital_status": this.proposal.marital_status,
                "gender": this.proposal.gender,
                "session_id": this.session_id,
                "height": this.proposal.height.ft + '.' + this.proposal.height.inch,
                "weight": this.proposal.weight,
                "occupation": (occupation) ? occupation.oc_id : '',
                "pan_card_no": this.proposal.pan,
                "emailId": this.proposal.emailId,
                "contactNumber": this.proposal.contactNumber,
                "annual_income": this.proposal.annual_income,
                "plan_id": this.plan_id,
                "sms_text": "",
                "address": {
                    "house_no": this.proposal.address.house_no,
                    "area": this.proposal.address.area,
                    "landmark": this.proposal.address.landmark,
                    "pincode": this.proposal.address.pincode,
                    "city_id": this.proposal.address.city_id,
                    "state_id": this.proposal.address.state_id,
                    "nationality": this.proposal.address.nationality
                }
            };
            // ////console.log(postData)
            // this.commonService.post(url, postData).subscribe(res => {
            //     //console.log(res)
            //     this.proposer_step = true;
            //     this.step1 = false;
            //     this.step2 = true;
            //     this.step3 = false;
            //     this.step4 = false;
            //     this.step6 = false;
            //     this.ngxLoader.stop();
            // }, err => {
            //     const errorMessage = err && err.message || 'Something goes wrong';
            //     this.toastr.error(errorMessage, 'Error');
            // })
            // //console.log(res)
            this.proposer_step = true;
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step6 = false;
            this.ngxLoader.stop();
        } else if (step == 'insuredmembers') {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step2nextbtn = true;
            this.insured_member_step = true
            this.ngxLoader.stop()

        } else if (step == 'nominee') {
            this.ngxLoader.start()
            // const url = `/proposalFormRoute/add_nominee/${this.userid}?plan_id=${this.plan_id}&session_id=${this.session_id}&session_url=${this.fullURL + '&step=one'}`;
            const body = {
                "first_name": this.proposal.nominee.first_name,
                "last_name": this.proposal.nominee.last_name,
                "age": parseInt(this.proposal.nominee.age),
                "date_of_birth": 0,
                "sms_text": "",
                "relationship_with_proposer": this.proposal.nominee.relationship_with_proposer
            }
            if (parseInt(this.proposal.nominee.age) < 18) {
                body['appointee_data'] = [{
                    "first_name": this.proposal.appointee.appointeeName,
                    "last_name": this.proposal.appointee.appointeeName,
                    "age": (this.proposal.appointee.appointeeAge) ? parseInt(this.proposal.appointee.appointeeAge) : 0,
                    "relationship_with_proposer": this.proposal.appointee.appointeeRelationship
                }]
            } else {
                body['appointee_data'] = "";
            }
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.nominee_step = true;
            this.ngxLoader.stop();
        } else {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step6 = true;
            this.medical_history_step = true;
            this.ngxLoader.stop()
        }
    }

    checkPremium() {
        this.ngxLoader.start()
        if (this.step == '3') {
            let k = 0;
            this.actulaRel = this.actulaRel.map((el) => {
                if (el == 'son' || el == 'daughter') {
                    return `kid`
                } else {
                    return el
                }
            }).map(e => {
                if (e == 'kid') {
                    k++;
                    return `kid${k}`;
                } else {
                    return e;
                }
            })
        }
        //console.log(this.actulaRel);
        const prem_type_disp = this.actulaRel.join("-");
        //console.log(prem_type_disp);
        let url: string;
        this.eldestmemberAge = Math.round(this.eldestmemberAge);
        if (this.step == '3') {
            //Aditya Birla
            let gender: string;
            if (this.plandetails.premium.gender == "" || !this.plandetails.premium.gender) {
                gender = '';
            } else {
                gender = (this.eldestmemberGender == 'MALE' || this.eldestmemberGender == 'Male') ? '1' : '2';
            }
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? 'individual' : prem_type_disp;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=${gender}`;
        } else if (this.step == '2') {
            //Star
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? '1a' : this.construct;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=`
        } else if (this.step == '1') {
            //Religare
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? 'individual' : this.construct;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=`
        }

        if (url) {
            this.commonService.get(url).subscribe(res => {
                //console.log("Check Premium : ", res);
                //alert('Premium will be changed..')
                this.ngxLoader.stop()
                if (res.data.length > 0) {
                    let finalPremium = res.data.filter((el: { premium_term: any; }) => el.premium_term == this.plandetails.premium_term);
                    let finalPremiumwithGST = Math.round(finalPremium[0]['premium_with_gst']);
                    let finalPremiumwithoutGST = Math.round(finalPremium[0]['premium_price']);
                    let finalGSTAmt = Math.round(finalPremium[0]['gst_amount']);
                    ////console.log("Final Premium : ", finalPremium);
                    //console.log("New Premium", finalPremiumwithGST);
                    //console.log("Prev Premium", this.plandetails.OneyearFinalPremium);
                    if (finalPremiumwithGST != this.plandetails.OneyearFinalPremium) {
                        this.plandetails.OneyearFinalPremium = finalPremiumwithGST;
                        this.finalPremiumDisplay = this.util.currencyFormat(finalPremiumwithGST);
                        this.plandetails.premium.premium_price = this.util.currencyFormat(finalPremiumwithoutGST);
                        this.plandetails.premium.gst_amount = this.util.currencyFormat(finalGSTAmt);
                        this.step1 = false;
                        this.step2 = true;
                        this.step3 = false;
                        this.insured_member_step = true
                        var _self = this;
                        $.confirm({
                            title: 'Premium Change Alert!',
                            content: `Based on Relations & Age Your Final Premium will be Rs. ${this.finalPremiumDisplay}`,
                            buttons: {
                                proceed: function () {
                                    //$.alert('Confirmed!');
                                    //console.log('proceed')
                                    localStorage.setItem('premium_id', finalPremium[0].premium_id);
                                    _self.step1 = false;
                                    _self.step2 = false;
                                    _self.step3 = true;
                                    _self.step2nextbtn = true;
                                    _self.insured_member_step = true
                                    _self.activityCapture('insuredmembers')
                                },
                                back: function () {
                                    _self._location.back();
                                }
                            }
                        });
                    } else {
                        this.activityCapture('insuredmembers')
                    }

                } else {
                    this.step2nextbtn = true;
                    this.step1 = false;
                    this.step2 = true;
                    this.step3 = false;
                    this.insured_member_step = true
                    this.util.errorDialog(`Based on the Insured Member relationship with proposer there is no suitable premium found.`, "Alert");
                }
            }, err => {

                const errorMessage = err && err.message || 'Something goes wrong';
                this.toastr.error(errorMessage, 'Error');
            })
        } else {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step2nextbtn = true;
            this.insured_member_step = true
            this.activityCapture('insuredmembers')
        }
    }

    back(step: number, flag?: boolean, index?: any) {
        if (flag) {
            this.previewFlag = flag
        }
        localStorage.setItem('step', step.toString());
        if (step == 1) {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('insures memeber');

        }
        if (step == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('insures memeber');

        }
        if (step == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('medical details');

        }
        if (step == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('kyc');

        }
        if (step == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            this.addon_step = false;
            //console.log('nominiee');

        }
        if (step == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.step7 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            this.addon_step = false;
            //console.log('review');

        }
        if (step == 7) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.step7 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            this.addon_step = true;
            //console.log('review');

        }
        $("html, body").animate({ scrollTop: 0 }, 600);
    }

    selectDisease(e: { target: { checked: any; }; }, member: any, disease: { [x: string]: boolean; }) {
        if (e.target.checked) {
            disease['formvisible'] = true;
        } else {
            disease['formvisible'] = false;
        }
        //console.log(this.proposal.insured_member)
    }

    medicalHistorySelect(e: { target: { checked: any; }; }, member: { has_ped: boolean; selectedDiseaseIds: string[]; }) {
        if (e.target.checked) {
            member.has_ped = true;

            //console.log('medicalHistorySelect + true');

        } else {
            //console.log('medicalHistorySelect + false');
            member.has_ped = false;
            member.selectedDiseaseIds = [];
        }
    }

    getDiseaseNames(member) {
        return icici_medical_questions
          .filter(question => member.selectedDiseaseIds && member.selectedDiseaseIds.includes(question.DiseaseID))
          .map(question => question.question_title);
      }

    selectNomineeDateOfBirth(event: any) {

        const today = new Date();
        const birthDate = new Date(this.proposal.nominee.date_of_birth);


        let age = today.getFullYear() - birthDate.getFullYear();

        if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            this.appointee = true;
        } else {
            this.appointee = false;
        }
        this.proposal.nominee.age = age

    }

    selectNomineeAge(e: { target: { value: number; }; }) {
        if (e.target.value < 18) {
            this.appointee = true;
        } else {
            this.appointee = false;
        }
    }

    savePolicy(IdTypeVal: string, premium_details: any, IdTypeName?: string) {
        let plan_id, plan_name
        
        const proposal_payload = JSON.parse(localStorage.getItem('proposal_payload'));
        if(IdTypeVal === 'ICICI') {
            plan_id = proposal_payload.PlanDetails[0].PlanCode
            plan_name =  proposal_payload.PlanDetails[0].PlanName
        } else if (IdTypeVal === 'HDFC') {
            plan_name=  proposal_payload.Policy_Details.TypeofPlan
            plan_id = null
        } else if (IdTypeVal === 'digit'){
            plan_id = proposal_payload.payload.retailHealthCreateQuote.contract.subInsuranceProductCode
            plan_name = localStorage.getItem('platform')
        } else if(IdTypeVal=== 'care') {
           plan_name = ""
           plan_id = proposal_payload.intPolicyDataIO.policy.baseProductId
        }
        const construct = JSON.parse(localStorage.getItem('userData2Insured'))
        const praposer = JSON.parse(localStorage.getItem('proposal_Proposer'))
       
        let body = {
            "user_id": localStorage.getItem('getquoteuserID'),
            "session_id": localStorage.getItem("session_id"),
            "company_id": "",
            "plan_id":plan_id,
            "premium_id": proposal_payload.SubProductCode,
            "proposal_id_name": IdTypeName,
            "proposal_id_value": IdTypeName,
            "si_id": premium_details.coverage,
            "si_amount": parseInt(premium_details.coverage),
            "company_name": IdTypeVal,
            "plan_name":plan_name,
            "construct": construct[0].value,
            "purchase_date": moment().format('YYYY-MM-DD'),
            "payment_status": "pending",
            "payment_status_reason": "pending",
            "user_name": `${praposer.first_name} ${praposer.last_name}`,
            "age": new Date().getFullYear() - praposer.date_of_birth.yy,
            "transaction_id": "",
            "premium_amount": premium_details.premium_amout,
            "response_data": {
                "amount": premium_details.premium_amout,
                "serviceTax": premium_details.gst,
                "totalPremium": premium_details.final_premium,
            }
        }
        //console.log(body)
        const url = `/userPlanTransactionRoute/add`;
        this.commonService.post(url, body).subscribe(res => {
            //console.log(res)
        }, err => {
            //console.log("Error", err);

        })
    }

    declarationspopup(e: { target: { checked: any; }; }, template: TemplateRef<any>) {
        if (e.target.checked) {
            this.btndisabled = false;
            //this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        } else {
            this.btndisabled = true;
        }
    }

    openTnc(template: any) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    savenShare(template: TemplateRef<any>, section: string) {
        this.section = section;
        this.modalRef2 = this.modalService.show(template, { class: 'common-popup' });
    }

    UwAlert(template: TemplateRef<any>) {
        this.modalRef2 = this.modalService.show(template, { class: 'common-popup' });
      }
    

    proccedForm(){
        this.alertSeen = true
        this.goto(7)
        this.modalRef2.hide();
    }
    nomineerelchange(e: { target: { value: any; }; }) {
        const rel = e.target.value;
        this.proposal.nominee.relationship_with_proposer = rel;
        this.proposal.nominee.relationship_with_proposer_value = rel;
    }

    submitSavenShare() {
        var regex = constant.emailvalidateregex;
        var regexcontactNo = constant.mobilevalidateregex;
        if (this.savenshareObj.emailId == '') {
            this.toastr.error("Please enter your valid email", "Error");
        } else if (!regex.test(this.savenshareObj.emailId)) {
            this.toastr.error("Please enter a valid email address", "Error");
        } else if (!regexcontactNo.test(this.savenshareObj.contactNumber)) {
            this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
            return false;
        } else {
            this.snsBtn = 'Saving data...';
            this.snsdisabled = true;
            const userId = this.userid;
            if (this.section === 'proposer') {
                const url = `/proposalFormRoute/add_user_details/${userId}`;
                const occupation = this.occupation.find((occ) => occ.occupation_code == this.proposal.occupation);
                const postData = {
                    "session_id": this.session_id,
                    "session_url": this.fullURL + '&step=one',
                    "first_name": this.proposal.first_name,
                    "plan_id": this.plan_id,
                    "last_name": this.proposal.last_name,
                    "date_of_birth": this.proposal.date_of_birth.yy + '-' + this.proposal.date_of_birth.mm + '-' + this.proposal.date_of_birth.dd,
                    "marital_status": this.proposal.marital_status,
                    "gender": this.proposal.gender,
                    "height": this.proposal.height.ft + '.' + this.proposal.height.inch,
                    "annual_income": this.proposal.annual_income,
                    "weight": this.proposal.weight,
                    "occupation": (occupation) ? occupation.oc_id : '',
                    "pan_card_no": this.proposal.pan,
                    // "politically_exposed": "",
                    // "medical_practioner_id": "",
                    // "medical_council": "",
                    // "workplace_address": "",
                    "emailId": this.proposal.emailId,
                    "share_email_id": this.savenshareObj.emailId,
                    "email_text": this.fullURL + '&step=one',
                    "contactNumber": this.proposal.contactNumber,
                    "sms_text": this.fullURL + '&step=one',
                    "address": {
                        "house_no": this.proposal.address.house_no,
                        "area": this.proposal.address.area,
                        "landmark": this.proposal.address.landmark,
                        "pincode": this.proposal.address.pincode,
                        "city_id": this.proposal.address.city_id,
                        "state_id": this.proposal.address.state_id,
                        "nationality": this.proposal.address.nationality,
                    }
                };
                //console.log(postData)
                // this.proposalpageSMS(this.fullURL + '&step=one');
                this.commonService.post(url, postData).subscribe(res => {
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", "Success");
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'insuredmembers') {
                // =================INSURED MEMBERS===========
                const url = `/proposalFormRoute/add_insured_members/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const postBody = this.proposal.insured_member.map((el: { occupation: any; relationship_with_user: any; relationship_with_proposer: any; first_name: any; middle_name: any; last_name: any; date_of_birth: { yy: any; mm: any; dd: any; }; marital_status: any; gender: any; height: { ft: string; inch: string; }; weight: any; lifestyle: any[]; }) => {
                    const occupation = this.occupation.find((occ) => occ.occupation_code == el.occupation);
                    return {
                        "session_url": this.fullURL + '&step=two',
                        "sms_text": this.fullURL + '&step=two',
                        "sms_contact_no": this.savenshareObj.contactNumber,
                        "share_email_id": this.savenshareObj.emailId,
                        "relationship_with_user": el.relationship_with_user,
                        "relation_code": el.relationship_with_proposer,
                        "first_name": el.first_name,
                        "middle_name": el.middle_name,
                        "last_name": el.last_name,
                        "date_of_birth": `${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`,
                        "marital_status": el.marital_status,
                        "gender": el.gender,
                        "height": el.height.ft + '.' + el.height.inch,
                        "weight": el.weight,
                        "aadhar_number": "",
                        "pan_card_no": "",
                        "contactNumber": "",
                        "emailId": "",
                        "medical_qa": [],
                        "occupation_id": (occupation) ? occupation.oc_id : '',
                        "medical_history": [],
                        "lifestyle": el.lifestyle.filter((el: { checked: any; }) => el.checked).map((v: { lifestyle_id: any; lifestyle_name: any; numberOfYears: any; consumes: any; }) => {
                            return {
                                lifestyle_id: v.lifestyle_id,
                                lifestyle_type: v.lifestyle_name,
                                number_of_years: v.numberOfYears,
                                consumption_per_day: v.consumes
                            }
                        })
                    }
                })
                //console.log("Insured member : ", postBody)

                // this.proposalpageSMS(this.fullURL + '&step=two');
                this.commonService.post(url, postBody).subscribe(res => {
                    //console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", 'Success');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'nominee') {
                const url = `/proposalFormRoute/add_nominee/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const body = {
                    "session_url": this.fullURL + '&step=three',
                    "sms_text": this.fullURL + '&step=three',
                    "sms_contact_no": this.savenshareObj.contactNumber,
                    "share_email_id": this.savenshareObj.emailId,
                    "first_name": this.proposal.nominee.first_name,
                    "last_name": this.proposal.nominee.last_name,
                    "age": parseInt(this.proposal.nominee.age),
                    "date_of_birth": 0,
                    "relationship_with_proposer": this.proposal.nominee.relationship_with_proposer
                }
                if (parseInt(this.proposal.nominee.age) < 18) {
                    body['appointee_data'] = [{
                        "first_name": this.proposal.appointee.appointeeName,
                        "last_name": this.proposal.appointee.appointeeName,
                        "age": (this.proposal.appointee.appointeeAge) ? parseInt(this.proposal.appointee.appointeeAge) : 0,
                        "relationship_with_proposer": this.proposal.appointee.appointeeRelationship
                    }]
                } else {
                    body['appointee_data'] = "";
                }
                // this.proposalpageSMS(this.fullURL + '&step=three');
                this.commonService.post(url, body).subscribe(res => {
                    //console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", 'Success');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'medical') {
                //=======Medical History-----
                const url = `/proposalFormRoute/add_insured_members/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const postBody = this.proposal.insured_member.map((el: { occupation: any; relationship_with_user: any; first_name: any; middle_name: any; date_of_birth: { yy: any; mm: any; dd: any; }; marital_status: any; gender: any; height: { ft: string; inch: string; }; weight: any; has_ped: any; medical_history: any[]; medical_questions: any[]; lifestyle: any[]; }) => {
                    const occupation = this.occupation.find((occ) => occ.occupation_code == el.occupation);
                    return {
                        "session_url": this.fullURL + '&step=four',
                        "sms_text": this.fullURL + '&step=four',
                        "sms_contact_no": this.savenshareObj.contactNumber,
                        "share_email_id": this.savenshareObj.emailId,
                        "relationship_with_user": el.relationship_with_user,
                        "first_name": el.first_name,
                        "middle_name": el.middle_name,
                        "last_name": el.first_name,
                        "date_of_birth": `${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`,
                        "marital_status": el.marital_status,
                        "gender": el.gender,
                        "height": el.height.ft + '.' + el.height.inch,
                        "weight": el.weight,
                        "aadhar_number": "",
                        "pan_card_no": "",
                        "contactNumber": "",
                        "emailId": "",
                        "occupation_id": (occupation) ? occupation.oc_id : '',
                        "has_ped": el.has_ped,
                        "medical_history": el.medical_history.filter((el: { formvisible: any; }) => el.formvisible).map((m: { medical_history_id: any; current_status: any; symptom_start_date: { mm: string; yy: string; }; health_declined: any; }) => {
                            return {
                                medical_history_id: m.medical_history_id,
                                recovery_status: m.current_status,
                                symptom_start_date: (m.symptom_start_date.mm && m.symptom_start_date.yy) ? m.symptom_start_date.yy + '-' + m.symptom_start_date.mm + '-01' : 0,
                                health_declined: m.health_declined
                            }
                        }),
                        medical_qa: el.medical_questions.map((qus: { [x: string]: string | number; pqm_id: any; question_code: any; }) => {
                            return {
                                plan_ques_map_id: qus.pqm_id,
                                question_code: qus.question_code,
                                answer: qus[qus['question_code']]
                            }
                        }),
                        "lifestyle": el.lifestyle.filter((el: { checked: any; }) => el.checked).map((v: { lifestyle_id: any; consumes: any; }) => {
                            return {
                                lifestyle_id: v.lifestyle_id,
                                consumption_per_day: v.consumes
                            }
                        })
                    }
                })
                //console.log("Medical History : ", postBody);
                // this.proposalpageSMS(this.fullURL + '&step=four');
                this.commonService.post(url, postBody).subscribe(res => {
                    //console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", "Success");
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'buylater') {
                // this.previewSMS()
                // this.buyLater()
            }
        }
    }


    calculateAgeofInsuredMember(e: { target: { value: string; }; }, index: string | number) {
        var d = new Date();
        let currentYear = d.getFullYear();
        let diff = currentYear - parseInt(e.target.value);
        if (diff < 18) {
            this.proposal.insured_member[index]['marital_status_flag'] = false;
            this.proposal.insured_member[index]['marital_status'] = 'Single';
        } else {
            this.proposal.insured_member[index]['marital_status_flag'] = true;
        }
    }

    CheckBMI(heightFt: number, heightIn: number, weight: number): number {
        const heightInMeters = (heightFt * 0.3048) + (heightIn * 0.0254);
        const BMI = weight / (heightInMeters * heightInMeters);
        return BMI;
      }

    getcityListNominee(state: string) {

    }

    getstateList() {
        // const url = `/cityRoute/liststate/1/0/1/?search_key=`;
        // this.commonService.get(url).subscribe(res => {
        this.stateList = [
            {
                state_name: "Maharashtra",
                state_id: "21",
            }
        ];
        this.stateName = this.stateList.filter((el: { state_id: any; }) => {
            return el.state_id === this.proposal.address.state_id
        })[0]['state_name'];
        if (this.stateName) {
            this.proposal.address.state = this.stateName
        } else {
            alert("State Name not found")
        }
        //console.log("State Name : ", this.proposal.address.state);
        // }, err => {
        //     const errorMessage = err && err.message || 'Something goes wrong';
        //     this.toastr.error(errorMessage, 'Error');
        // })
    }

    // call prposal api 
    ProposalCreationForHealth_icici_api(btnText: string): void {
        if (btnText === 'Next') {
            this.startLoading();
            this.CalculatePremiumforHealthIcici.createProposal(this.updatedAddons).pipe(
                catchError((proposalError) => {
                    this.stopLoading();
                    console.error(proposalError);
                    this.toastr.error(proposalError.message, 'Error');
                    throw proposalError;
                })
            ).subscribe((iciciProposal) => {
                if (iciciProposal.status === true && iciciProposal.statusMessage === "Success") {
                    const premium_details = JSON.parse(localStorage.getItem('iciciPremium_collection'))
                    localStorage.setItem('ICICIPROPOSAL', JSON.stringify(iciciProposal));
                    this.savePolicy("ICICI", premium_details, iciciProposal.proposalNumber)
                    this.PaymentGateway.createTransaction();


                } else {
                    this.toastr.error(iciciProposal.message, 'Error');
                }
                this.stopLoading();
            });
        }
    }

    ProposalCreationForCare(btnText: string): void {
        if (btnText === 'Next') {
            this.startLoading();
            const tab = localStorage.getItem('tab')
            if (tab === 'I') {
              this.user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  this.user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  this.user = JSON.parse(localStorage.getItem('userData3'));
              }
          this.careProposal.createPolicy().pipe(
            map(response => response),
            catchError(error => {
                this.stopLoading();
                console.error("Error occurred while creating policy:", error);
                this.toastr.error("Failed to create policy. Please try again."); 
                return throwError(() => error); 
            })
        ).subscribe({
            next: async response => {
                this.stopLoading();
                console.log("here is the response ", response)
                if (response.responseData.status === "1" && response.responseData.message === "Success") {
                    localStorage.setItem('careProposalNum',response.intPolicyDataIO.policy.proposalNum)
                    localStorage.setItem("careProposal",JSON.stringify(response.intPolicyDataIO.policy) )
                  this.careproposalNum = response.intPolicyDataIO.policy.proposalNum;
                  this.careProposalResponse =  response.intPolicyDataIO.policy
                  this.savePolicy("care",{"coverage":JSON.parse(localStorage.getItem('carePremiumPayload')).SI, "premium_amout":response.intPolicyDataIO.policy.basePremium, "final_premium":response.intPolicyDataIO.policy.premium, "gst":response.intPolicyDataIO.policy.tax }, response.intPolicyDataIO.policy.proposalNum )
                 await this.updateFormValues();
                 this.modalRef = this.modalService.show(this.careProposalAlert);
                } else {
                    this.toastr.error(response.intPolicyDataIO.errorLists[0].errDescription);
                    this.stopLoading();
                }
                
            }
        });
        
        }
      }
    
    async  updateFormValues(): Promise<void> {
        if (this.carepaymentForm) {
          const formElement = this.carepaymentForm.nativeElement as HTMLFormElement;
          const proposalNumInput = formElement.querySelector('#proposalNum') as HTMLInputElement;
          const returnURLInput = formElement.querySelector('#returnURL') as HTMLInputElement;
          if (proposalNumInput && returnURLInput) {
            proposalNumInput.value = this.careproposalNum;
            returnURLInput.value =this.base_url+CareEnviroment.careReturnUrl;
            const paymentPayload ={
                "g_id": localStorage.getItem('g_id'),
                "transaction_id":  localStorage.getItem('TransactionId'),
                "user_id": localStorage.getItem('getquoteuserID'),
                'payment_payload':{
                    "proposalNum": this.careproposalNum,
                    "returnUrl":this.base_url+CareEnviroment.careReturnUrl
                }
              }
              this.commonService.post(this.savePayload, paymentPayload ).subscribe()

          } else {
            console.error('Form inputs not found');
          }
        } else {
          console.error('Form element not found');
        }
      }
    
     async submitForm(): Promise<void> {
        if (this.carepaymentForm && this.careproposalNum) {
          this.carepaymentForm.nativeElement.submit();
        } else {
          console.error('Form not ready for submission');
        }
      }

    ProposalCreationForHealth_HDFC(btnText: string): void {
        if (btnText === 'Next') {
            this.startLoading();
            this.HdfcProposal.createProposal().subscribe(
                (response) => {
                    this.stopLoading();
                    if (response.StatusCode === 200){
                        this.hdfcProposalNum = response.Policy_Details.ProposalNumber
                        this.modalRef = this.modalService.show(this.otppopup);
                        this.hdfcpremium_details = response.Policy_Details.TotalPremium
                        JSON.parse(localStorage.getItem(`HDFC${this.platform}`)).final_premium
                        localStorage.setItem('hdfcProposal',response.Policy_Details.ProposalNumber)
                        const premium_details = JSON.parse(localStorage.getItem(`HDFC${this.platform}`))
                        this.savePolicy("HDFC", premium_details, response.Policy_Details.ProposalNumber)
                        this.hdfcProposalMessage = response.Message
                    } else {
                        this.toastr.error(response.Error)
                    }
                },
                error => {
                    this.stopLoading();
                    console.log("we are here",error )
                    const errorMessage = error.error.Error || "An unknown error occurred. Please try again.";
                    this.toastr.error(errorMessage, "Request Failed");
                }
            );
        }
    }
    downloadHdfcCIS() {
        this.startLoading()
        this.HdfcProposal.downloadCIS(this.hdfcProposalNum).subscribe(response=>{
            this.stopLoading();
            if(response.StatusCode === 200 && response.Message === null ) {
                this.isDownloaded = true 

                this.hdfcDocumentDownload.downloadPDF(response.Resp_Policy_Document.PDF_BYTES, "CISDocument")

            }
        })
    }

    hdfcPayment() {
        this.paymentGateway.generateRequestChecksum(this.hdfcpremium_details).subscribe(checksumResponse =>{
            if(checksumResponse.string !== null ){
                this.paymentForm = this.formbuilder.group({
                    Trnsno: [`${localStorage.getItem('TransactionId')}`],
                    Amt: [`${ this.hdfcpremium_details}`],
                    Appid: [`${HDFCenvironment.paymentDetails.appId}`],
                    Subid: [`${HDFCenvironment.paymentDetails.SubscriptionID}`],
                    Src: ['POST'],
                    Surl: [this.base_url+`${HDFCenvironment.paymentDetails.SuccessUrl}`],
                    Furl: [this.base_url+`${HDFCenvironment.paymentDetails.FailureUrl}`],
                    Chksum: [`${checksumResponse.string }`]
                  });
                this.paymentGateway.payment(  this.paymentForm  )
            }
           
        })
    }

    CISdeclarationspopup(e: { target: { checked: any; }; }, template: TemplateRef<any>) {
        if (e.target.checked) {
            this.hdfcPaymentButton = false;
            //this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        } else {
            this.hdfcPaymentButton = true;
        }
    }

   
    

    ProposalCreationForHealth_api(btnText) {
        //    alert(btnText)

        const policyStartDate = this.calculatePolicyStartDate();
        const policyEndDate = this.calculatePolicyEndDate(policyStartDate, 1);

        let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
        let medical_details_present = localStorage.getItem('medical_details_present');
        let medical_details = JSON.parse(localStorage.getItem('medical_details'));
        if (proposal_insured_member) {
            this.proposal.insured_member = proposal_insured_member;
            //console.log(this.proposal.insured_member);

        }
        if (proposal_nominee) {
            this.proposal.nominee = proposal_nominee;
        }

        let medical_detailsData = []
        if (medical_details_present) {
            if (medical_details_present == 'no') {
                medical_detailsData = [];

            } else {
                if (medical_details) {
                    medical_detailsData = medical_details;
                }


            }
        }

        let marital_status = proposal_Proposer.marital_status;
        let marital_status_value = '';

        switch (marital_status) {
            case 'Married':
                marital_status_value = '1951';
                break;
            case 'Single':
                marital_status_value = '1952';
                break;
            case 'Divorcee':
                marital_status_value = '1953';
                break;
            case 'Widow':
                marital_status_value = '1954';
                break;
            case 'Widower':
                marital_status_value = '1994';
                break;
            default:
                marital_status_value = '';
                break;
        }
        let MaritalStatusID_status = proposal_insured_member[0].marital_status;
        let MaritalStatusID_value = '';

        switch (MaritalStatusID_status) {
            case 'Married':
                MaritalStatusID_value = '1951';
                break;
            case 'Single':
                MaritalStatusID_value = '1952';
                break;
            case 'Divorcee':
                MaritalStatusID_value = '1953';
                break;
            case 'Widow':
                MaritalStatusID_value = '1954';
                break;
            case 'Widower':
                MaritalStatusID_value = '1994';
                break;
            default:
                MaritalStatusID_value = '';
                break;
        }

        let RelationshipWithProposerID = proposal_insured_member[0].relationship_with_user;
        let RelationshipWithProposer = '';

        switch (RelationshipWithProposerID) {
            case '345':
                RelationshipWithProposer = 'Self';
                break;
            case '320':
                RelationshipWithProposer = 'Spouse';
                break;
            case '321':
                RelationshipWithProposer = 'Son';
                break;
            case '322':
                RelationshipWithProposer = 'Daughter';
                break;
            case '2059':
                RelationshipWithProposer = 'Son In Law';
                break;
            case '2060':
                RelationshipWithProposer = 'Daughter In Law';
                break;
            case '319':
                RelationshipWithProposer = 'Father';
                break;
            case '1255':
                RelationshipWithProposer = 'Mother';
                break;
            case '2058':
                RelationshipWithProposer = 'Father In Law';
                break;
            case '2057':
                RelationshipWithProposer = 'Mother In Law';
                break;
            case '2063':
                RelationshipWithProposer = 'Grand Father';
                break;
            case '2064':
                RelationshipWithProposer = 'Grand Mother';
                break;
            case '2061':
                RelationshipWithProposer = 'Grand Son';
                break;
            case '2062':
                RelationshipWithProposer = 'Grand Daughter';
                break;
            case '324':
                RelationshipWithProposer = 'Brother';
                break;
            case '323':
                RelationshipWithProposer = 'Sister';
                break;
            case '2671':
                RelationshipWithProposer = 'Brother In Law';
                break;
            case '2672':
                RelationshipWithProposer = 'Sister In Law';
                break;
            case '3095':
                RelationshipWithProposer = 'Nephew';
                break;
            case '3759':
                RelationshipWithProposer = 'Niece';
                break;
            default:
                RelationshipWithProposer = '';
                break;
        }



        // // Function to convert existing_since to dd/mm/yy format
        // function convertDateFormat(dateObject) {
        //     const dd = "01"; // Assuming day is always 01
        //     const mm = dateObject.mm;
        //     const yy = dateObject.yy;

        //     return `${dd}/${mm}/${yy}`;
        // }

        // // Loop through diseases and update existing_since format
        // for (const disease of medical_detailsData) {
        //     disease.existing_since = convertDateFormat(disease.existing_since);
        // }

        // //console.log(medical_detailsData);

        const convertedConditions = medical_detailsData.map(condition => {
            const sufferingSince = `${condition.existing_since.dd || '01'}/${condition.existing_since.mm || '01'}/${condition.existing_since.yy}`;
            return { DiseaseID: condition.DiseaseID, SufferingSince: sufferingSince };
        });

        //console.log(convertedConditions);


        if (btnText == 'Next') {
            this.startLoading();

            let params =
            {
                'ClientDetails': {
                    'ClientTypeID': '0',
                    'DOB': `${proposal_Proposer.date_of_birth.dd}/${proposal_Proposer.date_of_birth.mm}/${proposal_Proposer.date_of_birth.yy}`,
                    'Email': proposal_Proposer.emailId,
                    'FirstName': proposal_Proposer.first_name,
                    'LastName': proposal_Proposer.last_name,
                    'MiddleName': proposal_Proposer.middle_name,
                    'MaritalStatusID': marital_status_value,
                    'Gender': proposal_Proposer.gender == 'MALE' ? "0" : "1",
                    'Nationality': 'Indian',
                    'OccupationID': proposal_Proposer.occupation,
                    'Salutation': 'Mr.',
                    'PassportNo': null,
                    'AnnualIncome': proposal_Proposer.annual_income,
                    'StateID': '21',
                    'AadhaarNo': null,
                    'RegisteredUnderGST': null,
                    'RelatedParty': null,
                    'GSTIN': null,
                    'ClientAddress': {
                        'CommunicationAddress': {
                            'Aadhaar': null,
                            'Address1': proposal_Proposer.address.house_no,
                            'Address2': proposal_Proposer.address.landmark,
                            'Address3': null,
                            'CityID': '244887',
                            'Country': '1',
                            'DistrictID': '336',
                            'Email': proposal_Proposer.emailId,
                            'Fax': null,
                            'MobileNo': proposal_Proposer.contactNumber,
                            'NearestLandmark': null,
                            'PanNo': null,
                            'PhoneNo': proposal_Proposer.contactNumber,
                            'Pincode': proposal_Proposer.address.pincode,
                            'AreaID': '45583',
                            'StateID': '21',
                            'City': '',
                            'District': '',
                            'Area': '',
                            'State': '',
                            'IsNomineeSameasCommAddr': 'true'
                        },
                        'PermanentAddress': {
                            'Address': {
                                'Aadhaar': null,
                                'Address1': 'adddresss1 addddresss2',
                                'Address2': '.',
                                'Address3': null,
                                'CityID': '244887',
                                'Country': '1',
                                'DistrictID': '336',
                                'Email': 'latam@policybazaar.com',
                                'Fax': null,
                                'MobileNo': '8765434567',
                                'NearestLandmark': null,
                                'PanNo': null,
                                'PhoneNo': '8765434567',
                                'Pincode': '400601',
                                'AreaID': '45583',
                                'StateID': '21',
                                'City': '',
                                'District': '',
                                'Area': '',
                                'State': '',
                                'IsNomineeSameasCommAddr': 'true'
                            },
                            'IsPermanentSameasCommAddr': 'true'
                        }
                    },
                    'MobileNo': proposal_Proposer.contactNumber
                },
                'InsuredDetailsList': [
                    {
                        'RelationshipWithProposerID': RelationshipWithProposerID,
                        'RelationshipWithProposer': RelationshipWithProposer,
                        'IsAnyPreExistingIllness': 'false',
                        'Salutation': 'Mr.',
                        'FirstName': proposal_insured_member[0].first_name,
                        'LastName': proposal_insured_member[0].last_name,
                        'MiddleName': proposal_insured_member[0].middle_name,
                        'Gender': proposal_insured_member[0].gender == 'MALE' ? "0" : "1",
                        'Age': ProposalComponent.calculateAge(`${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`),
                        'DOB': `${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`,
                        'MaritalStatusID': MaritalStatusID_value,
                        'MaritalStatus': MaritalStatusID_status,
                        'OccupationID': proposal_insured_member[0].occupation,
                        'Occupation': null,
                        'UHID': null,
                        'UHIDCreatedDate': null,
                        'Height': ProposalComponent.convertHeightToCm(proposal_insured_member[0].height.ft, proposal_insured_member[0].height.inch),
                        'Weight': proposal_insured_member[0].weight,
                        'SpecialConditions': '',
                        'PreExistingDisease': {
                            'IsExistingIllness': medical_details_present == 'no' ? 'false' : 'true',
                            'DiseaseList': convertedConditions,
                            'IsInsuredConsumetobacco': null,
                            'HasAnyPreClaimOnInsured': null,
                            'DetailsOfPreClaimOnInsured': null,
                            'HasAnyPreHealthInsuranceCancelled': null,
                            'DetailsOfPrevInsuranceCancelled': null
                        },
                        'InsureQuestionsList': [

                        ],
                        'OtherInsuranceList': [
                            {
                                'PreviousInsuraceCompName': null,
                                'PreviousPolNo': null,
                                'PreviousPolStartDate': null,
                                'PreviousPolEndDate': null,
                                'CoverTypeID': null,
                                'SumInsured': null,
                                'AccumulatedCumulativeBonus': null
                            }
                        ],
                        'SumInsured': '',
                        'IsAnyPhysicalDefect': null,
                        'PhysicalDefectDetails': null,
                        'ISPersonEarningMember': null,
                        'TotalCapitalSI': null,
                        'Hasanyclaimwithcurrentpreviousinsurer': null,
                        'IsAnyDeclinedPolicy': null,
                        'IsAnyotherhlthinspolicywiththeCompany': null,
                        'OtherInformation': null,
                        'Discount': null,
                        'Loading': null,
                        'IsAnyInsuredClaimed': null,
                        'IsPrevInsuranceCancelled': null,
                        'IsInsuredcoveredInHltPolicy': null,
                        'NationalityId': null,
                        'InsuredClaimDetails': null,
                        'IsFitnessDeclaration': null,
                        'IsInsuredConsumetobacco': null,
                        'IsExistingIllness': null,
                        'DoesInsuredSmokeOrConsumeAlchohol': null,
                        'IsIllnessCancer': null,
                        'IsIllnessOrganTransplant': null,
                        'IsIllnessMultipleScelorsis': null,
                        'IsIllnessBurns': null,
                        'IsIllnessGraft': null,
                        'IsIllnessHeartValve': null,
                        'IsIllnessComa': null,
                        'IsIllnessQuadriplegia': null,
                        'IsIllnessBlind': null,
                        'IsIllnessRenal': null,
                        'IllnessCancerNames': null,
                        'IllnessOrganTransplantNames': null,
                        'IllnessMultipleScelorsisNames': null,
                        'IllnessBurnsNames': null,
                        'IllnessGraftNames': null,
                        'IllnessHeartValveNames': null,
                        'IllnessComaNames': null,
                        'IllnessQuadriplegiaNames': null,
                        'IllnessBlindNames': null,
                        'IllnessRenalNames': null,
                        'SplitApplicable': null,
                        'IsMemberRenewed': null,
                        'PreviousCumulativeBonusrate': null,
                        'PreviousCumulativeBonus': null,
                        'CumulativeBonus': null,
                        'CumulativeBonusrate': null,
                        'NewlyAddedMember': null,
                        'CurrentYearNCD': null,
                        'ApplicableSublimitforPortability': null,
                        'IsInsuredDiagnosedEarlier': null,
                        'EditGridRow': null
                    }
                ],
                'Policy': {
                    'Tenure': 1,
                    'PolicyStartDate': policyStartDate,
                    'PolicyEndDate': policyEndDate,
                    'Branch_Code': '9202',
                    'BusinessTypeID': '1',
                    'ProductCode': '2868',
                    'ExternalSystemID': '1',
                    'POSType': null,
                    'POSAadhaarNumber': null,
                    'POSPANNumber': null,
                    'IsQuickQuote': false
                },
                'RiskDetails': {
                    'PlanID': 82,
                    'CategoryID': '0',
                    'DOBofSeniorMost': `${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`,
                    'MemberCount': 1,
                    'Age': ProposalComponent.calculateAge(`${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`),
                    'CoverTypeID': '1',
                    'SumInsured': '500000',
                    'PaymentBy': 'Proposer',
                    'Tenure': 1,
                    'InstallmentTypeID': null,
                    'NameOfPhysician': null,
                    'PhysicianContactNo': null,
                    'PhysicianEmail': null,
                    'NoofInstallments': null,
                    'HealtExtension': {
                        'Roomrenttype': null,
                        'Voluntarydeductiblelimit': null,
                        'Reductioninwaitingperiodlimit': null,
                        'DCBLimit': null,
                        'lstAddonDetails': [
                            {
                                'AddOnName': 'AD01',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD02',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD03',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD04',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD05',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD06',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD08',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD09',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD10',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD11',
                                'IsSelected': false
                            }
                        ]
                    },
                    'MultipleSI': 'No',
                    'PlanwiseAddOn': 'false',
                    'AddOnPlansType': 'None',
                    'Bundling': 'false',
                    'UpgradepremiumByZone': 'false',
                    'Zone': null,
                    'DeductibleSI': '0',
                    'BundleSumInsured': null,
                    'CoverType': 'Individual',
                    'NoofMembersAbove21': null,
                    'NoofMembersBelow21': 0
                },
                'NomineeDetails': {
                    'Salutation': 'Mr.',
                    'FirstName': proposal_nominee.first_name,
                    'MiddleName': '',
                    'LastName': proposal_nominee.last_name,
                    'DOB': '01/01/1998',
                    'ApoointeeNomineeMobileNoHI': '',
                    'AppointeeDOB': '01/01/1998',
                    'NomineeRelationshipID': '1986',
                    'NomineeRelationshipOther': null,
                    'GuardianName': null,
                    'RelationshipwithProposerID': '320',
                    'RelationshipwithProposer': null,
                    'NomineeAddress': {
                        'Aadhaar': null,
                        'Address1': proposal_Proposer.address.house_no,
                        'Address2': proposal_Proposer.address.landmark,
                        'Address3': null,
                        'CityID': '244887',
                        'Country': '1',
                        'DistrictID': '336',
                        'Email': proposal_Proposer.emailId,
                        'Fax': null,
                        'MobileNo': proposal_Proposer.contactNumber,
                        'NearestLandmark': null,
                        'PanNo': null,
                        'PhoneNo': proposal_Proposer.contactNumber,
                        'Pincode': proposal_Proposer.address.pincode,
                        'AreaID': '45583',
                        'StateID': '21',
                        'City': '',
                        'District': '',
                        'Area': '',
                        'State': '',
                        'IsNomineeSameasCommAddr': 'true'
                    },
                    'AppointeeName': null
                },
                'PreviousInsuranceDetails': {
                    'PrevInsuranceID': null,
                    'PrevYearPolicyNo': null,
                    'PrevYearPolicyStartDate': null,
                    'PrevYearPolicyEndDate': null,
                    'PreYearNCD': null,
                    'prevYearTenure': null,
                    'PrevYearSI': null,
                    'PrevInsurerBranchID': null,
                    'PrevInsurerContactNo': null,
                    'PrevInsurerEmailID': null,
                    'PrevInsurerAddress': null,
                    'PrevInsurerBranchPincode': null,
                    'PrevPolicyType': null
                },
                'PolicyDiscount': {
                    'IsAnyEmployeeOfRelianceADAGroup': 'false',
                    'EmployeeCode': '',
                    'IsRGIHealthPolicyHolder': 'false',
                    'IsRGIRetailPolicyHolder': 'false',
                    'RGIHealthPolicyNo': null,
                    'RGIRetailPolicyNo': null,
                    'IsReferredByEmployee': 'false',
                    'IsOtherInsuranceApplicable': 'false'
                },
                'CoverDetails': null,
                'ClaimStatus': 'false',
                'NoofSplits': null,
                'Issplitcase': null,
                'UserID': '100002API',
                'SourceSystemID': '100002API',
                'AuthToken': 'Pass@123',
                'TraceID': null,
                'IsQuickquote': 'false'
            }


            this.ProposalService.ProposalCreationForHealthToAPI(params).subscribe(
                (response) => {
                    if (response.Policy.ProposalNo !== null) {
                        this.btnText = "Buy Now"
                        this.stopLoading();
                        localStorage.setItem('ProposalNo', response.Policy.ProposalNo)
                        localStorage.setItem('FinalPremium', response.Premium.FinalPremium)
                        localStorage.setItem('UserID', response.UserID)
                    }
                },
                (error) => {
                    console.error('API Error:', error);
                    // Handle the error as needed
                }
            );


            // this.btnText = "Buy Now" 
        } else {
            let ProposalNo = localStorage.getItem('ProposalNo');
            let FinalPremium = localStorage.getItem('FinalPremium');
            let UserID = localStorage.getItem('UserID');
            let PAN_Verified = JSON.parse(localStorage.getItem('PAN_Verified'));
            const url = `https://rgipartners.reliancegeneral.co.in/PaymentIntegration/PaymentIntegration?ProposalNo=${ProposalNo}&userID=${UserID}&ProposalAmount=${FinalPremium}&PaymentType=1&Responseurl=http://localhost:4200/home&CKYC=${PAN_Verified.CKYC}&IsDocumentUpload=${PAN_Verified.IsDocumentUpload}&PanNo=${PAN_Verified.PanNo}&IsForm60=${PAN_Verified.IsForm60}`;

            localStorage.setItem('url', url);

            window.location.href = url;
        }

    }

    private calculatePolicyStartDate(): string {
        const currentDate = new Date();
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);

        const day = nextDate.getDate().toString().padStart(2, '0');
        const month = (nextDate.getMonth() + 1).toString().padStart(2, '0');
        const year = nextDate.getFullYear();

        return `${day}/${month}/${year}`;
    }

    private calculatePolicyEndDate(startDate: string, tenure: number): string {
        const [day, month, year] = startDate.split('/').map(Number);

        // Create start date object (month is 0-indexed in JavaScript Date)
        const startDateObj = new Date(year, month - 1, day);

        // Create end date object by adding tenure years
        const endDateObj = new Date(startDateObj);
        endDateObj.setFullYear(startDateObj.getFullYear() + tenure);

        // Subtract one day from the end date to get the correct policy end date
        endDateObj.setDate(endDateObj.getDate() - 1);

        // Handle the special case of February 29 in a leap year
        if (startDateObj.getMonth() === 1 && startDateObj.getDate() === 29 && endDateObj.getMonth() !== 1) {
            endDateObj.setDate(28);
        }

        // Format end date parts
        const endDay = endDateObj.getDate().toString().padStart(2, '0');
        const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0');
        const endYear = endDateObj.getFullYear().toString();

        return `${endDay}/${endMonth}/${endYear}`;
    }

    static calculateAge(birthdate: string): number {
        const currentDate = new Date();
        const birthDate = new Date(birthdate);

        // Calculate the age
        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Check if the birthday has occurred this year
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        return age;
    }

    static convertHeightToCm(feet: number, inches: number): number {
        // 1 foot = 30.48 cm
        const heightInCm = feet * 30.48;

        // 1 inch = 2.54 cm
        const additionalCm = inches * 2.54;

        // Total height in centimeters
        const totalHeightInCm = heightInCm + additionalCm;

        return Math.round(totalHeightInCm);
    }

    startLoading() {
        this.loading = true;
    }



    stopLoading() {
        this.loading = false;
    }


    // private getOccupationByCode(code) {
    //     return this.occupations.find(occupation => occupation.occupation_code === code);}


    openModal(template: TemplateRef<any>, addon: any) {
        this.selectedAddon = addon;
        this.modalRef = this.modalService.show(template, { class: 'modal-md common-popup' });
    }

    validateAppointeeDateOfBirth(event: any) {
        const selectedDate = new Date(event.target.value);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - selectedDate.getFullYear();
        const monthDifference = currentDate.getMonth() - selectedDate.getMonth();
        const dayDifference = currentDate.getDate() - selectedDate.getDate();
    
        if (
          age < 18 ||
          (age === 18 && (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
        ) {
          this.isAppointeeDateInvalid = true;
          this.toastr.error("Appointee age must be greater than 18.")
        } else {
          this.isAppointeeDateInvalid = false;
          this.proposal.appointee.appointeeAge = age
        }
    }

    addOnSelect(envnt: { target: { checked: any; }; }, member: { has_addOn: boolean; }) {
        if (envnt.target.checked) {
            member.has_addOn = true;

        } else {
            member.has_addOn = false;
           
        }
    }

    onChangeAddOn(event: Event, member: { has_addOn: any; hdfcAddOns?: any[] }, addOnType: any, memberIndex: number, addon_name: string) {
        this.startLoading();
        let members = this.proposal.insured_member;
        const target = event.target as HTMLInputElement | HTMLSelectElement;
        if (addOnType === 'HDC') {
            this.HDCSelected = (target as HTMLSelectElement).value;
        }
    
        if (!member.hdfcAddOns) {
            member.hdfcAddOns = [];
        }
        if (target.type === 'checkbox') {
            const isChecked = (target as HTMLInputElement).checked;
            member[addOnType] = isChecked;
    
            if (isChecked) {
                
                if (!member.hdfcAddOns.some(addOn => addOn.name === addon_name)) {
                    member.hdfcAddOns.push({ name: addon_name, response: (target as HTMLInputElement).value });
                }
            } else {
                
                member.hdfcAddOns = member.hdfcAddOns.filter(addOn => addOn.name !== addon_name);
            }
        } else if (target.tagName === 'SELECT') {
            const selectedValue = (target as HTMLSelectElement).value;
            member[addOnType] = selectedValue;
    
            const existingAddOn = member.hdfcAddOns.find(addOn => addOn.name === addon_name);
            if (existingAddOn) {
                existingAddOn.response = selectedValue;
            } else {
                member.hdfcAddOns.push({ name: addon_name, response: selectedValue });
            }
        }
    
        if (memberIndex !== -1) {
            members[memberIndex] = member;
        }
    
        this.hdfcPremium.calculateHDFCpremium(member, memberIndex).subscribe({
            next: (response) => {
              this.stopLoading();
              this.updatePremium(response);
            },
            error: (error) => {
              this.stopLoading();
              const errorMessage = (error.error && error.error.Error) ? error.error.Error : 'Premium calculation failed. Please check data later.';
              this.toastr.error(errorMessage, 'Error',);
            }
          });
        
        localStorage.setItem('proposal_insured_member', JSON.stringify(this.proposal.insured_member));
    }

    onChangeDeductibleAmount(event){
        this.startLoading();
        const target = event.target as HTMLInputElement | HTMLSelectElement;
        let selectedValue = "0"
        selectedValue = (target as HTMLSelectElement).value;
        this.hdfcPremium.calculateHDFCpremium( parseInt(selectedValue, 10) ).subscribe({
            next: (response) => {
              this.stopLoading();
              this.updatePremium(response);
            },
            error: (error) => {
              this.stopLoading();
              const errorMessage = (error.error && error.error.Error) ? error.error.Error : 'Premium calculation failed. Please check data later.';
              this.toastr.error(errorMessage, 'Error',);
            }
          });
        localStorage.setItem('deductibleAmount', selectedValue)
    }

    isAdult(birthdate: any): boolean {
        const birthDate = new Date(parseInt(birthdate.yy), parseInt(birthdate.mm) - 1, parseInt(birthdate.dd));
        const today = new Date();
    
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
      
        
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
      
        return age > 18;
    }

    updatePremium(response) {
       
        if (response.Response_Data_OS) {
            const tab = localStorage.getItem('tab')
            let user
            if (tab === 'I') {
              user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  user = JSON.parse(localStorage.getItem('userData3'));
              }
        const hdfcTotalPremium = parseFloat(response.Response_Data_OS.TotalPremium) || 0;
        const gstComponent1 = parseFloat(response.Response_Data_OS.GST) || 0;
        const coverage = parseFloat(response.Response_Data_OS.SumInsured) || 0;
        const premium_amout = parseFloat(response.Response_Data_OS.NetPremium) || 0;
        const premium_year = parseFloat(user.tenure) || 0;
        this.premium_collection = {
          coverage: coverage,
          premium_year: premium_year,
          premium_amout: premium_amout,
          gst: gstComponent1 ,
          final_premium: hdfcTotalPremium,
        };
       

        localStorage.setItem('HDFCPremium_collection', JSON.stringify(this.premium_collection));
        this.stopLoading();
    }
    this.stopLoading();

       
    }

    capitalizeIDProofNumber() {
        this.kyc_details.IDProofNumber = this.kyc_details.IDProofNumber.toUpperCase();
      }
    
      addonToggleCare(addon) {
          addon.showMembers = !addon.showMembers;
          if (addon.showMembers) {
              if (!this.carselectedAddons.includes(addon.addon_id)) {
                this.carselectedAddons.push(addon.addon_id);
              }
          } else {
              const index = this.carselectedAddons.indexOf(addon.addon_id);
              if (index > -1) {
                this.carselectedAddons.splice(index, 1);
              }
          }
          if (this.carselectedAddons.includes("COPD1211")) {
          this.freedomHeartMandatoryAddon = true
          } else if ( (this.carselectedAddons.includes("PREPHECA1150") && this.carselectedAddons.includes("UECV1153")) && this.carselectedAddons.includes("UARV1195")) {
           this.advantageMandatoryAddons = true
          } 
       
          this.ncbSuper =  this.careAddonsCheck.checkNCBSuper(this.carselectedAddons, this.proposal.insured_member, this.proposal.address.pincode)
         
          this.globalAddoncheck = this.careAddonsCheck.checkGlobalAddons()

         if(this.carselectedAddons.length > 9 ){
            this.toastr.error("You Can Selected Maximum 9 Addons Including Mandatory Addons ")
            this.addonButtonflag = false
            return
         } else {
            this.addonButtonflag = true

         }
         const addonMap = {
            'NEGCE4092': 'NEGCI4093',
            'NEGCI4093': 'NEGCE4092'
        };
        
        for (const [addon, disableAddon] of Object.entries(addonMap)) {
            const disableIndex = this.careAddon.findIndex(addons => addons.addon_id === disableAddon);
            if (disableIndex !== -1) {
                this.careAddon[disableIndex].isButtonDisabled = this.carselectedAddons.includes(addon);
            }
        }
        
        if (!this.carselectedAddons.includes('NEGCE4092') && !this.carselectedAddons.includes('NEGCI4093')) {
            ['NEGCE4092', 'NEGCI4093'].forEach(id => {
                const index = this.careAddon.findIndex(addons => addons.addon_id === id);
                if (index !== -1) {
                    this.careAddon[index].isButtonDisabled = false;
                }
            });
        }
        const filteredAddons =this.careAddon.filter(addon =>
            this.carselectedAddons .includes(addon.addon_id)
          );
        if(!this.proposal.selectcareAddons){
            this.proposal.selectcareAddons = filteredAddons
        } else {
            this.proposal.selectcareAddons = filteredAddons
        }
        this.proposal.careAddons =  this.carselectedAddons 
        localStorage.setItem('proposal_Proposer',JSON.stringify(this.proposal))
      }
      

    updateCarePremiumWithAddon () {
        const keys = Object.keys(this.selectedAddonMembers);
        let selected_addons = this.careAddon.filter(addon => keys.includes(addon.addon_id));
        if(this.platform === 'careHeartPremium' ) {
            this.carePremiumCalculation.executeHeartApiRequest(selected_addons).subscribe( response =>{
              })
        } else if (this.platform === 'careAdvantagePremium') {
            this.carePremiumCalculation.executeAdvantageApiRequest(selected_addons).subscribe(response =>{

            })

        } else if (this.platform === 'careFreedomPremium') {
            this.carePremiumCalculation.executeFreedomApiRequest(selected_addons).subscribe(response => {
               
            })

        }
      
    }

    createGoDigitPropsal(btnText: string): void {
        if (btnText === 'Next') {
            const tab = localStorage.getItem('tab')
            if (tab === 'I') {
              this.user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  this.user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  this.user = JSON.parse(localStorage.getItem('userData3'));
              }
            this.startLoading();
            this.ProposalCreationForGodigit.createProposal(goDoigitAddons).subscribe(response => {
                this.stopLoading();
                let proposalResponse = response;
                if (proposalResponse.error.errorCode === 200 && proposalResponse.error.errorMessage === null) {
                    const paymentPayload ={
                        "g_id": localStorage.getItem('g_id'),
                        "transaction_id":  localStorage.getItem('digitEnquiryId'),
                        "user_id": localStorage.getItem('getquoteuserID'),
                        'payment_payload':{
                            "proposalNum": proposalResponse.policyNumber,
                            "paymentURL": proposalResponse.paymentLink
                        }
                    }

                    this.savePolicy("digit",{"coverage":proposalResponse.persons[0].coverages.sumInsuredAmount, "premium_amout":parseFloat(proposalResponse.premium.netPremium), "gst" :proposalResponse.premium.igst, "final_premium":proposalResponse.premium.grossPremium }, proposalResponse.policyNumber )
                    this.goDigitProposalResponse = proposalResponse
                    localStorage.setItem('goDigit_proposal',JSON.stringify(proposalResponse))
                    this.commonService.post(this.savePayload, paymentPayload ).subscribe()
    
                    this.modalRef = this.modalService.show(this.goDigitProposalAlert);
                   

                } else {
                    this.toastr.error(`${proposalResponse.error.errorMessage}`,"error")
                }
            }, error => {
                this.stopLoading();
                console.error('API call failed:', error);
            });
        }
    }
    
    formatDateOfBirth(dob: string): string {
        const [year, month, day] = dob.split("-");
        return `${day}-${month}-${year}`;
    }
    
    capitalizeInsuredMemberIDProofNumber(index?:any) {
        if(index === undefined) {
           this.proposal.IDProofNumber =  this.proposal.IDProofNumber.toUpperCase()
        } else {
            this.proposal.insured_member[index].IDProofNumber = this.proposal.insured_member[index].IDProofNumber.toUpperCase();
        }
        
    }

    nomineeDetails () {
        this.showMaternityAddon = this.proposal.insured_member.some(member => member.gender === 'FEMALE');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let premium_collectionData = JSON.parse(localStorage.getItem('iciciPremium_collection'));

            if (premium_collectionData) {
                this.premium_collection.coverage = premium_collectionData.coverage;
                this.showPersonalAccident = (proposal_Proposer.annual_income * 10) > this.premium_collection.coverage
            }
            if(this.proposal.nominee.first_name ===''){
                this.toastr.error("nominee name is required");
                return;
            } else if (this.proposal.nominee.date_of_birth ==='') {
                this.toastr.error("nominee date_of_birth is required");
                return
            } else if (this.proposal.nominee.relationship_with_proposer === '') {
                this.toastr.error("nominee relationship_with_proposer is required");
                return
            } else if(this.isGoDigit === true && this.proposal.nominee.gender === '') {
                this.toastr.error("nominee gender is required");
                return
            }
            localStorage.setItem('proposal_nominee', JSON.stringify(this.proposal.nominee));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));

            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
    }


    digittoggleAddon(addon: any) {
        addon.showMembers = !addon.showMembers;
        if (addon.showMembers) {
          this.addAddon(addon);
        } else {
          this.removeAddon(addon);
        }
      }
    

      addAddon(addon: any) {
      
        
      }
    
    
      removeAddon(addon: any) {
      
       
      }
      monthYearFormatValid(input: string): boolean {
        const yearFormatRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
        return yearFormatRegex.test(input);
    }
      
}

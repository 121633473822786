import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { catchError, finalize, retry } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap, debounceTime } from 'rxjs/operators';
import { environment, goDigitEnviroment } from 'src/environments/environment';
import { constant } from '../constant';
import { CommonService } from '../services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LegalAndAdminPoliciesComponent } from '../legal-and-admin-policies/legal-and-admin-policies.component';
import { AuthService } from '../services/auth.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Options } from 'ng5-slider';
import { CalculatePremiumforHealthNew } from '../services/reliance.service';
import { CalculateCarePremiumforHealth, CheckPremiumAndFamily } from '../services/care.service';
import { CalculatePremiumforHealthIcici } from '../services/icici.service';
import {CalculatePremiumforHealthHDFC} from '../services/hdfcergo.service'
import { CalculateQuotegoDigit } from '../services/goDigit.service'

declare var $: any;


@Component({
  selector: 'app-health-quote',
  templateUrl: './health-quote.component.html',
  styleUrls: ['./health-quote.component.css']
})
export class HealthQuoteComponent implements OnInit {
  HDFC_plan_response: any[] = []; 
  loading = false;
  hdfcTotalPremium = 0;
  careSupremePremium = 0;
  careSupremetitle = '';
  careFreedomPremium = 0;
  careFreedomtitle = '';
  careAdvantagePremium = 0;
  careAdvantagetitle = '';
  careHeartPremium = 0;
  careHearttitle = '';
  premium_collection: any = {

  };
  IciciHealthAdvantagePlusPremium: any = 0;
  goDigitPremium:any =[]
  IciciHealthAdvantagePlustitle: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private http: HttpClient,
    private _location: Location,
    private authService: AuthService,
    private ngxLoader: NgxUiLoaderService,
    private CheckPremiumAndFamily: CheckPremiumAndFamily,
    // reliance
    private relianceHealthService: CalculatePremiumforHealthNew,
    // care
    private careHealthService: CalculateCarePremiumforHealth,
    // icici
    private iciciHealthService: CalculatePremiumforHealthIcici, 
    //HDFC 
    private hdfcPremium: CalculatePremiumforHealthHDFC,
    //goDigit
    private goDigitService: CalculateQuotegoDigit
  ) { }


  ngOnInit(): void {
    const individualFrm = JSON.parse(localStorage.getItem('userData1'));    
    this.startLoading();
    const tab = localStorage.getItem('tab')
    const goDigitPlans = goDigitEnviroment.ProductsCode
    localStorage.removeItem('Hdfc_Policy_payload');
    let careUser, eldestAge
    
    if (tab === 'I') {
      careUser = JSON.parse(localStorage.getItem('userData1'));
      eldestAge = careUser.age
      } else if (tab === 'F') {
        careUser = JSON.parse(localStorage.getItem('userData2'));
        eldestAge =this.careHealthService.eldestMemberage(JSON.parse(localStorage.getItem("userData2Insured")));

      } else {
        careUser = JSON.parse(localStorage.getItem('userData3'));
        eldestAge =this.careHealthService.eldestMemberage(JSON.parse(localStorage.getItem("userData2Insured")));
      }
    let user
    let HDFC_Itration =[]
    let goDigitPlanResponses = []
    this.HDFC_plan_response = []; 
    if (tab === 'I') {
      user = JSON.parse(localStorage.getItem('userData1'));
      } else if (tab === 'F') {
          user = JSON.parse(localStorage.getItem('userData2'));
      } else {
          user = JSON.parse(localStorage.getItem('userData3'));
      }

    if(parseInt(user.coverAmount, 10) === 500000) {
      HDFC_Itration  = ["Optima Secure"]
    } else if(parseInt(user.coverAmount, 10) > 500000 && parseInt(user.coverAmount, 10)<10000000 ) {
      if(parseInt((user.tenure),10) ===3){
        HDFC_Itration  = ["Optima Secure", "Optima Super Secure"]
       }else {
        HDFC_Itration  = ["Optima Secure"]
       }
    } else if(parseInt(user.coverAmount, 10) === 10000000|| parseInt(user.coverAmount, 10) === 20000000){
      if(parseInt((user.tenure),10) ===3){
        HDFC_Itration  =["Optima Secure", "Optima Super Secure","OptimaSecureGlobal","OptimaSecureGlobalPlus"]
       }else {
        HDFC_Itration  = ["Optima Secure","OptimaSecureGlobal","OptimaSecureGlobalPlus"] 
       }
    }

    HDFC_Itration.forEach((planType)=>{
      this.hdfcPremium.calculateHDFCpremium(undefined, undefined, planType.toString()).subscribe(
        (response) => {
          this.stopLoading();
          if (response.Response_Data_OS) {
            const tab = localStorage.getItem('tab')
            let user
            if (tab === 'I') {
              user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  user = JSON.parse(localStorage.getItem('userData3'));
              }
            this.hdfcTotalPremium = parseFloat(response.Response_Data_OS.TotalPremium) || 0;
            const gstComponent1 = parseFloat(response.Response_Data_OS.GST) || 0;
            const coverage = parseFloat(response.Response_Data_OS.SumInsured) || 0;
            const premium_amout = parseFloat(response.Response_Data_OS.NetPremium) || 0;
            const premium_year = parseFloat(user.tenure) || 0;
            this.premium_collection = {
              coverage: coverage,
              premium_year: premium_year,
              premium_amout: premium_amout,
              gst: gstComponent1 ,
              final_premium: this.hdfcTotalPremium,
            };
            let planType_premium = {
              premium_amout:parseFloat(response.Response_Data_OS.TotalPremium) || 0,
              planTypeName: planType
            }
            this.HDFC_plan_response.push(planType_premium)
            localStorage.setItem(`HDFC${planType}`, JSON.stringify(this.premium_collection));
            localStorage.setItem('deductibleAmount','0');
          }
        },
        (error) => {
          console.error('API Error:', error);
          // Handle the error as needed
        }
      );

    })
    
   
    // this.hdfcPremium.calculateHDFCpremium().subscribe(
    //   (response) => {
    //     this.stopLoading();
    //     if (response.Response_Data_OS) {
    //       const tab = localStorage.getItem('tab')
    //       let user
    //       if (tab === 'I') {
    //         user = JSON.parse(localStorage.getItem('userData1'));
    //         } else if (tab === 'F') {
    //             user = JSON.parse(localStorage.getItem('userData2'));
    //         } else {
    //             user = JSON.parse(localStorage.getItem('userData3'));
    //         }
    //       this.hdfcTotalPremium = parseFloat(response.Response_Data_OS.TotalPremium) || 0;
    //       const gstComponent1 = parseFloat(response.Response_Data_OS.GST) || 0;
    //       const coverage = parseFloat(response.Response_Data_OS.SumInsured) || 0;
    //       const premium_amout = parseFloat(response.Response_Data_OS.NetPremium) || 0;
    //       const premium_year = parseFloat(user.tenure) || 0;
    //       this.premium_collection = {
    //         coverage: coverage,
    //         premium_year: premium_year,
    //         premium_amout: premium_amout,
    //         gst: gstComponent1 ,
    //         final_premium: this.hdfcTotalPremium,
    //       };

    //       localStorage.setItem('HDFCPremium_collection', JSON.stringify(this.premium_collection));
    //       localStorage.setItem('deductibleAmount','0');
    //     }
    //   },
    //   (error) => {
    //     console.error('API Error:', error);
    //     // Handle the error as needed
    //   }
    // );
    goDigitPlans.forEach(plan => {
      this.goDigitService.quickQuote(plan.PlanCode).subscribe(response=>{
        let HealthQuickQuote = response["Retail Health-Quick Quote"]
        if (HealthQuickQuote.error.errorCode === 200 && HealthQuickQuote.error.errorMessage === "Success"){
          const tab = localStorage.getItem('tab')
          let user
          if (tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
            } else if (tab === 'F') {
                user = JSON.parse(localStorage.getItem('userData2'));
            } else {
                user = JSON.parse(localStorage.getItem('userData3'));
            }
           
            plan.Premium =response["Retail Health-Quick Quote"].premium.grossPremium
            this.goDigitPremium.push(plan)
          this.premium_collection = {
            coverage: user.coverAmount,
            premium_year: user.tenure,
            premium_amout: HealthQuickQuote.premium.basePremium,
            gst: HealthQuickQuote.premium.totalTax,
            final_premium: HealthQuickQuote.premium.grossPremium,
          };
          localStorage.setItem(plan.PlanName, JSON.stringify(this.premium_collection));
      } else {
          console.error(`Error: Code `);
      }
      })
  })

    // care Supreme
    console.time('fetchData');
    // this.careHealthService.executeSupremeApiRequest().subscribe(
    //   (response) => {
    //     this.stopLoading();
    //     this.careSupremePremium = response.data.grandTotal.selectedValue;
    //     this.careSupremetitle = response.data.abacusData.title;

    //     if (response) {
    //       const basicPremium = parseFloat(response.data.outputFields[0].premium.replace(',', '')) || 0;
    //       const totalPremium = parseFloat(response.data.grandTotal.selectedValue.replace(',', '')) || 0;
    //       const totalTax = (basicPremium * 0.18) || 0;


    //       this.premium_collection = {
    //         coverage: "700000",
    //         premium_year: "1",
    //         premium_amout: Math.round(basicPremium),
    //         gst: Math.round(totalTax),
    //         final_premium: Math.round(totalPremium),
    //         TotalDiscountAmount: Math.round((basicPremium + totalTax) - totalPremium)
    //       };

    //       localStorage.setItem('CareSupremePremium_collection', JSON.stringify(this.premium_collection));
    //     }
    //   },
    //   (error) => {
    //     console.error('API Error:', error);
    //     // Handle the error as needed
    //   }
    // );
    // care Freedom
   
  if( careUser.coverAmount === '1000000') {
    if(eldestAge > 45) {
      this.careHealthService.executeFreedomApiRequest().subscribe(
        (response) => {
          this.stopLoading();
          let carePayload = JSON.parse(localStorage.getItem('carePremiumPayload')) 
          if (response.status === true && response.responseCode===200 && response.responseMsg === "OK") { 
            this.careFreedomPremium = response.data.outputFields[0].premium;
            this.careFreedomtitle = response.data.abacusData.title;
            const tab = localStorage.getItem('tab')
            let user :any 
            if (tab === 'I') {
              user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  user = JSON.parse(localStorage.getItem('userData3'));
              }
            this.premium_collection = {
              coverage: carePayload.SI,
              premium_year: carePayload.Tenure,
              premium_amout: response.data.outputFields[0].basePremium,
              gst: "" ,
              final_premium:this.careFreedomPremium,
            };
  
            localStorage.setItem('CareFreedom', JSON.stringify(this.premium_collection));
  
          }
        },
        (error) => {
          console.error('API Error:', error);
          // Handle the error as needed
        }
      )

    }
  } else {

    this.careHealthService.executeFreedomApiRequest().subscribe(
      (response) => {
        this.stopLoading();
        let carePayload = JSON.parse(localStorage.getItem('carePremiumPayload')) 
        if (response.status === true && response.responseCode===200 && response.responseMsg === "OK") { 
          this.careFreedomPremium = response.data.outputFields[0].premium;
          this.careFreedomtitle = response.data.abacusData.title;
          const tab = localStorage.getItem('tab')
          let user :any 
          if (tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
            } else if (tab === 'F') {
                user = JSON.parse(localStorage.getItem('userData2'));
            } else {
                user = JSON.parse(localStorage.getItem('userData3'));
            }
          this.premium_collection = {
            coverage: carePayload.SI,
            premium_year: carePayload.Tenure,
            premium_amout: response.data.outputFields[0].basePremium,
            gst: "" ,
            final_premium:this.careFreedomPremium,
          };

          localStorage.setItem('CareFreedom', JSON.stringify(this.premium_collection));

        }
        

       
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error as needed
      }
    )

  }
    ;
    // care Advantage
   if( this.CheckPremiumAndFamily.premiumAndFamily()) {
    this.careHealthService.executeAdvantageApiRequest().subscribe(
      (response) => {
        let carePayload = JSON.parse(localStorage.getItem('carePremiumPayload')) 
        this.stopLoading();
        if (response.status === true && response.responseCode===200 && response.responseMsg === "OK") {
          this.careAdvantagePremium = response.data.outputFields[0].premium;
          this.careAdvantagetitle = response.data.abacusData.title;
          const tab = localStorage.getItem('tab')
          let user
          if (tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
            } else if (tab === 'F') {
                user = JSON.parse(localStorage.getItem('userData2'));
            } else {
                user = JSON.parse(localStorage.getItem('userData3'));
            }
         
          this.premium_collection = {
            coverage: carePayload.SI,
            premium_year: carePayload.Tenure,
            premium_amout: response.data.outputFields[0].basePremium,
            gst: "" ,
            final_premium:this.careAdvantagePremium,
          };
          localStorage.setItem('CarAdvantage', JSON.stringify(this.premium_collection));
           
        }
        
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error as needed
      }
    );

   }
    // care Heart
    this.careHealthService.executeHeartApiRequest().subscribe(
      (response) => {
        let carePayload = JSON.parse(localStorage.getItem('carePremiumPayload')) 
        this.stopLoading();
        if (response.status === true && response.responseCode===200 && response.responseMsg === "OK") {
          this.careHeartPremium = response.data.outputFields[0].premium;
          this.careHearttitle = response.data.abacusData.title;
          const tab = localStorage.getItem('tab')
          let user
          if (tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
            } else if (tab === 'F') {
                user = JSON.parse(localStorage.getItem('userData2'));
            } else {
                user = JSON.parse(localStorage.getItem('userData3'));
            }
         
          this.premium_collection = {
            coverage: carePayload.SI,
            premium_year: carePayload.Tenure,
            premium_amout: response.data.outputFields[0].basePremium,
            gst: "" ,
            final_premium: this.careHeartPremium ,
          };

          localStorage.setItem('CarHeart', JSON.stringify(this.premium_collection));

        }
        
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error as needed
      }
    );
    // icici
    this.iciciHealthService.checkpolicyrestrictiondetails().pipe(
      catchError((checkPolicyError) => {
        console.error('checkpolicyrestrictiondetails API Error:', checkPolicyError.message);
        // Handle the error as needed
        this.stopLoading()
        throw checkPolicyError; // Re-throw the error to propagate it to the next error handler
    
      }),
      finalize(() => {
        // Code to run regardless of success or failure (e.g., )
       
      })
    ).subscribe((checkPolicyResponse) => {
      console.log('checkpolicyrestrictiondetails API Response:', checkPolicyResponse);

      if (checkPolicyResponse.status === true && checkPolicyResponse.statusMessage==="Success") {
        this.iciciHealthService.calculatePremium().pipe(
          catchError((calculatePremiumError) => {
            console.error('iciciHealthService API Error:', calculatePremiumError.message);
            // Handle the error as needed
            throw calculatePremiumError; // Re-throw the error to propagate it to the next error handler
          })
        ).subscribe((calculatePremiumResponse) => {
          
          console.log('iciciHealthService API Response:', calculatePremiumResponse);

          this.IciciHealthAdvantagePlusPremium = calculatePremiumResponse.totalPremium;
          this.IciciHealthAdvantagePlustitle = "Health Advantage Plus";

          if (calculatePremiumResponse) {
            const basicPremium = parseFloat(calculatePremiumResponse.basicPremium) || 0;
            const totalPremium = parseFloat(calculatePremiumResponse.totalPremium) || 0;
            const totalTax = parseFloat(calculatePremiumResponse.totalTax) || 0;
            const correlationId = calculatePremiumResponse.correlationId;
            let tab = localStorage.getItem('tab');
            let  user : any
            if (tab === 'I') {
              user = JSON.parse(localStorage.getItem('userData1'));
              } else if (tab === 'F') {
                  user = JSON.parse(localStorage.getItem('userData2'));
              } else {
                  user = JSON.parse(localStorage.getItem('userData3'));
              }
                
            this.premium_collection = {
              coverage: user.coverAmount,
              premium_year: user.tenure,
              premium_amout: Math.round(basicPremium),
              gst: Math.round(totalTax),
              final_premium: Math.round(totalPremium),
              TotalDiscountAmount: null,
              correlationId: correlationId,
              zone: calculatePremiumResponse.zone
            };
            this.stopLoading();

            localStorage.setItem('iciciPremium_collection', JSON.stringify(this.premium_collection));
          }

          // Handle the calculatePremiumResponse as needed
        });
      }
    });

  }



  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  handleButtonClick(premium: string) {
    const tab = localStorage.getItem('tab');
    let user :any;
    if(tab == 'F'){
     user = JSON.parse(localStorage.getItem('userData2'));
    }else{
      user = JSON.parse(localStorage.getItem('userData1'))
    }
    switch (premium) {
      case 'reliancePremium':
        this.router.navigate(['/proposal']);
        localStorage.setItem('platform', premium);
        break;
      case 'careSupremePremium':
        this.router.navigate(['/proposal']);
        localStorage.setItem('platform', premium);
        break;
      case 'iciciPremium':
        this.router.navigate(['/proposal']);
        localStorage.setItem('platform', premium);
        this.savePayload('iciciPremium', localStorage.getItem('uuid'),localStorage.getItem('icici_quote_payload'))
        break;
      case 'hdfcPremium':
        this.router.navigate(['/proposal']);
        localStorage.setItem('platform', premium);
        this.savePayload('HDFCPremium', localStorage.getItem('TransactionId'),localStorage.getItem('hdfc_quote_payload'))
        break;
      case 'careHeartPremium':
        this.router.navigate(['/proposal']);
        this.savePayload(premium, localStorage.getItem('careTransactionId'), this.careHealthService.careHeart)
        localStorage.setItem('platform', premium);
        break;
      case 'careFreedomPremium':
        this.router.navigate(['/proposal']);
        this.savePayload(premium, localStorage.getItem('careTransactionId'), this.careHealthService.careFreedompayload)
        localStorage.setItem('platform', premium);
        break;
      case 'careAdvantagePremium':
        this.router.navigate(['/proposal']);
        this.savePayload( premium, localStorage.getItem('careTransactionId'), this.careHealthService.careAdvantage)
        localStorage.setItem('platform', premium);
        break;

      case 'Optima Secure':
      case 'Optima Super Secure':
      case 'OptimaSecureGlobal':
      case 'OptimaSecureGlobalPlus':
            this.router.navigate(['/proposal']);
            localStorage.setItem('platform', premium);
            this.savePayload('HDFCPremium', localStorage.getItem('TransactionId'), localStorage.getItem('hdfc_quote_payload'));
            break;
      case 'Double Wallet Plan':
      case 'Infinity Wallet Plan':
      case 'World Wide Treatment Plan':
        this.router.navigate(['/proposal']);
        localStorage.setItem('platform', premium);
        this.savePayload('goDigit', localStorage.getItem('digitEnquiryId'),localStorage.getItem('icici_quote_payload'))
        break;
      default:
        
        alert('Work in Progress!')
        break;
    }
    // this.router.navigate(['/proposal']); // Replace 'new-page' with the actual route path
  }

  savePayload( provider: string, transaction_id: string, payload: string ) {
    const savePayloads = '/payloads/addPayloads'
     const payloadData = {
      "g_id": localStorage.getItem('g_id'),
      "transaction_id":transaction_id,
      "user_id": localStorage.getItem('getquoteuserID'),
      'quote_payload': payload,
      'insurance_provider': provider
    }
    this.commonService.post(savePayloads,payloadData).subscribe()

  }
  backClicked() {
    //this._location.back();
    this.router.navigate([`health`]);
}

}

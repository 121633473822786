import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CareEnviroment } from "../../environments/environment";
import { from, Observable, throwError } from "rxjs";
import { CommonService } from './common.service'
import { switchMap } from  'rxjs/operators';
import { catchError } from  'rxjs/operators';
import { heartSumInsure, AdvantageSumInsured, FreedomSumInsured } from "../careconfig/CareSumInsuredCode";
import { CareToken } from "./encryption.Service"

@Injectable({
  providedIn: "root",
})

export class CarePropsalCreation {
    constructor(private http: HttpClient,
        private commonService:CommonService,
        private careToken :CareToken ) {}

      private  async carePayload():Promise<any>  {
        let proposer_details = JSON.parse(localStorage.getItem('proposal_Proposer'));
        let countNumber = await this.careToken.encryptToke(proposer_details.bankAccountNumber).toPromise()
        let ifscCode =  await this.careToken.encryptToke(proposer_details.bankIFSCCode).toPromise()
        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'))
        let kyc_details = JSON.parse(localStorage.getItem('KYC_Verified'))
        let nominee = JSON.parse(localStorage.getItem('proposal_nominee'))
        let  platform = localStorage.getItem('platform')
        let careSI = JSON.parse(localStorage.getItem('carePremiumPayload'))
        let amount = this.convertToLacOrCr(careSI.SI)
        let productSiData :any
        let cover_type: string
        if(  localStorage.getItem('tab')=== 'I'){
            cover_type = "INDIVIDUAL"
        } else {  
            cover_type ="FAMILYFLOATER"
        }
        if(platform === "careFreedomPremium") {
           
            productSiData = FreedomSumInsured.filter(sumInsured => {
               return sumInsured.sumInsuredvalue === amount && sumInsured.coverType === cover_type; });
    
        } else if (platform === "careHeartPremium") {
        
            productSiData = heartSumInsure.filter(sumInsured => {
               return sumInsured.sumInsuredvalue === amount && sumInsured.coverType === cover_type;
              
            });

        } else if (platform ==="careAdvantagePremium") {
            productSiData = AdvantageSumInsured.filter(sumInsured => {
                return sumInsured.sumInsuredvalue === amount && sumInsured.coverType === cover_type;
            });
            
        }
        let insuredMember = []; 
        let addons:string
        let partyContactDOList
        let partyEmailDOList
        let partyIdentityDOList
        let   partyAddressDOList = [
            {
                "addressLine1Lang1": proposer_details.address.house_no,
                "addressLine2Lang1":  proposer_details.address.landmark,
                "addressTypeCd": "PERMANENT",
                "areaCd":  proposer_details.address.area,
                "cityCd":  proposer_details.address.city,
                "stateCd":  proposer_details.address.state,
                "pinCode":  proposer_details.address.pincode
            },
            {
                "addressLine1Lang1": proposer_details.address.house_no,
                "addressLine2Lang1":  proposer_details.address.landmark,
                "addressTypeCd": "COMMUNICATION",
                "areaCd":  proposer_details.address.area,
                "cityCd":  proposer_details.address.city,
                "stateCd":  proposer_details.address.state,
                "pinCode":  proposer_details.address.pincode
            }

        ]
        let guid_for_self = this.generateguid()
        let proposerPed = [
            {
                "questionSetCd": "yesNoExist",
                "questionCd": "pedYesNo",
                "response": "YES"
            },
        ]
        proposal_insured_member.forEach(member=>{
            let userGuiId 
            let  pedQuestions = [
                {
                    "questionSetCd": "yesNoExist",
                    "questionCd": "pedYesNo",
                    "response": "YES"
                }
            ]
            if(platform === "careFreedomPremium") {
                pedQuestions = member.selectedDiseaseIds
                if(member.relationship_with_user === "SELF") {
                    proposerPed = member.selectedDiseaseIds

                } 
            } else {
                if (Array.isArray(member.carediseaseObject)) {
                    member.carediseaseObject.forEach(disease => {
                        const diseasePaylaod = {
                            "questionSetCd": disease.question_set_code,
                            "questionCd": disease.question_code,
                            "response": disease.Response 
                        }
                        if(member.relationship_with_user === "SELF") {
                            proposerPed.push(diseasePaylaod)
                        } 
                        pedQuestions.push(diseasePaylaod)
                        
                    });
                } else {
                    console.error("member.carediseaseObject is either undefined or not an array");
                }
                
            }
            if(member.relationship_with_user === "SELF") {
               userGuiId = guid_for_self
               partyContactDOList = [
                {
                    "contactNum": proposer_details.contactNumber,
                    "contactTypeCd": "MOBILE",
                    "stdCode": "+91"
                }
                    ]
                partyEmailDOList = [
                    {
                        "emailAddress": proposer_details.emailId,
                        "emailTypeCd": "PERSONAL"
                    }
                    ]
                partyIdentityDOList = [
                    {
                        "identityTypeCd": kyc_details.IDProofType,
                        "identityNum": kyc_details.IDProofNumber
                    }
                    ]
                } else {
                userGuiId = this.generateguid()
                partyContactDOList = [
                    {
                        "contactNum": proposer_details.contactNumber,
                        "contactTypeCd": "MOBILE",
                        "stdCode": "+91"
                    }
                        ]
                partyEmailDOList = [
                        {
                            "emailAddress": "",
                            "emailTypeCd": ""
                        }
                ]
                partyIdentityDOList = [
                        {
                            "identityTypeCd": "",
                            "identityNum": ""
                        }
                ]

            }
            let slutation: string
            if(member.gender == 'MALE'){
                slutation = "MR"
            } else{
                slutation = "MS"
            }
            
            let member_data = {
                "birthDt": `${member.date_of_birth.dd}/${member.date_of_birth.mm}/${member.date_of_birth.yy}`,
                "firstName": member.first_name,
                "genderCd": member.gender,
                "guid": userGuiId,
                "lastName": member.last_name,
                "height":this.commonService.heightToCms(member.height.ft, member.height.inch),
                "weight": parseInt(member.weight, 10),
                "partyAddressDOList":partyAddressDOList ,
                "partyContactDOList": partyContactDOList,
                "partyEmailDOList": partyEmailDOList,
                "partyIdentityDOList":partyIdentityDOList ,
                "partyQuestionDOList":pedQuestions && pedQuestions.length > 1 ? pedQuestions : [],
                "relationCd": member.relationship_with_user,
                "roleCd": "PRIMARY",
                "titleCd": slutation
            }
            insuredMember.push(member_data)
        })
        if (proposer_details.careAddons) {
            const jsonStrings = proposer_details.careAddons.map(item => JSON.stringify(item));
            let singleString = jsonStrings.join(',');
            singleString = singleString.replace(/","/g, ',');
            addons = singleString.replace(/^"|"$/g, '');
        }
        const payload_addition_data = {
            "intPolicyDataIO": {
                    "policy": {
                        "businessTypeCd": "NEWBUSINESS",
                        "baseProductId": productSiData[0].ProductID.toString(),
                        "baseAgentId": CareEnviroment.baseAgentId,
                        "coverType": cover_type,
                    "policyAdditionalFieldsDOList": [
                        {
                            "field1": CareEnviroment.PartnerName,
                            "field10": `${nominee.first_name} ${nominee.last_name}`,
                            "field12": `${nominee.relationship_with_proposer}`,
                            "fieldAgree": "YES",
                            "fieldAlerts": "YES",
                            "fieldTc": "YES"
                        }
                    ],
                    "partyDOList" :[],
                    "sumInsured": productSiData[0].sumInsured,
                    "term": careSI.Tenure,
                    "addOns":  addons,
                    "isPremiumCalculation": "YES"
                },
            }
        }
         payload_addition_data.intPolicyDataIO.policy.partyDOList=insuredMember
       let proposerSalutation  
       if(proposer_details.gender == "MALE") {
        proposerSalutation  = "MR"
       } else {
        proposerSalutation  = "MS"
       }
        const proposer =  {
            "birthDt": `${proposer_details.date_of_birth.dd}/${proposer_details.date_of_birth.mm}/${proposer_details.date_of_birth.yy}`,
            "firstName": proposer_details.first_name,
            "genderCd": proposer_details.gender,
            "guid": guid_for_self,
            "lastName": proposer_details.last_name,
            "height":this.commonService.heightToCms(proposer_details.height.ft, proposer_details.height.inch),
            "weight": parseInt(proposer_details.weight, 10),
            "partyAddressDOList": [
                {
                    "addressLine1Lang1": proposer_details.address.house_no,
                    "addressLine2Lang1":  proposer_details.address.landmark,
                    "addressTypeCd": "PERMANENT",
                    "areaCd":  proposer_details.address.area,
                    "cityCd":  proposer_details.address.city,
                    "stateCd":  proposer_details.address.state,
                    "pinCode":  proposer_details.address.pincode
                },
                {
                    "addressLine1Lang1": proposer_details.address.house_no,
                    "addressLine2Lang1":  proposer_details.address.landmark,
                    "addressTypeCd": "COMMUNICATION",
                    "areaCd":  proposer_details.address.area,
                    "cityCd":  proposer_details.address.city,
                    "stateCd":  proposer_details.address.state,
                    "pinCode":  proposer_details.address.pincode
                }
            ],
            "partyContactDOList": [
                {
                    "contactNum": proposer_details.contactNumber,
                    "contactTypeCd": "MOBILE",
                    "stdCode": "+91"
                }
            ],
            "partyEmailDOList": [
                {
                    "emailAddress": proposer_details.emailId,
                    "emailTypeCd": "PERSONAL"
                }
            ],
            "partyIdentityDOList": [
                {
                    "identityTypeCd": kyc_details.IDProofType,
                    "identityNum": kyc_details.IDProofNumber
                }
            ],
            "partyBankDetails": {
                "bankAccountNumber":countNumber.encryptedText,
                 "bankIFSCCode": ifscCode.encryptedText
            },
            "relationCd": "SELF",
            "roleCd": "PROPOSER",
            "titleCd":proposerSalutation,
           "partyQuestionDOList": proposerPed && proposerPed.length > 1 ? proposerPed : [],
            "ckyc":kyc_details.CKYC? "YES": "NO",
            "ckycNumber": kyc_details.CKYC,
            "ovdkyc": ""
        }
        payload_addition_data.intPolicyDataIO.policy.partyDOList.push(proposer)
       
        return payload_addition_data
    }

    createPolicy(): Observable<any> {
        return this.careToken.generateToken(CareEnviroment.PreProposalappId).pipe(
            switchMap((encryptedToken: any) => {
                let headers = new HttpHeaders({
                    appId: CareEnviroment.PreProposalappId,
                    signature: CareEnviroment.Signature,
                    timestamp: Date.now().toString(),
                    applicationCD: CareEnviroment.applicationCD,
                    sessionId: this.careToken.sessionId,
                    tokenId: encryptedToken.encryptedText,
                    "Content-Type": "application/json",
                });
    
                return from(this.carePayload()).pipe(  // Convert Promise to Observable
                    switchMap(payload => {
                        localStorage.setItem("proposal_payload", JSON.stringify(payload));
                        const savePayloads = '/payloads/addPayloads';
                        const payloadData = {
                            "g_id": localStorage.getItem('g_id'),
                            "transaction_id": localStorage.getItem('careTransactionId'),
                            "user_id": localStorage.getItem('getquoteuserID'),
                            'proposal_payload': payload,
                            'insurance_provider': localStorage.getItem('platform')
                        };
    
                        this.commonService.post(savePayloads, payloadData).subscribe();
    
                        return this.http.post(CareEnviroment.careProposalUrl, payload, { headers });
                    })
                );
            }),
            catchError((error) => {
                console.error("Error occurred during create policy:", error);
                return throwError(error);
            })
        );
    }
    

    private generateguid(): string {
        const prefix = "PMALL";
        const randomNumber = Math.floor(1000000 + Math.random() * 9000000); // Generates a random number between 1000000 and 9999999
        return `${prefix}${randomNumber}`;
    }

    private convertToLacOrCr(siValue: string): string {
        const siNumber = parseInt(siValue, 10);
    
        if (siNumber >= 1_00_00_000) {
          return (siNumber / 1_00_00_000)+ 'Cr';
        } else if (siNumber >= 1_00_000) {
          return (siNumber / 1_00_000)+'L';
        } else {
          return siNumber.toString();
        }
      }
    
}





// authentication.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { state } from '@angular/animations';
import { stringify } from 'querystring';
import { master_pincode } from '../icici_constant/PROD_PINCODE_MASTER';
import { environment } from './../../environments/environment';
import { CommonService } from './common.service';
import { constant } from "../constant"

// Define an interface for kycEncryptData
interface KycEncryptData {
  encryptedData: any;
  pan: string;
  date: string;
}

@Injectable({
  providedIn: 'root',
})
export class CalculatePremiumforHealthIcici {
  individual: any = {
    type: 'I',
    gender: '1',
    age: '',
    dob: '',
    adult_number: 1,
    policyTenure: 1,
    child_number: 0,
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    coverAmount: '',
    tenure: '',
    ped: '0',
    RelationshipWithApplicant:'SELF',
    pincode: '',
    diseaseSelection: [
      {
        'DiseaseID': '14',
        'SufferingSince': '29/12/2018'
      }
    ]
  };

  familyData: any = {
    type: 'F',
    gender: '1',
    DOB: '',
    coverAmount: '',
    adult_number: 1,
    child_number: 0,
    pincode: '',
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    ped: '0',
    tenure: ''
  };
  productCode: number;
  SumInsured: string;
  pincode: string;
  planCode: number;


  familyMembers: any = [
    { relationship: 'FATHER', dateOfBirth: '1979-05-21' },
    { relationship: 'MOTHER', dateOfBirth: '1981-04-10' },
  ]

  private tokenEndpoint = environment.iciciApis.tokenEndpoint;
  private calculatePremiumEndpoint = environment.iciciApis.calculatePremiumEndpoint;
  private checkpolicyrestrictiondetailsEndpoint = environment.iciciApis.checkpolicyrestrictiondetailsEndpoint; // Replace with your actual endpoint
  private getzoneendpoint = environment.iciciApis.getzoneendpoint;
  private proposalendpoint = environment.iciciApis.proposalendpoint;
  private savePayloads = '/payloads/addPayloads'

  constructor(private http: HttpClient,
    private commonService: CommonService ) { }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private authenticateCheckpolicyrestrictiondetails(): Observable<string> {

    const individualFrm = JSON.parse(localStorage.getItem('userData1'));
    const familyFrm = JSON.parse(localStorage.getItem('userData2'))
    if (individualFrm) {
      this.individual = individualFrm
      this.planCode = this.getplancode(this.individual.adult_number, this.individual.child_number, this.individual.tenure).code
      this.pincode = this.individual.pincode
      this.SumInsured = this.individual.coverAmount

    }
    if (familyFrm) {
      this.familyData = familyFrm
      this.planCode = this.getplancode(this.familyData.adult_number, parseInt(this.familyData.child_number), this.familyData.tenure).code
      this.pincode = this.familyData.pincode
      this.SumInsured = this.familyData.coverAmount

    }

    const clientId = environment.user_details.clientId;
    const clientSecret = environment.user_details.clientSecret;
    const username = environment.user_details.username;
    const password = environment.user_details.password;
    const scope = 'esbpolicyrestriction';
    const grantType = 'password';

    const requestData = new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
      scope: scope,
      grant_type: grantType
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(this.tokenEndpoint, requestData.toString(), {
      headers: headers,
    }).pipe(
      map(data => data.access_token)
    );
  }


  checkpolicyrestrictiondetails(): Observable<any> {
    return this.authenticateCheckpolicyrestrictiondetails().pipe(
      // Use the access token to make a request to the premium calculation endpoint
      switchMap((accessToken: string) => {
        //console.log(accessToken);

        const headers = new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        });

        // let generatedUUID = localStorage.getItem('uuid');
        // if (!generatedUUID) {
          let generatedUUID = this.generateUUID();
          localStorage.setItem('uuid', generatedUUID);
        // } ""
        // Adjust the payload and URL according to your premium calculation API
        const payload = {
          "ProductCode": environment.commonFixedData.Restriction_Product_code,
          "SubProductCode": environment.commonFixedData.SubProductCode,
          "TransactionType": environment.commonFixedData.TransactionType,
          "SumInsured": this.SumInsured,
          "PinCode": this.pincode,
          "CorrelationId": generatedUUID
        }
        return this.http.post<any>(this.checkpolicyrestrictiondetailsEndpoint, payload, { headers });
      })
    );
  }

  private authenticate(): Observable<string> {
    const clientId = environment.user_details.clientId;
    const clientSecret = environment.user_details.clientSecret;
    const username = environment.user_details.username;
    const password = environment.user_details.password;
    const scope = 'esbhealth';
    const grantType = 'password';

    const requestData = new URLSearchParams({
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
      scope: scope,
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(this.tokenEndpoint, requestData.toString(), {
      headers: headers,
    }).pipe(
      map(data => data.access_token)
    );
  }

  calculatePremium(storedPayload?: any[]): Observable<any> {
    return this.authenticate().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        });

        let getuuid = localStorage.getItem('uuid');
        if (!getuuid) {
          let generatedUUID = this.generateUUID();
          localStorage.setItem('uuid', generatedUUID);
          getuuid = generatedUUID;
        }


        return this.getPincode(this.pincode).pipe(
          switchMap((pincodeResult) => {
            const districtUpperCase = pincodeResult[0] && pincodeResult[0].PostOffice && pincodeResult[0].PostOffice[0] && pincodeResult[0].PostOffice[0].District
              ? pincodeResult[0].PostOffice[0].District.toUpperCase()
              : null;
            const state = pincodeResult[0] && pincodeResult[0].PostOffice && pincodeResult[0].PostOffice[0]
              ? pincodeResult[0].PostOffice[0].Circle.toUpperCase()
              : null;

              const payloadForZone = {
                "ProductCode": environment.commonFixedData.Zone_ProductCode,
                "State": state,
                "City":districtUpperCase,
                "CorrelationId": getuuid,
                "ZoneRefiling": "Yes"
              };
  
        return this.http.post<any>(this.getzoneendpoint, payloadForZone, { headers }).pipe(
          switchMap((zoneResult) => {
            const mappedZone= zoneResult.zone;
                const payload = storedPayload || this.createPayload(districtUpperCase, getuuid, state, mappedZone);           
                return this.http.post<any>(this.calculatePremiumEndpoint, payload, { headers });
              })
            );
          })
        );
      })
    );
  }

  // Payload for all 
  private createPayload(districtUpperCase: string, getuuid: string, State: string, zoneResult: string): any {
    const policyStartDate = this.calculatePolicyStartDate();
    const policyEndDate = this.calculatePolicyEndDate(policyStartDate, this.individual.policyTenure || 1);
    let insuredDetails: any[] = [];
    let eldestMemberAge: number;
    let noOfAdults: number;
    let noOfChildren: number;
    let coverAmount: string;
    let tenure: number;
    const insuranceType = localStorage.getItem('tab');
    if (insuranceType == 'I') {
      eldestMemberAge = this.individual.age
      noOfAdults = 1
      noOfChildren = 0
      // this.planCode = this.plancode(1,0,1)
      tenure = this.individual.tenure
      coverAmount = this.individual.coverAmount
      insuredDetails.push({
        DateOfBirth: this.individual.dob,
        RelationshipWithApplicant: "SELF",

      });
    }
    else {
      const totalMember = JSON.parse(localStorage.getItem("userData2Insured"));

      let maxAge = 0;
      totalMember[0].form.forEach(member => {
        const today = new Date();
        const birthDate = new Date(member.DateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age > maxAge) {
          maxAge = age;

        }
      });
      eldestMemberAge = maxAge
      noOfAdults = this.familyData.adult_number
      noOfChildren = this.familyData.child_number
      // this.planCode = this.plancode(1,1,1)
      coverAmount = this.familyData.coverAmount
      tenure = this.familyData.tenure
      totalMember[0].form.forEach(member => {
        insuredDetails.push({
          DateOfBirth: member.DateOfBirth,
          RelationshipWithApplicant: member.relation,
        });
      });

    }

    const icici_quote_payload = {
      "PlanCode": this.planCode,
      "PolicyEndDate": policyEndDate,
      "PolicyStartDate": policyStartDate,
      "DealId": environment.commonFixedData.DealId,
      "SubProductCode": environment.commonFixedData.SubProductCode,
      "SumInsured": coverAmount,
      "NoOfAdult": noOfAdults,
      "NoOfChildren": noOfChildren,
      "AgeOfEldestMember": eldestMemberAge,
      "GSTToState": State,
      "Tenure": tenure,
      "InsuredDetails": insuredDetails,
      "CorrelationId": getuuid,
      "IsGoldenShield": true,
      "VoluntaryCopaymentPercentage": 0.0,
      "PinCode": this.pincode,
      "City": districtUpperCase,
      "IsHealthAdvantage": true,
      "ZoneUpgradation": "No",
      "PreHospitalization": "60 Days",
      "PostHospitalization": "180 Days",
      "PEDWaitingPeriod": "2 Years",
      "SpecificConditionWaiting": "2 Years",
      "ProposalAnnualIncome": null,
      "RevisedZone": zoneResult,
      "ZoneRefiling": "YES",
      "BusinessType": "New Business",

    };

    localStorage.setItem('icici_quote_payload', JSON.stringify(icici_quote_payload));

    return icici_quote_payload;
  }

  createProposal(updatedAddons: any): Observable<any> {
    return this.authenticate().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        });
        const payload = this.proposalPayload(updatedAddons)
        console.log("wew are here",  payload.CorrelationId)
        const proposalPayload ={
          "g_id": localStorage.getItem('g_id'),
          "transaction_id": payload.CorrelationId,
          "user_id": localStorage.getItem('getquoteuserID'),
          'proposal_payload':payload
        }
        this.commonService.post(this.savePayloads, proposalPayload).subscribe(prop=>{
          console.log("proposal pay",prop)
        })
        localStorage.setItem('proposal_payload',JSON.stringify(payload))
        return this.http.post(this.proposalendpoint, payload , { headers });
      })
    );
  }

  private proposalPayload(updatedAddons: any) {
    let kyc_response = JSON.parse(localStorage.getItem('KYC_Verified'))
    const proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
    let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
    let premium_collection = JSON.parse(localStorage.getItem('iciciPremium_collection'));
    let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
    let insuredMember = [];

    proposal_insured_member.forEach(member => {
      let dateOfBirth = `${member.date_of_birth.yy}-${member.date_of_birth.mm}-${member.date_of_birth.dd}`;
      let age = this.calculateAge(dateOfBirth);
      let pedList: string
      if (member.selectedDiseaseIds && member.selectedDiseaseIds.length > 0) {
        pedList = member.selectedDiseaseIds.join(",");
      } else {
        pedList = null;
      }

      let processedMember = {
        "InsuredName": `${member.first_name} ${member.middle_name} ${member.last_name}`,
        "RelationshipWithApplicant": member.relationship_with_user,
        "Gender": member.gender,
        "DateOfBirth": dateOfBirth,
        "Ageinyears": age,
        "Weight": member.weight,
        "HeightsInFeets": member.height.ft,
        "HeightsInInches": member.height.inch,
        "CoverGroups": "Person",
        "Applicable": true,
        "Aabha": null,

        "AddOn78": false,
        "AddOn79": false,
        "AddOn80": false,
        "AddOn81": false,
        "AddOn82": false,
        "AddOn83": false,
        "AddOn84": false,
        "AddOn85": false,
        "AddOn86": false,
        "AddOn87": false,
        "AddOn88": false,
        "AddOn89": false,
        "AddOn90": false,
        "AddOn91": false,
        "AddOn92": false,
        "AddOn93": false,
        "AddOn94": false,
        "InitialWaitingperiodCI": null,

        "PEDIllness": pedList,
        "PreviousInsurerDetails": null,
        "PortabilityWaiver": null,
        "OccupationOfInsured":member.occupation,
        "PortabilityDOJ": "",
        "InsuredLoading": 0.0
      };

      for (const addonId in updatedAddons) {
        if (updatedAddons.hasOwnProperty(addonId)) {
          const addon = updatedAddons[addonId];
          if (addon.members.filter(m => m.first_name === member.first_name && m.last_name === member.last_name && m.date_of_birth === member.date_of_birth)) {
            processedMember[`AddOn${addonId}`] = true;
            if (addonId == "78" ){
              processedMember.InitialWaitingperiodCI = "90";
          }

          }
        }
      }

      insuredMember.push(processedMember);
    });
    let plan: any;
    let PANCardNo = ""
    let AADHAARNo = ""
    if (kyc_response.IDProofType === 'PAN') {
      PANCardNo = kyc_response.IDProofNumber
    } else if (kyc_response.IDProofType === "AADHAAR") {
      AADHAARNo = kyc_response.IDProofNumber
    }
    const policyStartDate = this.calculatePolicyStartDate();

    const policyEndDate = this.calculatePolicyEndDate(policyStartDate, premium_collection.premium_year || 1);

    const insuranceType = localStorage.getItem('tab');

    if (insuranceType === 'I') {
      const individualFrm = JSON.parse(localStorage.getItem('userData1'));
      plan = this.getplancode(individualFrm.adult_number, 0, premium_collection.premium_year);
    } else {
      const familyFrm = JSON.parse(localStorage.getItem('userData2'))
      plan = this.getplancode(familyFrm.adult_number, familyFrm.child_number, premium_collection.premium_year);
    }
    const city_code_json = master_pincode.filter(master_pin => proposal_Proposer.address.pincode == master_pin.NUM_PINCODE)
    let zone = premium_collection.zone
    const currentDate = new Date().toISOString();
    localStorage.setItem('proposalDate', JSON.stringify(currentDate));
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const applicationNo = `APP_TPM_${timestamp}`

    return {
      "CorrelationId": premium_collection.correlationId,
      "BusinessType": "New Business",
      "DealId": environment.commonFixedData.DealId,
      "PolicyStartDate": policyStartDate,
      "PolicyEndDate": policyEndDate,
      "SubProductCode": environment.commonFixedData.SubProductCode,
      "SubProductName": environment.commonFixedData.SubProductName,
      "ChannelCode": environment.commonFixedData.ChannelCode,
      "ChannelName": environment.commonFixedData.ChannelName,
      "ApplicationNo": applicationNo,
      "AutoRenewal": "No",
      "TransactionType": "NON HUF",
      "InwardDate": currentDate,
      "PaymentMode": "Other",
      "EcsMandate": "No",
      "Tenure": premium_collection.premium_year,
      "BOPSReceivedDate": currentDate,
      "PremiumReceivedFromCustomer": premium_collection.final_premium,
      'NomineeName': `${proposal_nominee.first_name} ${proposal_nominee.last_name}`,
      "NomineeRelationshipWithInsured": proposal_nominee.relation_value,
      "NomineeDOB": proposal_nominee.date_of_birth,
      "VoluntaryDeductibleSI": 0.0,
      "AppointeeName": proposal_Proposer.appointee.appointeeName,
      "AppointeeDOB":  proposal_Proposer.appointee.date_of_birth,
      "AppointeeRelationship": proposal_Proposer.appointee.appointeeRelationship,
      "CreditCardSavingAccountNumber": "null",
      "BankNameforCreditCard": "null",
      "CreditCardExpiryDate": "null",
      "AuthDate": null,
      "AuthCode": "",
      "Authorized": "null",
      "RewardPoint": 0.0,
      "IsHealthAdvantage": true,
      "VoluntaryCopay": null,
      "PreHospitalization": "60 Days",
      "PostHospitalization": "180 Days",
      "SpecificConditionWaiting": "2 Years",
      "ProposalAnnualIncome": proposal_Proposer.annual_income,
      "ZoneUpgradation": null,
      "RevisedZone": zone.replace("_", " "),
      "ZoneRefiling": "Yes",
      "ProposerDOB": `${proposal_Proposer.date_of_birth.yy}-${proposal_Proposer.date_of_birth.mm}-${proposal_Proposer.date_of_birth.dd}`,
      "ReasonofPortability": null,
      "CustomerDetails": {
        "CustomerName": `${proposal_Proposer.first_name} ${proposal_Proposer.middle_name} ${proposal_Proposer.last_name}`,
        "DateOfBirth": `${proposal_Proposer.date_of_birth.yy}-${proposal_Proposer.date_of_birth.mm}-${proposal_Proposer.date_of_birth.dd}`,
        "PinCode": proposal_Proposer.address.pincode,
        "PANCardNo": PANCardNo,
        "CustomerType": "Individual",
        "Email": proposal_Proposer.emailId,
        "MobileNumber": proposal_Proposer.contactNumber,
        "AddressLine1": `${proposal_Proposer.address.house_no}, ${proposal_Proposer.address.landmark},${proposal_Proposer.address.area}`,
        "CustomerID": null,
        "CKYCId": kyc_response.ckyc_number,
        "EKYCid": null,
        "PEPFlag": false,
        "ILKYCReferenceNumber": kyc_response.il_kyc_ref_no,
        "GSTDetails": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": "null",
        "CountryCode": city_code_json[0].NUM_COUNTRY_CD,
        "StateCode": city_code_json[0].NUM_STATE_CD,
        "CityCode": city_code_json[0].NUM_CITYDISTRICT_CD,
        "Gender": proposal_Proposer.gender,
        "MobileISD": "91",
        "AadharNumber": AADHAARNo
      },
      "PlanDetails": [
        {
          "Premium": premium_collection.premium_amout,
          "SumInsured": premium_collection.coverage,
          "Plan": "Plan",
          "PlanCode": plan.code,
          "PlanName": plan.name,
          "PortabilitySumInsured": 0.0,
          "InsuredDetails": insuredMember
        }
      ],
      "PreviousPolicyDetails": null,
      "LoadingDiscount": null,
      "PEDWaitingPeriod": "2 Years"
    }
  }


  static calculateBirthdate(age: number): string {
    const currentDate = new Date();
    const birthYear = currentDate.getFullYear() - age;

    // Adjusting the birthdate format to 'DD/MM/YYYY'
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = birthYear.toString();

    return `${year}-${month}-${day}`;
  }

  calculateAgebyDobObject(date_of_birth: { dd: string; mm: string; yy: string }): number {
    const today = new Date();
    const birthDate = new Date(parseInt(date_of_birth.yy), parseInt(date_of_birth.mm) - 1, parseInt(date_of_birth.dd));
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // Modify the object to create a date string
  static formatDateOfBirth(dateOfBirthObj) {
    let day = dateOfBirthObj.dd;
    let month = dateOfBirthObj.mm;
    let year = dateOfBirthObj.yy;
    // Ensure month and day are two digits
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return `${year}-${month}-${day}`;
  }

  private calculatePolicyStartDate(): string {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = nextDate.getDate().toString().padStart(2, '0');
    const month = (nextDate.getMonth() + 1).toString().padStart(2, '0');
    const year = nextDate.getFullYear();

    return `${year}-${month}-${day}`;
  }

  private calculateAge(dateOfBirth) { 
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  private calculatePolicyEndDate(startDate: string, tenure: number): string {
    const tenureInt = typeof tenure === 'string' ? parseInt(tenure, 10) : tenure;
    const [year, day, month] = startDate.split('-').map(Number);
    // Create start date object (month is 0-indexed in JavaScript Date)
    const startDateObj = new Date(year, month - 1, day);

    // Create end date object by adding tenure years
    const endDateObj = new Date(startDateObj);
    endDateObj.setFullYear(startDateObj.getFullYear() + tenureInt);

    // Subtract one day from the end date to get the correct policy end date
    endDateObj.setDate(endDateObj.getDate() - 1);

    // Handle the special case of February 29 in a leap year
    if (startDateObj.getMonth() === 1 && startDateObj.getDate() === 29 && endDateObj.getMonth() !== 1) {
      endDateObj.setDate(28);
    }

    // Format end date parts
    const endDay = endDateObj.getDate().toString().padStart(2, '0');
    const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0');
    const endYear = endDateObj.getFullYear().toString();

    return `${endYear}-${endMonth}-${endDay}`;
  }

  private getPincode(pincode: string): Observable<any> {
    const url = `https://api.postalpincode.in/pincode/${pincode}`;

    //console.log(url);

    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  private handleError(error: any): Observable<any> {
    console.error('An error occurred:', error);
    return throwError('Something went wrong. Please try again later.');
  }
  private getplancode(adult: number, child: number, year: number) {
    const planCodes = {
      "1A+1K_1Y": { code: 13531, name: "Apex_Plus_R_1A+1K_1_Year" },
      "1A+2K_1Y": { code: 13532, name: "Apex_Plus_R_1A+2K_1_Year" },
      "2A_1Y": { code: 13535, name: "Apex_Plus_R_2A_1_Year" },
      "2A+3K_1Y": { code: 13540, name: "Apex_Plus_R_2A+3K_1_Year" },
      "3A+2K_1Y": { code: 13545, name: "Apex_Plus_R_3A+2K_1_Year" },
      "2A+2K_1Y": { code: 13539, name: "Apex_Plus_R_2A+2K_1_Year" },
      "5A+1K_3Y": { code: 13546, name: "Apex_Plus_R_5A+1K_3_Year" },
      "1A+3K_1Y": { code: 13745, name: "Apex_Plus_R_1A+3K_1_Year" },
      "4A+1K_1Y": { code: 13548, name: "Apex_Plus_R_4A+1K_1_Year" },
      "5A+2K_1Y": { code: 13553, name: "Apex_Plus_R_5A+2K_1_Year" },
      "4A_1Y": { code: 13547, name: "Apex_Plus_R_4A_1_Year" },
      "6A+1K_1Y": { code: 13558, name: "Apex_Plus_R_6A+1K_1_Year" },
      "4A+3K_1Y": { code: 13603, name: "Apex_Plus_R_4A+3K_1_Year" },
      "2A+1K_1Y": { code: 13537, name: "Apex_Plus_R_2A+1K_1_Year" },
      "6A_1Y": { code: 13555, name: "Apex_Plus_R_6A_1_Year" },
      "1K_1Y": { code: 13529, name: "Apex_Plus_R_1K_1_Year" },
      "1A_1Y": { code: 13530, name: "Apex_Plus_R_1A_1_Year" },
      "3A+1K_1Y": { code: 13543, name: "Apex_Plus_R_3A+1K_1_Year" },
      "5A_1Y": { code: 13550, name: "Apex_Plus_R_5A_1_Year" },
      "2A+2K_3Y": { code: 13573, name: "Apex_Plus_R_2A+2K_3_Year" },
      "2A+3K_3Y": { code: 13564, name: "Apex_Plus_R_2A+3K_3_Year" },
      "3A_3Y": { code: 13562, name: "Apex_Plus_R_3A_3_Year" },
      "1A+3K_3Y": { code: 13747, name: "Apex_Plus_R_1A+3K_3_Year" },
      "3A+2K_3Y": { code: 13557, name: "Apex_Plus_R_3A+2K_3_Year" },
      "4A+1K_3Y": { code: 13552, name: "Apex_Plus_R_4A+1K_3_Year" },
      "3A+1K_3Y": { code: 13560, name: "Apex_Plus_R_3A+1K_3_Year" },
      "4A_3Y": { code: 13554, name: "Apex_Plus_R_4A_3_Year" },
      "5A_3Y": { code: 13549, name: "Apex_Plus_R_5A_3_Year" },
      "6A_3Y": { code: 13538, name: "Apex_Plus_R_6A_3_Year" },
      "1A_3Y": { code: 13587, name: "Apex_Plus_R_1A_3_Year" },
      "1K_3Y": { code: 13585, name: "Apex_Plus_R_1K_3_Year" },
      "1A+2K_3Y": { code: 13584, name: "Apex_Plus_R_1A+2K_3_Year" },
      "1A+1K_3Y": { code: 13586, name: "Apex_Plus_R_1A+1K_3_Year" },
      "2A_3Y": { code: 13582, name: "Apex_Plus_R_2A_3_Year" },
      "2A+1K_3Y": { code: 13579, name: "Apex_Plus_R_2A+1K_3_Year" },
      "5A+2K_3Y": { code: 13541, name: "Apex_Plus_R_5A+2K_3_Year" },
      "6A+1K_3Y": { code: 13536, name: "Apex_Plus_R_6A+1K_3_Year" },
      "3A_2Y": { code: 13571, name: "Apex_Plus_R_3A_2_Year" },
      "2A+3K_2Y": { code: 13570, name: "Apex_Plus_R_2A+3K_2_Year" },
      "2A+1K_2Y": { code: 13568, name: "Apex_Plus_R_2A+1K_2_Year" },
      "1A+2K_2Y": { code: 13565, name: "Apex_Plus_R_1A+2K_2_Year" },
      "1A+1K_2Y": { code: 13563, name: "Apex_Plus_R_1A+1K_2_Year" },
      "1A_2Y": { code: 13561, name: "Apex_Plus_R_1A_2_Year" },
      "2A_2Y": { code: 13588, name: "Apex_Plus_R_2A_2_Year" },
      "2A+2K_2Y": { code: 13589, name: "Apex_Plus_R_2A+2K_2_Year" },
      "1K_2Y": { code: 13559, name: "Apex_Plus_R_1K_2_Year" },
      "3A+2K_2Y": { code: 13591, name: "Apex_Plus_R_3A+2K_2_Year" },
      "3A+1K_2Y": { code: 13590, name: "Apex_Plus_R_3A+1K_2_Year" },
      "6A_2Y": { code: 13581, name: "Apex_Plus_R_6A_2_Year" },
      "6A+1K_2Y": { code: 13580, name: "Apex_Plus_R_6A+1K_2_Year" },
      "5A+2K_2Y": { code: 13578, name: "Apex_Plus_R_5A+2K_2_Year" },
      "5A+1K_2Y": { code: 13577, name: "Apex_Plus_R_5A+1K_2_Year" },
      "5A_2Y": { code: 13576, name: "Apex_Plus_R_5A_2_Year" },
      "4A+1K_2Y": { code: 13575, name: "Apex_Plus_R_4A+1K_2_Year" },
      "4A_2Y": { code: 13574, name: "Apex_Plus_R_4A_2_Year" },
      "4A+2K_2Y": { code: 13605, name: "Apex_Plus_R_4A+2K_2_Year" },
      "1A+3K_2Y": { code: 13746, name: "Apex_Plus_R_1A+3K_2_Year" },
      "3A_1Y": { code: 13542, name: "Apex_Plus_R_3A_1_Year" }
    };

    // Create the key
    let key = `${adult}A${child > 0 ? `+${child}K` : ''}_${year}Y`;

    if (planCodes[key]) {
      return planCodes[key];
    } else {
      return { code: null, name: "Plan not found" };
    }
  }
}
@Injectable({
  providedIn: 'root',
})


// ICICI KYC SERVICE 
export class CkycServiceIcici {

  private tokenEndpoint = environment.iciciApis.kyctokenEndpoint;
  private apiUrl = environment.iciciApis.kycapiUrl;
  private icici_kyc_encrypt = environment.iciciApis.icici_kyc_encrypt_data;
  private baseUrl = constant.api_endpoint


  constructor(private http: HttpClient,
    private commonService: CommonService
    ) { }


  private authenticate(): Observable<string> {
    const clientId = environment.user_details.clientId;
    const clientSecret = environment.user_details.kycclientSecret;
    const username = environment.user_details.username;
    const password = environment.user_details.password;
    const scope = 'esb-kyc';
    const grantType = 'password';

    const requestData = new URLSearchParams({
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
      scope: scope,
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(this.tokenEndpoint, requestData.toString(), {
      headers: headers,
    }).pipe(
      map(data => data.access_token)
    );
  }

  private kycEncrypt(data: any, accessToken: any): Observable<KycEncryptData> {


    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    });

    return this.http.post<KycEncryptData>(this.baseUrl+this.icici_kyc_encrypt, data, {
      headers: headers,
    });
  }


  getKYCVerified(data: any): Observable<any> {
    let premium_collection = JSON.parse(localStorage.getItem('iciciPremium_collection'));
    const savePayload = '/payloads/addPayloads'
    const kycPayload ={
      "g_id": localStorage.getItem('g_id'),
      "transaction_id":  premium_collection.correlationId,
      "user_id": localStorage.getItem('getquoteuserID'),
      'kyc_payload':data
    }
    this.commonService.post(savePayload, kycPayload).subscribe()
    return this.authenticate().pipe(
      switchMap((accessToken: string) => {
        if(data.method == 'upload'){
          const formData = new FormData()
          formData.append('mobile_number', data.mobile);
          formData.append('email', data.email);
          formData.append('is_poa_poi_same', data.is_poa_poi_same);
          formData.append('poi[0].certificate_type', data.POI);
          formData.append('poi[0].document', data.poiFile);
          if (data.poaFile && data.POA) {
            formData.append('poa[0].certificate_type',data.POA);
            formData.append('poa[0].document', data.poaFile);

          }
          formData.append('CorrelationId', premium_collection.correlationId);
          formData.append('customer_type', 'I');
          const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
          });

         return this.http.post<any>(`${this.apiUrl+'ovd'}`, formData, { headers }).pipe(
          catchError((error) => this.handleError(error))
         )

        }
        if(data.method == 'online'){
          const unencrypted: any = {};
          const rowData: any = {};
          if (data.certificate_type === 'PAN') {
            rowData.pan = data.pan_details.pan;
            rowData.dob = this.formatDate(data.pan_details.dob);
  
          } else if (data.certificate_type === 'AADHAAR') {
            rowData.aadhaar_number = data.aadhaar_details.aadhaar_number;
            const originalDate = data.aadhaar_details.dob;
            const [year, month, day] = originalDate.split('-');
            rowData.dob = `${day}-${month}-${year}`;
            unencrypted.full_name = data.aadhaar_details.name_on_aadhaar;
            unencrypted.gender = data.aadhaar_details.gender;
          } else if (data.certificate_type === 'CIN') {
            rowData.cin_number = data.cin_details.cin_number;
            rowData.date_of_incorporation = this.formatDate(data.cin_details.date_of_incorporation);
          } else if (data.certificate_type === "DL") {
            rowData.dl_number = data.other_id_details.id_number;
            rowData.dob = this.formatDate(data.other_id_details.dob);
          } else if (data.certificate_type === "PASSPORT") {
            rowData.file_number = data.other_id_details.id_number;
            rowData.dob = this.formatDate(data.other_id_details.dob);
  
          } else if (data.certificate_type === "VOTERID") {
            rowData.epic_number = data.other_id_details.id_number;
            rowData.dob = this.formatDate(data.other_id_details.dob);
          } else if (data.certificate_type === "CKYC") {
            rowData.ckyc_number = data.other_id_details.id_number;
            rowData.dob = this.formatDate(data.other_id_details.dob);
          }
          else {
            rowData.id_number = data.other_id_details.id_number;
            rowData.dob = this.formatDate(data.other_id_details.dob);;
          }
          const sensitiveData = { sensitiveData: rowData };
          return this.kycEncrypt(sensitiveData, accessToken).pipe(
            switchMap((kycEncryptData: KycEncryptData) => { // Fix: Use switchMap instead of map
              const headers = new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              });
  
              const storageString = localStorage.getItem("iciciPremium_collection");
  
              if (storageString) {
                try {
                  const storage = JSON.parse(storageString);
                  const certificateType = data.certificate_type;
                  const detailsKey = certificateType === 'VOTERID' ? 'epic_details' : certificateType.toLowerCase() + '_details';
  
                  const collectData = {
                    correlationId: storage.correlationId,
                    certificate_type: data.certificate_type,
                    pep_flag: false,
                    [detailsKey]: {
                      ...kycEncryptData.encryptedData,
                      ...unencrypted
                    }
                  };
  
                  return this.http.post<any>(`${this.apiUrl+'initiate'}`, collectData, { headers }).pipe(
                    catchError((error) => this.handleError(error))
                  );
                } catch (error) {
                  return throwError("Error parsing JSON");
                }
              } else {
                console.error("No data found in localStorage with the key 'iciciPremium_collection'");
                return throwError("No data found in localStorage");
              }
            })
          );
        }
       
      })
    );
  }



  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error.message);
    return throwError(error.message || 'Server error');
  }


  private formatDate(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }


}


@Injectable({
  providedIn: 'root',
})
export class PaymentGateway {
  private transactionUrl = environment.paymentApis.transactionUrl
  private paymentMethodUrl = environment.paymentApis.paymentMethodUrl
  private tokenUrl = environment.paymentApis.tokenUrl;
  private paymentVerificatioUrl = environment.paymentApis.paymentVerication
  private baseUrl = constant.api_endpoint


  constructor(private http: HttpClient,
    private commonService: CommonService) { }
  createTransaction(): void {
    let  premium_collection = JSON.parse(localStorage.getItem('iciciPremium_collection'));
    const transactionData = {
      'TransactionId': this.generateTransactionUUID(),
      'Amount':premium_collection.final_premium,
      'ApplicationId': environment.commonFixedData.ApplicationId,
      'ReturnUrl': this.baseUrl+environment.paymentApis.returnUrl
    };
    const savePayload = '/payloads/addPayloads'
    const paymentPayload ={
      "g_id": localStorage.getItem('g_id'),
      "transaction_id":  premium_collection.correlationId,
      "user_id": localStorage.getItem('getquoteuserID'),
      'payment_payload':transactionData
    }
    this.commonService.post(savePayload, paymentPayload).subscribe()

    this.getAuthToken().subscribe(token => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${token}`
      });

      // Make API call
      this.http.post<any>(this.transactionUrl, transactionData, { headers }).subscribe(
        response => {
          console.log('Transaction created successfully:', response);
          this.processTransaction(response);
        },
        error => {
          console.error('Error creating transaction:', error);
          console.error('Error details:', error.message);
          // Handle error
        }
      );
    });
  }

  private getAuthToken(): Observable<string> {
    const data = {
      "AuthType": "Custom",
      "ApplicationId": environment.commonFixedData.ApplicationId,
      "Username": environment.payment_user.username,
      "Password": environment.payment_user.password,
      "Key": environment.payment_user.key,
      "IV": environment.payment_user.iv
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const tokenObservable = this.http.post<any>(this.tokenUrl, JSON.stringify(data), { headers }).pipe(
      map(response => response.AuthToken), // Access the AuthToken property
      catchError(error => {
        console.error('Error generating auth token:', error);
        throw error;
      })
    );

    tokenObservable.subscribe(
      token => console.log("Token is:", token),
      error => console.error('Error in subscription:', error)
    );

    return tokenObservable;
  }
  private processTransaction(transactionId: string): void {

    const paymentUrl = `${this.paymentMethodUrl}?transactionid=${transactionId}`;
    window.location.href = paymentUrl;
  }

  private generateTransactionUUID() {

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) >> (c === 'x' ? 0 : 4);
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  paymentVerification(transaction_id: any) {
    console.log("paymentVerification is called", transaction_id);
  
    return new Promise((resolve, reject) => {
      this.getAuthToken().subscribe(
        token => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Basic ${token}`
          });
  
          const url = `${this.paymentVerificatioUrl}?TransactionIdForPG=${transaction_id}`;
  
          this.http.get<any>(url, { headers }).subscribe(
            response => {
              resolve(response); 
            },
            error => {
              reject(error); 
            }
          );
        },
        error => {
          console.error('Error fetching auth token:', error);
          reject(error); 
        }
      );
    });
  }
  
  
}


@Injectable({
  providedIn: 'root',
})
export class PaymentTaggingClass {

  private tokenEndpoint = environment.iciciApis.tokenEndpoint;
  private paymenttaggingapi = environment.iciciApis.paymenttaggingapi;
  constructor(private http: HttpClient) { }

  authenticate(): Observable<string> {
    const clientId = environment.user_details.clientId;
    const clientSecret = environment.user_details.clientSecret;
    const username = environment.user_details.username;
    const password = environment.user_details.password;
    const scope = 'esbpayment';
    const grantType = 'password';

    const requestData = new URLSearchParams({
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
      scope: scope,
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(this.tokenEndpoint, requestData.toString(), {
      headers: headers,
    }).pipe(
      map(data => data.access_token)
    );
  }

  paymenttagging(paymentData: any): Observable<any> {
    return this.authenticate().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        });
        const proposal = JSON.parse(localStorage.getItem('ICICIPROPOSAL'));
        const date = JSON.parse(localStorage.getItem('proposalDate'));
        const payload =
        {
          "CorrelationId": proposal.correlationId,
          "isTaggingRequired": true,
          "isMappingRequired": true,
          "PaymentEntry": {
            "onlineDAEntry": {
              "AuthCode": paymentData.AuthCode,
              "MerchantID": paymentData.MerchantId,
              "TransactionId": paymentData.TransactionId,
              "CustomerID": proposal.customerId,
              "PaymentAmount": paymentData.Amount,
              "InstrumentDate": date
            }
          },
          "PaymentMapping": {
            "customerProposal": [
              {
                "CustomerID": proposal.customerId,
                "ProposalNo": proposal.proposalNumber
              }
            ]
          },
          "PaymentTagging": {
            "customerProposal": [
              {
                "CustomerID": proposal.customerId,
                "ProposalNo": proposal.proposalNumber
              }
            ]
          },
          "DealId": environment.commonFixedData.DealId
        }


        return this.http.post<any>(this.paymenttaggingapi, payload, { headers });
      })
    );
  }


}



@Injectable({
  providedIn: 'root',
})
export class DocumentDownload {
  private tokenEndpoint = '/icici_token/generate-jwt-token';
  private policyCertificate = '/api_icici/common/ilservices/misc/v2/generic/policycertificate';
  constructor(private http: HttpClient) { }
    downloadPDF(policyNo: string, correlationId: string, customerId: string): Observable<Blob> {
      return this.pdfDownloadUthenticate().pipe(
        switchMap((accessToken: string) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          });
    
          const payload = {
            CorrelationId: correlationId,
            policyNo: policyNo,
            customerId: customerId,
          };
    
          return this.http.post(this.policyCertificate, payload, {
            headers,
            responseType: 'arraybuffer', 
          }).pipe(
            map((arrayBuffer: ArrayBuffer) => {
             
              return new Blob([arrayBuffer]);
            })
          );
        })
      );
    }
    
    
    
    pdfDownloadUthenticate(): Observable<string> {
      const requestData = new URLSearchParams({
        grant_type: environment.user_details.grantType,
        client_id: environment.user_details.clientId,
        client_secret: environment.user_details.clientSecret,
        username: environment.user_details.username,
        password: environment.user_details.password,
        scope: 'esbpolicypdf',
      });
    
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    
      return this.http.post<any>(this.tokenEndpoint, requestData.toString(), { headers }).pipe(
        map(data => data.access_token)
      );
    }
    
}
